import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import WallBoardForm from './WallBoardForm';
import { DropResult } from 'react-beautiful-dnd';
import { updateWallBoard } from '../../api/createWallBoard';
import DashboardsContext from '../../contexts/DashboardsContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import deleteDashboard from '../../api/deleteDashboard';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import useDashboardZoomOptions from './useDashboardZoomOptions';
import useWeatherMapZoomOptions from './useWeatherMapZoomOptions';

export const WEATHER_BOARD_ID = 'weatherBoard';

const isDashboard = (
  wbd: PersistedDashboardType | WeatherBoardType,
): wbd is PersistedDashboardType => {
  // @ts-ignore
  return !!wbd.canvas;
};

const WallBoardFormContainer = ({
  wallBoard,
  enterWallBoardMode,
  onGenerateAccessCodeClicked,
  previewDashboard,
  setPreviewDashboard,
}: {
  wallBoard: Wallboard;
  enterWallBoardMode: () => void;
  onGenerateAccessCodeClicked: () => void;
  previewDashboard?: PersistedDashboardType | WeatherBoardType;
  setPreviewDashboard: React.Dispatch<
    React.SetStateAction<PersistedDashboardType | WeatherBoardType | undefined>
  >;
}) => {
  const { accountRef } = useContext(AccountPickerContext);

  // State
  const [name, setName] = useState<string>(wallBoard.name);
  const [wallBoardDashboardIds, setWallBoardDashboardIds] = useState<string[]>(
    wallBoard.wallBoardDashboardIds,
  );
  const [intervalTimeSeconds, setIntervalTimeSeconds] = useState<string>(
    wallBoard.intervalTimeSeconds.toString(10),
  );
  const { allDashboards: availableDashboards } = useContext(DashboardsContext);
  const { trackEvent } = useContext(AnalyticsContext);

  useEffect(() => {
    setWallBoardDashboardIds(wallBoard.wallBoardDashboardIds);
    setName(wallBoard.name);
    setIntervalTimeSeconds(wallBoard.intervalTimeSeconds.toString(10));
  }, [wallBoard]);

  useEffect(() => {
    if (
      previewDashboard &&
      !wallBoardDashboardIds.includes(previewDashboard.id)
    ) {
      setPreviewDashboard(undefined);
    }
  }, [previewDashboard, setPreviewDashboard, wallBoardDashboardIds]);

  const onSave = useCallback(
    (newWb: Wallboard) => {
      trackEvent('Wallboard Config - Saved', {
        wallBoardId: newWb.id,
      });
      updateWallBoard(newWb, accountRef).then(() => {});
    },
    [accountRef, trackEvent],
  );
  const zoomOptions = useWeatherMapZoomOptions(wallBoard, onSave);
  const dashboardZoomOptions = useDashboardZoomOptions(wallBoard, onSave);

  // Interactions
  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName);
    const newWb = {
      ...wallBoard,
      name: newName,
    };
    onSave(newWb);
  };

  const onIntervalTimeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newInterval = Number.parseInt(event.target.value);
    setIntervalTimeSeconds(event.target.value);
    const newWb = {
      ...wallBoard,
      intervalTimeSeconds: newInterval,
    };
    onSave(newWb);
  };

  const onDragEnd = (result: DropResult) => {
    const { draggableId, destination } = result;
    if (!destination) {
      return;
    }

    const itemsWithoutDropped = wallBoardDashboardIds.filter(
      (wb) => wb !== draggableId,
    );
    const theItem = wallBoardDashboardIds.find((wbId) => wbId === draggableId);
    if (!theItem) {
      throw new Error('Item not found');
    }

    trackEvent('Wallboard Config - Order Changed', {
      wallBoardId: wallBoard.id,
    });

    const newOrder = [
      ...itemsWithoutDropped.slice(0, destination.index),
      draggableId,
      ...itemsWithoutDropped.slice(
        destination.index,
        itemsWithoutDropped.length,
      ),
    ];

    setWallBoardDashboardIds(newOrder);
    const newWb = {
      ...wallBoard,
      wallBoardDashboardIds: newOrder,
    };
    onSave(newWb);
  };

  const onAddWeatherBoard = () => {
    if (wallBoardDashboardIds.includes(WEATHER_BOARD_ID)) {
      alert('That dashboard is already featured');
      return;
    }
    trackEvent('Wallboard Config - Added WeatherBoard', {
      wallBoardId: wallBoard.id,
    });
    const newOrder = [...wallBoardDashboardIds, WEATHER_BOARD_ID];
    setWallBoardDashboardIds(newOrder);
    const newWb = {
      ...wallBoard,
      wallBoardDashboardIds: newOrder,
    };
    onSave(newWb);
    setPreviewDashboard({
      id: WEATHER_BOARD_ID,
      name: 'Weather',
    });
  };

  const onWallBoardDashboardRemoved = (
    wbd: PersistedDashboardType | WeatherBoardType,
  ) => {
    const confirmed = window.confirm(
      'Removing this slide will cause this content to be deleted. Are you' +
        ' sure?',
    );
    if (!confirmed) {
      return;
    }
    if (previewDashboard && previewDashboard.id === wbd.id) {
      setPreviewDashboard(undefined);
    }
    trackEvent('Wallboard Config - Removed Dashboard', {
      wallBoardId: wallBoard.id,
    });
    const newIds = wallBoardDashboardIds.filter((wid) => wid !== wbd.id);
    setWallBoardDashboardIds(newIds);

    const newWb = {
      ...wallBoard,
      wallBoardDashboardIds: newIds,
    };

    if (isDashboard(wbd) && wbd.isWallboardSlide) {
      deleteDashboard(wbd.id, accountRef).then(() => {
        onSave(newWb);
      });
    } else {
      onSave(newWb);
    }
  };

  const wallBoardDashboards = wallBoardDashboardIds
    .map((id) => {
      if (id === WEATHER_BOARD_ID) {
        return { id: WEATHER_BOARD_ID, name: 'Weather' };
      } else {
        return availableDashboards.find((d) => d.id === id);
      }
    })
    .filter((d) => !!d) as (PersistedDashboardType | WeatherBoardType)[];

  return (
    <WallBoardForm
      name={name}
      onNameChanged={onNameChanged}
      intervalTimeSeconds={intervalTimeSeconds}
      onIntervalTimeChanged={onIntervalTimeChanged}
      wallBoardDashboards={wallBoardDashboards}
      onWallBoardDashboardRemoved={onWallBoardDashboardRemoved}
      onAddWeatherBoard={onAddWeatherBoard}
      weatherMapZoomOptions={zoomOptions}
      dashboardZoomOptions={dashboardZoomOptions}
      onDragEnd={onDragEnd}
      enterWallBoardMode={enterWallBoardMode}
      onGenerateAccessCodeClicked={onGenerateAccessCodeClicked}
      previewDashboard={previewDashboard}
      setPreviewDashboard={setPreviewDashboard}
    />
  );
};

export default WallBoardFormContainer;
