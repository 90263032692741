import { useCallback, useContext } from 'react';

import canvasContentTypeCheckers from 'types/cardTypeCheckers';
import { toFixedPlates } from 'components/Report/toReportDrillDown';
import isDefined from 'isDefined';
import CurrentUserContext from 'contexts/CurrentUserContext';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import getTimeStamp from 'getTimeStamp';
import createSavedFilter from 'api/createSavedFilter';
import getSavedFilter from 'api/getSavedFilter';
import DashboardGadgetsContext from 'contexts/DashboardGadgetsContext';
import MetricListsContext from 'contexts/MetricListsContext';
import getIdentifier from 'getIdentifier';

import useCopyVisHelpers from './useCopyVisHelpers';

const useCreateDashboardCopy = () => {
  const currentUser = useContext(CurrentUserContext);
  const { dashboardGadgetsLookup } = useContext(DashboardGadgetsContext);
  const { metricListsLookup } = useContext(MetricListsContext);
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { generateNewVisDef, getMetricIdForNewVis } = useCopyVisHelpers();

  const copyDashboardGadget = useCallback(
    async ({
      c,
      dashboard,
    }: {
      c: CanvasCard.DashboardGadgetCard;
      dashboard: PersistedDashboardType;
    }): Promise<CanvasCard.DashboardGadgetCard | undefined> => {
      const existingDashboardGadget =
        dashboardGadgetsLookup[c.content.dashboardGadgetId];
      if (!existingDashboardGadget) {
        return undefined;
      }
      const newChartDef = await generateNewVisDef(
        existingDashboardGadget.chartDef,
      );
      const newDashboardGadget = {
        ...existingDashboardGadget,
        chartDef: newChartDef,
        id: getIdentifier(),
        drillDowns: toFixedPlates({
          plates: existingDashboardGadget.drillDowns || window.emptyArray,
          variableDrillDowns: dashboard.variableDrillDowns || window.emptyArray,
        }),
        scope: toFixedPlates({
          plates: existingDashboardGadget.scope || window.emptyArray,
          variableDrillDowns: dashboard.variableDrillDowns || window.emptyArray,
        }),
        createdBy: currentUser.id,
        createdOn: getTimeStamp(),
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };

      await STORE.visualisations
        .getDashboardGadgetsRef({ accountId: selectedAccountId })
        .doc(newDashboardGadget.id)
        .set(newDashboardGadget);

      return {
        ...c,
        content: {
          ...c.content,
          dashboardGadgetId: newDashboardGadget.id,
        },
      };
    },
    [
      currentUser.id,
      dashboardGadgetsLookup,
      generateNewVisDef,
      selectedAccountId,
    ],
  );

  const copyMetricList = useCallback(
    async ({
      c,
      dashboard,
    }: {
      c: CanvasCard.MetricListCard;
      dashboard: PersistedDashboardType;
    }): Promise<CanvasCard.MetricListCard | undefined> => {
      const existingMetricList = metricListsLookup[c.content.metricListId];
      if (!existingMetricList) {
        return;
      }
      const newMetricList: MetricListGadgetType = {
        ...existingMetricList,
        id: getIdentifier(),
        createdBy: currentUser.id,
        createdOn: getTimeStamp(),
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };

      const newList: MetricListItemType[] = [];

      // Create new copies of the saved filters which metric list items
      // require. This ensures changes are made in isolation
      const newSavedFilterRequests = newMetricList.list.map(async (i) => {
        if (i.savedFilterId) {
          const currentSavedFilter = (await getSavedFilter(
            i.savedFilterId,
            accountRef,
          )) as SavedFilter;
          const newSavedFilter = {
            ...currentSavedFilter,
            id: getIdentifier(),
            drillDowns: toFixedPlates({
              plates: currentSavedFilter.drillDowns,
              variableDrillDowns: dashboard.variableDrillDowns,
            }),
            scope: toFixedPlates({
              plates: currentSavedFilter.scope,
              variableDrillDowns: dashboard.variableDrillDowns,
            }),
            createdBy: currentUser.id,
            createdOn: getTimeStamp(),
            updatedBy: currentUser.id,
            updatedOn: getTimeStamp(),
          };

          await createSavedFilter(newSavedFilter, accountRef);
          const newI = {
            ...i,
            metricId: await getMetricIdForNewVis(i.metricId),
            savedFilterId: newSavedFilter.id,
          } as MetricListItemType;

          newList.push(newI);
        }
      });
      await Promise.all(newSavedFilterRequests);

      newMetricList.list = newList;
      await STORE.visualisations
        .getMetricListsRef({
          accountId: selectedAccountId,
        })
        .doc(newMetricList.id)
        .set(newMetricList);

      return {
        ...c,
        content: {
          ...c.content,
          metricListId: newMetricList.id,
        },
      };
    },
    [
      accountRef,
      currentUser.id,
      getMetricIdForNewVis,
      metricListsLookup,
      selectedAccountId,
    ],
  );

  const copyCanvas = useCallback(
    async ({
      dashboard,
      templatedFrom,
    }: {
      dashboard: PersistedDashboardType;
      templatedFrom?: PersistedDashboardType;
    }): Promise<Canvas> => {
      const canvas = templatedFrom ? templatedFrom.canvas : dashboard.canvas;
      const cardRequests = canvas.cards.map((c) => {
        if (canvasContentTypeCheckers.isDashboardGadget(c)) {
          return copyDashboardGadget({ c, dashboard });
        } else if (canvasContentTypeCheckers.isMetricList(c)) {
          return copyMetricList({ c, dashboard });
        } else {
          return Promise.resolve({
            ...c,
          });
        }
      });
      const newCards = await Promise.all(cardRequests);
      const newCanvas = {
        ...canvas,
        cards: newCards.filter(isDefined),
      };

      return newCanvas;
    },
    [copyDashboardGadget, copyMetricList],
  );

  const createDashboardCopy = useCallback(
    async ({
      name,
      dashboard,
      isCreatingTemplate,
      templatedFrom,
      access,
      wallboard,
    }: {
      name: string;
      dashboard: PersistedDashboardType;
      isCreatingTemplate?: boolean;
      templatedFrom?: PersistedDashboardType;
      access: ResourceAccess;
      wallboard?: Wallboard;
    }): Promise<{
      newDashboard: PersistedDashboardType;
      newDashboardId: string;
    }> => {
      const newCanvas = await copyCanvas({
        dashboard,
        templatedFrom,
      });

      const newDashboard = {
        ...dashboard,
        id: getIdentifier(),
        name,
        canvas: newCanvas,
        variableDrillDowns: [],
        isTemplate: !!isCreatingTemplate,
        templateId: undefined,
        access,
        createdBy: currentUser.id,
        createdOn: getTimeStamp(),
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };

      if (wallboard) {
        newDashboard.isWallboardSlide = true;
        newDashboard.wallboardId = wallboard.id;
      }

      await STORE.getDashboardsRef({
        accountId: selectedAccountId,
      })
        .doc(newDashboard.id)
        .set(newDashboard);

      return {
        newDashboardId: newDashboard.id,
        newDashboard,
      };
    },
    [copyCanvas, currentUser.id, selectedAccountId],
  );

  return createDashboardCopy;
};

export default useCreateDashboardCopy;
