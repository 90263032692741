import React, { ChangeEvent } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';
import { Section } from '../MyAccount/Profile/styles';
import Row from '../Common/Row';
import AccessInput from '../AccessInput';
import Inputs from '../Inputs';
import FormHeader from '../../kingpin/forms/FormHeader';
import Form from '../../kingpin/forms/Form';
import FormContent from '../../kingpin/forms/FormContent';
import FeatureGate, { FEATURE_GATES } from '../FeatureGate';

const MaybeRenderModal = ({
  isAlreadyInModal,
  isOpen,
  close,
  children,
}: {
  isAlreadyInModal: boolean;
  isOpen: boolean;
  close: () => void;
  children: JSX.Element | JSX.Element[];
}) => {
  if (isAlreadyInModal) {
    return <>{children}</>;
  } else {
    return (
      <ModalTransition>
        {isOpen && (
          <Modal shouldScrollInViewport={false} onClose={close}>
            {children}
          </Modal>
        )}
      </ModalTransition>
    );
  }
};

const DashboardForm = ({
  isWallboard,
  isEditing,
  isOpen,
  onSubmit,
  close,
  isLoading,
  title,
  onTitleChanged,
  isValid,
  isSaveAs,
  onBackPressed,
  access,
  setAccess,
  isAlreadyInModal,
  canSetToPrivate,
}: {
  isWallboard: boolean;
  isEditing: boolean;
  isOpen: boolean;
  onSubmit: () => void;
  close: () => void;
  isLoading: boolean;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isValid: boolean;
  isSaveAs: boolean;
  onBackPressed?: () => void;
  access: ResourceAccess;
  setAccess: React.Dispatch<React.SetStateAction<ResourceAccess>>;
  isAlreadyInModal?: boolean;
  canSetToPrivate?: boolean;
}) => (
  <MaybeRenderModal
    isAlreadyInModal={!!isAlreadyInModal}
    isOpen={isOpen}
    close={close}
  >
    <Form>
      <FormHeader
        title={
          isWallboard
            ? 'Create Slide'
            : isEditing
              ? 'Edit Dashboard'
              : isSaveAs
                ? 'Save As'
                : 'Create Dashboard'
        }
        onClose={close}
      />
      <FormContent>
        <Section>
          <Inputs.TextInput
            value={title}
            onChange={onTitleChanged}
            maxLength={40}
            data-testid={'slide-title'}
            inputSize="Small"
            label={
              isWallboard
                ? 'Slide Title (max. 40 characters)'
                : 'Dashboard Title (max. 40 characters)'
            }
          />
        </Section>
        {!isWallboard && (
          <Section>
            <AccessInput
              access={access}
              setAccess={setAccess}
              canSetToPrivate={canSetToPrivate}
            />
          </Section>
        )}
      </FormContent>

      {onBackPressed && (
        <Row spaceBetween>
          <Button onClick={close} label="Cancel" type="Tertiary" size="Small" />
          <Row style={{ justifyContent: 'flex-end' }}>
            <FeatureGate featureName={FEATURE_GATES.TEMPLATES}>
              <>
                <Button
                  onClick={onBackPressed}
                  label="Back"
                  type="Tertiary"
                  size="Small"
                />
                <div style={{ marginRight: 8 }} />
              </>
            </FeatureGate>
            <Button
              onClick={onSubmit}
              label="Save"
              type="Primary"
              size="Small"
              isDisabled={!isValid}
              isLoading={isLoading}
            />
          </Row>
        </Row>
      )}
      {!onBackPressed && (
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={close}
            label="Cancel"
            type="Secondary"
            size="Small"
          />
          <div style={{ marginRight: 8 }} />
          <Button
            onClick={onSubmit}
            label="Save"
            type="Primary"
            size="Small"
            isDisabled={!isValid}
            isLoading={isLoading}
          />
        </Row>
      )}
    </Form>
  </MaybeRenderModal>
);

export default DashboardForm;
