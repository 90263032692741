import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import getFilterValues from 'api/getFilterValues';
import GqlClientContext from 'contexts/GqlClientContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import { buildShowEntityAppContentTab } from 'navigation/appRoutes';

import useEntityParams from './useEntityParams';

const useQueryArgs = (selectedEntity: EntityDetails.Entity | undefined) => {
  const [queryArgs, setQueryArgs] = useState<
    | {
        filterInput: FilterInput;
        dateScope: DateRangeInput;
        field: string;
      }
    | undefined
  >();

  useEffect(() => {
    if (!selectedEntity) {
      setQueryArgs(undefined);
      return;
    }

    setQueryArgs({
      field: selectedEntity.primaryField,
      filterInput: { dataType: [selectedEntity.entityDataset] },
      dateScope: {},
    });
  }, [selectedEntity]);

  return queryArgs;
};

const DEBOUNCE_MS = process.env.NODE_ENV === 'test' ? 200 : 1000;

export const useEntityValues = (
  selectedEntity: EntityDetails.Entity | undefined,
) => {
  const { client } = useContext(GqlClientContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const { value } = useEntityParams();
  const [entitySearchText, setEntitySearchText] = useState<string>('');
  const [values, setValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryArgs = useQueryArgs(selectedEntity);

  const onEntitySearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEntitySearchText(event.target.value);
    },
    [],
  );

  // Some unusual code here to enable a debounce and handle race conditions
  useEffect(() => {
    setIsLoading(true);
    if (!queryArgs) {
      setValues([]);
      return;
    }
    let isActive = true;

    // Timeout for debounce
    setTimeout(() => {
      if (!isActive) {
        // Bounced
        return;
      }
      getFilterValues({
        field: queryArgs.field,
        filterInput: queryArgs.filterInput,
        dateScope: queryArgs.dateScope,
        client,
        accountId: selectedAccount.accountId,
        value: entitySearchText,
      }).then((newValues) => {
        if (!isActive) {
          // Handle race condition potential
          return;
        }

        if (value) {
          // Always have the selected entity visible
          setValues(_.uniq([...newValues, value]).sort());
        } else {
          setValues(newValues);
        }

        setIsLoading(false);
      });
    }, DEBOUNCE_MS); // 1s debounce

    return () => {
      isActive = false;
    };
  }, [client, entitySearchText, value, queryArgs, selectedAccount.accountId]);

  return {
    values,
    isLoading,
    entitySearchText,
    onEntitySearchTextChanged,
  };
};

const useEntityOptions = ({
  selectedEntity,
  onEntitySelected,
}: {
  selectedEntity: EntityDetails.SelectedEntity | undefined;
  onEntitySelected: (e: string) => void;
}) => {
  const { field, tabId, tabType } = useEntityParams();
  const {
    values: entityValues,
    isLoading: isLoadingEntityValues,
    entitySearchText,
    onEntitySearchTextChanged,
  } = useEntityValues(selectedEntity ? selectedEntity.type : undefined);
  const navigate = useNavigate();
  const lastEntityId = useRef<string | undefined>(undefined);
  const shouldClearOptions = useCallback(() => {
    if (lastEntityId.current === undefined || !selectedEntity) {
      return true;
    }

    return lastEntityId.current !== selectedEntity.type.id;
  }, [selectedEntity]);

  const buildOptions = useCallback(() => {
    if (!selectedEntity) {
      return [];
    }
    if (isLoadingEntityValues) {
      return [];
    }
    if (!field || !tabId || !tabType) {
      return [];
    }

    lastEntityId.current = selectedEntity.type.id;
    return entityValues.map((entity) => ({
      key: entity,
      value: entity,
      label: entity,
      isSelected: entity === selectedEntity.fieldValue,
      onSelected: () => {
        onEntitySelected(entity);
        navigate(
          buildShowEntityAppContentTab({
            field,
            value: entity,
            tabId,
            tabType,
          }),
        );
      },
    }));
  }, [
    selectedEntity,
    isLoadingEntityValues,
    entityValues,
    onEntitySelected,
    navigate,
    field,
    tabId,
    tabType,
  ]);
  const [options, setOptions] = useState<DropdownOption[]>(buildOptions);
  useEffect(() => {
    setOptions((currentOptions) => {
      if (shouldClearOptions()) {
        return buildOptions();
      }

      const newOptions = _.sortBy(
        _.uniqBy([...buildOptions(), ...currentOptions], 'key'),
        'label',
      );

      return newOptions.map((o) => ({
        ...o,
        isSelected: selectedEntity
          ? o.value === selectedEntity.fieldValue
          : false,
      }));
    });
  }, [buildOptions, selectedEntity, shouldClearOptions]);

  return {
    entityOptions: options,
    entitySearchText,
    onEntitySearchTextChanged,
    isLoadingEntityOptions: isLoadingEntityValues,
  };
};

export default useEntityOptions;
