import React, { useCallback, useContext, useState } from 'react';
import { ExecutivePortalsContext } from 'contextProviders/PortalsProvider/ExecutivePortalsProvider';
import useAdminPortal from 'contextProviders/PortalsProvider/useAdminPortal';

import PortalOption from './PortalOption';
import PortalSearchInput from './PortalSearchInput';
import CreatePortalButton from './CreatePortalButton';
import { OPENED_NAV_WIDTH } from '../../../navigation/styles';

const PortalPickerDialog = ({
  close,
  openPortalForm,
}: {
  close: () => void;
  openPortalForm: () => void;
}) => {
  const { allExecutivePortals } = useContext(ExecutivePortalsContext);
  const adminPortal = useAdminPortal();

  const [searchText, setSearchText] = useState('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  return (
    <div
      style={{
        minWidth: OPENED_NAV_WIDTH - 18,
      }}
    >
      <PortalSearchInput
        searchText={searchText}
        onSearchTextChanged={onSearchTextChanged}
      />
      <div style={{ maxHeight: 256, overflowY: 'scroll' }}>
        {adminPortal && <PortalOption portal={adminPortal} close={close} />}
        {allExecutivePortals.length > 0 && (
          <>
            {allExecutivePortals.map((portal) => (
              <PortalOption portal={portal} key={portal.id} close={close} />
            ))}
          </>
        )}
      </div>
      <CreatePortalButton
        closePortalPicker={close}
        openPortalForm={openPortalForm}
      />
    </div>
  );
};

export default PortalPickerDialog;
