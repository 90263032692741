import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import cardTypeCheckers from '../types/cardTypeCheckers';
import { DASHBOARDS_COLLECTION } from '../constants';

const deleteImages = async (
  dashId: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  const dashDoc = await accountRef
    .collection(DASHBOARDS_COLLECTION)
    .doc(dashId)
    .get();
  if (dashDoc.exists) {
    const dash = dashDoc.data() as PersistedDashboardType;
    const imageCards = (dash.canvas ? dash.canvas.cards : []).filter(
      cardTypeCheckers.isImage,
    );
    const deletionRequests = imageCards.map((c) =>
      firebase
        .storage()
        .ref(c.content.imageSrc)
        .delete()
        .catch(() => {
          // do nothing
        }),
    );
    await Promise.all(deletionRequests);
  }
};

const deleteVideos = async (
  dashId: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  const dashDoc = await accountRef
    .collection(DASHBOARDS_COLLECTION)
    .doc(dashId)
    .get();
  if (dashDoc.exists) {
    const dash = dashDoc.data() as PersistedDashboardType;
    const videoCards = (dash.canvas ? dash.canvas.cards : []).filter(
      cardTypeCheckers.isVideo,
    );
    const deletionRequests = videoCards.map((c) =>
      firebase
        .storage()
        .ref(c.content.videoSrc)
        .delete()
        .catch(() => {
          // do nothing
        }),
    );
    await Promise.all(deletionRequests);
  }
};

const deleteDashboard = async (
  id: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  await deleteImages(id, accountRef);
  await deleteVideos(id, accountRef);

  return accountRef.collection(DASHBOARDS_COLLECTION).doc(id).delete();
};

export default deleteDashboard;
