import React, { useContext } from 'react';
import { PortalsContext } from 'contextProviders/PortalsProvider';
import LegacyMainNav from './LegacyMainNav';
import MainNav from './MainNav';

const MainNavContainer = () => {
  const { isPortalsEnabled } = useContext(PortalsContext);
  if (isPortalsEnabled) {
    return <MainNav />;
  }

  return <LegacyMainNav />;
};

export default MainNavContainer;
