import React, { createContext } from 'react';
import useExecutivePortals from './useExecutivePortals';
import usePublishedLookup, { PublishedLookup } from './usePublishedLookup';

interface PortalsContextType {
  availableExecutivePortals: ExecutivePortal[];
  allExecutivePortals: ExecutivePortal[];
  publishedLookup: PublishedLookup;
}

export const ExecutivePortalsContext = createContext<PortalsContextType>({
  availableExecutivePortals: [],
  allExecutivePortals: [],
  publishedLookup: {
    reports: {},
    dashboards: {},
    targetApps: {},
    scorecards: {},
  },
});

const ExecutivePortalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { availableExecutivePortals, allExecutivePortals } =
    useExecutivePortals();
  const publishedLookup = usePublishedLookup(allExecutivePortals);

  return (
    <ExecutivePortalsContext.Provider
      value={{
        availableExecutivePortals,
        allExecutivePortals,
        publishedLookup,
      }}
    >
      {children}
    </ExecutivePortalsContext.Provider>
  );
};

export default ExecutivePortalsProvider;
