import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import BoardPeriodsProvider from '../contextProviders/BoardPeriodsProvider';
import isDriverPerformanceBoard from '../isDriverPerformanceBoard';
import BonusPeriodsProvider from '../contextProviders/BonusPeriodsProvider';
import CustomerLaneCommitBoard from './CustomerLaneCommitsBoard';
import BoardContainer from './Board';
import isCustomerLaneBoard from '../isCustomerLaneBoard';
import isGeneralBoard from '../isBoardWithSlideOut';
import Row from './Common/Row';
import isPerformanceBoard from '../isPerformanceBoard';
import PerformanceBoards from './PerformanceBoards';

const PopupBoard = ({
  board,
  isOpen,
  close,
  runTimeFilters,
  scopeFilters,
  runTimeDateRange,
  bonusPeriodId,
}: {
  board?: Board | PerformanceBoardTypes.Board;
  isOpen: boolean;
  close: () => void;
  runTimeFilters: FilterPlate[];
  scopeFilters: FilterPlate[];
  runTimeDateRange?: DateRangeInput;
  bonusPeriodId: string;
}) => {
  return (
    <ModalTransition>
      {isOpen && board && (
        <Modal
          width={'95vw'}
          height={'80vh'}
          onClose={close}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <div style={{ flex: 1, height: '80vh' }} data-testid="popup-board">
            <Row style={{ height: '100%' }}>
              <div style={{ width: '100%' }}>
                {isDriverPerformanceBoard(board) && (
                  <BonusPeriodsProvider bonusPeriodId={bonusPeriodId}>
                    <BoardContainer
                      board={board}
                      initialRunTimeFilters={runTimeFilters}
                      initialScopeFilters={scopeFilters}
                    />
                  </BonusPeriodsProvider>
                )}
                {isGeneralBoard(board) && (
                  <BoardPeriodsProvider
                    board={board}
                    initialDateRange={runTimeDateRange}
                  >
                    <BoardContainer
                      board={board}
                      initialRunTimeFilters={runTimeFilters}
                      initialScopeFilters={scopeFilters}
                    />
                  </BoardPeriodsProvider>
                )}
                {isCustomerLaneBoard(board) && (
                  <CustomerLaneCommitBoard
                    board={board}
                    initialRunTimeFilters={runTimeFilters}
                    initialScopeFilters={scopeFilters}
                  />
                )}
                {isPerformanceBoard(board) && (
                  <BoardPeriodsProvider
                    board={board}
                    initialDateRange={runTimeDateRange}
                  >
                    <PerformanceBoards.Board
                      board={board}
                      popupFilters={runTimeFilters}
                      scopeFilters={scopeFilters}
                    />
                  </BoardPeriodsProvider>
                )}
              </div>
            </Row>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default PopupBoard;
