import React, { useEffect, useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import ReportDrillDownContainer from './ReportDrillDown';
import { toPlate } from './VariableFiltersSlideOut/VariableFilterCard/VariableFilterCard';
import { Label } from 'components/MyAccount/Profile/styles';
import useDrillDownFieldName from '../hooks/useDrillDownFieldName';
import ReportDrillDownsProvider from '../contextProviders/ReportDrillDownsProvider';
import useDataTypesOnReportCanvas from './Report/useDataTypesOnReportCanvas';
import Colors2 from '../theme/Colors2';

export const useVariableFilterDataTypes = (
  variableFilter: VariableDrillDownType,
  canvas: Canvas | undefined,
) => {
  const dataTypesOnCanvas = useDataTypesOnReportCanvas(canvas);
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  useEffect(() => {
    const dataType = variableFilter.value.dataset;
    if (dataType) {
      setDataTypes([dataType]);
    } else {
      setDataTypes(dataTypesOnCanvas);
    }
  }, [dataTypesOnCanvas, variableFilter.value.dataset]);

  return dataTypes;
};

const VariableFilterInput = ({
  variableFilter,
  setVariableFilters,
  canvas,
}: {
  variableFilter: VariableDrillDownType;
  setVariableFilters: React.Dispatch<
    React.SetStateAction<VariableDrillDownType[]>
  >;
  canvas?: Canvas;
}) => {
  const dataTypes = useVariableFilterDataTypes(variableFilter, canvas);
  const fieldName = useDrillDownFieldName(variableFilter.value);
  const onUpdated = (newDrillDown: ReportDrillDownType) => {
    const newVariableFilter = {
      ...variableFilter,
      value: newDrillDown,
    };

    setVariableFilters((vfs) =>
      vfs.map((vs) => {
        if (vs.id === variableFilter.id) {
          return newVariableFilter;
        } else {
          return vs;
        }
      }),
    );
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <Label>
        <Typography.Body type={'Label'} color={Colors2.Grey['5']}>
          {fieldName}
        </Typography.Body>
      </Label>
      <ReportDrillDownsProvider dataTypes={dataTypes}>
        <ReportDrillDownContainer
          drillDown={toPlate(variableFilter)}
          isScope={false}
          isInVariableFilterList
          onUpdated={onUpdated}
        />
      </ReportDrillDownsProvider>
    </div>
  );
};

export default VariableFilterInput;
