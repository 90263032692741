import React, { useContext } from 'react';

import styled from 'styled-components';
import NavSideBarContext from '../../../contexts/NavSideBarContext';
import MainNavTop from '../MainNavTop';
import NavSection from '../NavSection';
import NavItem from '../NavItem';
import AppRoutes from '../../../navigation/appRoutes';
import MainNavBottom from '../MainNavBottom';
import FeatureGate, { FEATURE_GATES } from '../../../components/FeatureGate';
import GlobalSearch from '../../../components/GlobalSearch';
import MainNavFavouritesSection from '../MainNavFavouritesSection';
import AccountPicker from '../../../components/AccountPicker';
import NAVIGATION from '../constants';
import Colors2 from '../../../theme/Colors2';
import Row from 'components/Common/Row';
import FavoritesSection from '../FavoritesSection';
import PortalPicker from '../PortalPicker';
import PortalNavSection from './PortalNavSection';

const MainNavDiv = styled.div<{ isCollapsed: boolean }>`
  width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  min-width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
  justify-content: space-between;
  border-right: 1px solid ${Colors2.Border};
`;

const MainNavPrimaryContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainNav = () => {
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <Row>
      <MainNavDiv isCollapsed={!isOpen}>
        <MainNavPrimaryContent>
          <MainNavTop />
          <NavSection>
            <AccountPicker />
            <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
              <GlobalSearch />
            </FeatureGate>
            <MainNavFavouritesSection />
            <NavItem
              to={AppRoutes.loggedIn.workspaces}
              label={'Workspaces'}
              tooltip={'Workspaces'}
              icon={'workspace'}
              onClickEvent={'Navigation Sidebar - Clicked Workspaces'}
            />
            <NavItem
              to={AppRoutes.loggedIn.shared}
              label={'Shared'}
              tooltip={'Shared'}
              icon={'shared'}
              onClickEvent={'Navigation Sidebar - Clicked Shared'}
            />
          </NavSection>
          <NavSection hideBorderBottom>
            <PortalPicker />
          </NavSection>
          <PortalNavSection />
        </MainNavPrimaryContent>
        <MainNavBottom />
      </MainNavDiv>
      <FavoritesSection />
    </Row>
  );
};

export default MainNav;
