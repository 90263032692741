import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import Row from '../../Common/Row';
import UserIcon from '../../Common/UserIcon';
import useRemoveUserAccessConfirmation from './useRemoveUserAccessConfirmation';

const ItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 12px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const UserWithAccess = ({
  type,
  typeId,
  contentName,
  user,
  refreshSharedWith,
}: {
  type: 'report' | 'dashboard';
  typeId: string;
  contentName: string;
  user: UserManagement.SignedUpUser | UserManagement.PendingUser;
  refreshSharedWith: () => void;
}) => {
  const { RemoveConfirmation, onRemoveClicked } =
    useRemoveUserAccessConfirmation({
      user,
      type,
      typeId,
      contentName,
      refreshSharedWith,
    });

  return (
    <>
      <ItemDiv>
        <Row centerAlign>
          <div style={{ display: 'flex', marginRight: 8 }}>
            <UserIcon {...user} isSmall noMargin />
          </div>
          <Typography.Body type={'Button Text'}>
            {user.displayName}
          </Typography.Body>
        </Row>
        <Button
          size={'Small'}
          type={'Ghost'}
          icon="cross"
          onClick={onRemoveClicked}
        />
      </ItemDiv>
      {RemoveConfirmation}
    </>
  );
};

export default UserWithAccess;
