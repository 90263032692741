import React, { useCallback, useContext, useState } from 'react';

import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import portalTypeCheckers from 'types/portalTypeCheckers';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import ToastContext from 'contexts/ToastContext';

import useFormState from './useFormState';
import ColorDot from '../ColorDot';

const CreatePortalForm = ({ close }: { close: () => void }) => {
  const { showToast } = useContext(ToastContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const {
    name,
    onNameChanged,
    portalTypeOptions,
    colorOptions,
    draftPortal,
    color,
    onUrlSlugChanged,
    urlSlug,
  } = useFormState();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onCreateClicked = useCallback(() => {
    if (!draftPortal) {
      return;
    }
    setIsSaving(true);

    if (portalTypeCheckers.isExecutivePortal(draftPortal)) {
      STORE.contentDistributions
        .getExecutivePortalsRef({
          accountId: selectedAccountId,
        })
        .doc(draftPortal.id)
        .set(draftPortal)
        .then(() => {
          setIsSaving(false);
          showToast(`${name} Executive Portal has been created`);
          close();
        });
    } else if (portalTypeCheckers.isEngagementPortal(draftPortal)) {
      STORE.contentDistributions
        .getEngagementPortalsRef({
          accountId: selectedAccountId,
        })
        .doc(draftPortal.id)
        .set(draftPortal)
        .then(() => {
          setIsSaving(false);
          showToast(`${name} Engagement Portal has been created`);
          close();
        });
    }
  }, [close, draftPortal, name, selectedAccountId, showToast]);

  return (
    <Form>
      <FormHeader title={'New Portal'} onClose={close} />
      <FormContent>
        {/* Type */}
        <div style={{ marginBottom: 24, display: 'none' }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Type</Typography.Body>
          </div>
          <Dropdown options={portalTypeOptions} />
        </div>
        {/* Name */}
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Name</Typography.Body>
          </div>
          <TextInput value={name} onChange={onNameChanged} />
        </div>
        {/* Url slug */}
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Url Slug</Typography.Body>
          </div>
          <TextInput value={urlSlug} onChange={onUrlSlugChanged} />
        </div>
        {/* Color */}
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Label'}>Portal Color</Typography.Body>
          </div>
          <Dropdown
            options={colorOptions}
            renderSelectedLabel={() =>
              color ? (
                <ColorDot
                  color={color}
                  key={`selected-${color}`}
                  width={20}
                  height={20}
                />
              ) : (
                <div />
              )
            }
          />
        </div>
      </FormContent>
      <Row spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
          isDisabled={isSaving}
        />
        <Button
          size={'Small'}
          type={'Primary'}
          label={'Create'}
          onClick={onCreateClicked}
          isLoading={isSaving}
          isDisabled={draftPortal === undefined || isSaving}
        />
      </Row>
    </Form>
  );
};

export default CreatePortalForm;
