import React from 'react';
import styled, { css } from 'styled-components';
import ReportCanvas from '../ReportCanvas';
import Row from '../Common/Row';
import DashboardEditingButtons from '../DashboardEditingButtons';
import DashboardMenu from './DashboardMenu';
import WidgetGallery from '../WidgetGallery';
import WidgetGalleryProvider from '../../contextProviders/WidgetGalleryProvider/WidgetGalleryProvider';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Loading from '../Loading';
import VariableFiltersSlideOut from '../VariableFiltersSlideOut';

import ReactDOM from 'react-dom';
import ConfigureDashboardGadgetProvider from '../../contextProviders/ConfigureDashboardGadgetProvider';
import { CANVAS_ID, EXCLUDE_HEIGHT, TOP_BAR_HEIGHT } from '../../constants';
import ReportDrillDownsProvider from '../../contextProviders/ReportDrillDownsProvider';
import PERMISSIONS from '../../permissionDefinitions';
import PermissionGates from '../PermissionGates';
import isDashboardTemplate from '../../types/isDashboardTemplate';
import { DASHBOARD_MENU_PORTAL_DIV_ID } from '../../screens/DashboardShow/constants';
import Form from '../../kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';
import Tooltip from '../Tooltip';

const TopBarRow = styled(Row)`
  padding: 0px 24px;
  align-items: center;
`;

const TargetAppDashboardCss = css`
  height: calc(100vh - ${TOP_BAR_HEIGHT * 2}px);
  max-height: calc(100vh - ${TOP_BAR_HEIGHT * 2}px);
`;

const EntityAppDashboardCss = css`
  height: calc(100vh - ${TOP_BAR_HEIGHT * 2}px);
  max-height: calc(100vh - ${TOP_BAR_HEIGHT * 2}px);
`;

const TargetAppWallboardCss = css`
  height: 100vh;
  max-height: 100vh;
`;

const ContentX = styled.div<{
  isTargetsApp?: boolean;
  isWallboard?: boolean;
  isEntityApp?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  position: relative;
  height: calc(100vh - ${TOP_BAR_HEIGHT}px);
  max-height: calc(100vh - ${TOP_BAR_HEIGHT}px);
  background-color: white;

  ${(props) => props.isTargetsApp && TargetAppDashboardCss}
  ${(props) => props.isWallboard && TargetAppWallboardCss}
  ${(props) => props.isEntityApp && EntityAppDashboardCss}
`;

const DashboardMenuPortal = ({ children }: { children: JSX.Element }) => {
  const domNode = document.getElementById(DASHBOARD_MENU_PORTAL_DIV_ID);
  if (domNode) {
    return ReactDOM.createPortal(children, domNode);
  } else {
    return null;
  }
};

const MENU_PERMISSIONS = [
  PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
  PERMISSIONS.DASHBOARDS.EDIT,
  PERMISSIONS.DASHBOARDS.CREATE,
  PERMISSIONS.DASHBOARDS.DELETE_ANY_DASHBOARD,
  PERMISSIONS.DASHBOARDS.DELETE_CREATED_BY_ME,
];

const AdminStuff = ({
  isEditing,
  startEditing,
  saveDashboardAs,
  createTemplateFrom,
  onDelete,
  setCurrentCanvas,
  canvasMode,
  isTemplate,
  openVariableFiltersSlideOut,
}: {
  isEditing: boolean;
  startEditing: () => void;
  saveDashboardAs: (name: string) => Promise<any>;
  createTemplateFrom: () => Promise<void>;
  saveEditAs: (name: string) => Promise<any>;
  onDelete: () => void;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  canvasMode: CanvasMode;
  isTemplate: boolean;
  openVariableFiltersSlideOut: () => void;
}) => (
  <DashboardMenuPortal>
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {!isEditing && (
        <Row>
          <PermissionGates.HasAny requiredPermissions={MENU_PERMISSIONS}>
            <Tooltip content={'Configure Dashboard'}>
              <DashboardMenu
                startEditing={startEditing}
                saveDashboardAs={saveDashboardAs}
                createTemplateFrom={createTemplateFrom}
                onDelete={onDelete}
                setCurrentCanvas={setCurrentCanvas}
                canvasMode={canvasMode}
                isTemplate={isTemplate}
                openVariableFiltersSlideOut={openVariableFiltersSlideOut}
              />
            </Tooltip>
          </PermissionGates.HasAny>
        </Row>
      )}
    </div>
  </DashboardMenuPortal>
);

const Dashboard = ({
  currentCanvas,
  setCurrentCanvas,
  isEditing,
  onEditSave,
  onEditCancel,
  startEditing,
  saveDashboardAs,
  createTemplateFrom,
  saveEditAs,
  onDelete,
  createAndAddDashboardWidget,
  canvasMode,
  previewCanvasMode,
  isSavingAs,
  isWallboard,
  isMobile,
  dashboardId,
  dashboard,
  isVariableFiltersSlideOutOpen,
  openVariableFiltersSlideOut,
  closeVariableFiltersSlideOut,
  isTargetsApp,
  isEntityApp,
}: {
  currentCanvas: Canvas;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  isEditing: boolean;
  onEditSave: () => void;
  onEditCancel: () => void;
  startEditing: () => void;
  saveDashboardAs: (name: string) => Promise<any>;
  createTemplateFrom: () => Promise<void>;
  saveEditAs: (name: string) => Promise<any>;
  onDelete: () => void;
  createAndAddDashboardWidget: (
    gadget: DashboardGadget,
    card: CanvasCard.Card,
  ) => Promise<void>;
  canvasMode: CanvasMode;
  previewCanvasMode?: CanvasMode;
  isSavingAs: boolean;
  isWallboard?: boolean;
  isMobile: boolean;
  dashboardId: string;
  dashboard: PersistedDashboardType;
  isVariableFiltersSlideOutOpen: boolean;
  openVariableFiltersSlideOut: () => void;
  closeVariableFiltersSlideOut: () => void;
  isTargetsApp?: boolean;
  isEntityApp?: boolean;
}) => (
  <WidgetGalleryProvider
    startEditing={startEditing}
    currentCanvas={currentCanvas}
    setCurrentCanvas={setCurrentCanvas}
    isDashboard
    createAndAddDashboardWidget={createAndAddDashboardWidget}
  >
    <ConfigureDashboardGadgetProvider>
      <ContentX
        id="dashboard"
        isTargetsApp={isTargetsApp}
        isWallboard={isWallboard}
        isEntityApp={isEntityApp}
      >
        <div
          data-testid={`dashboard-${dashboardId}`}
          style={{ flex: 1 }}
          id={`${CANVAS_ID}-${dashboardId}`}
        >
          {isEditing && !isMobile && (
            <TopBarRow
              centerAlign
              style={{ justifyContent: 'flex-end', height: TOP_BAR_HEIGHT }}
              className={`${EXCLUDE_HEIGHT}-${dashboardId}`}
            >
              <PermissionGates.Has
                requiredPermission={PERMISSIONS.DASHBOARDS.CREATE}
              >
                <DashboardEditingButtons
                  onSave={onEditSave}
                  onCancel={onEditCancel}
                  saveEditAs={saveEditAs}
                  isEditing={isEditing}
                />
              </PermissionGates.Has>
            </TopBarRow>
          )}

          {!isMobile && (
            <AdminStuff
              isEditing={isEditing}
              saveDashboardAs={saveDashboardAs}
              createTemplateFrom={createTemplateFrom}
              saveEditAs={saveEditAs}
              startEditing={startEditing}
              onDelete={onDelete}
              setCurrentCanvas={setCurrentCanvas}
              canvasMode={canvasMode}
              openVariableFiltersSlideOut={openVariableFiltersSlideOut}
              isTemplate={isDashboardTemplate(dashboard)}
            />
          )}

          {!isSavingAs && (
            <ReportCanvas
              isEditing={isEditing}
              currentCanvas={currentCanvas}
              setCurrentCanvas={setCurrentCanvas}
              previewCanvasMode={previewCanvasMode}
              startEditing={startEditing}
            />
          )}

          <WidgetGallery />
          <ModalTransition>
            {isSavingAs && (
              <Modal shouldScrollInViewport={false} autoFocus={false}>
                <Form>
                  <FormHeader title={'Saving as...'} />
                  <FormContent>
                    <div
                      style={{
                        padding: 32,
                        height: '40vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                      }}
                    >
                      <Loading />
                    </div>
                  </FormContent>
                </Form>
              </Modal>
            )}
          </ModalTransition>
        </div>
        {isDashboardTemplate(dashboard) && (
          <ReportDrillDownsProvider>
            <VariableFiltersSlideOut
              dashboardTemplate={dashboard}
              isOpen={isVariableFiltersSlideOutOpen}
              close={closeVariableFiltersSlideOut}
            />
          </ReportDrillDownsProvider>
        )}
      </ContentX>
    </ConfigureDashboardGadgetProvider>
  </WidgetGalleryProvider>
);

export default Dashboard;
