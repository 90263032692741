import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

const EXECUTIVE_PORTALS_DOC = 'executivePortals';
const CURRENT_VERSION = 'v1';

const contentPortalsConverter = {
  toFirestore(portal: ExecutivePortal): firebase.firestore.DocumentData {
    return withoutNulls({ ...portal });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<ExecutivePortal>,
    options: firebase.firestore.SnapshotOptions,
  ): ExecutivePortal {
    const data = {
      ...snapshot.data(options),
    };

    data.targetAppIds = data.targetAppIds || [];
    data.scorecardIds = data.scorecardIds || [];
    return data;
  },
};

const getExecutivePortalsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.CONTENT_DISTRIBUTIONS_COLLECTION)
    .doc(EXECUTIVE_PORTALS_DOC)
    .collection(CURRENT_VERSION)
    .withConverter(contentPortalsConverter);
};

export default getExecutivePortalsRef;
