import React from 'react';
import Row from '../Common/Row';
import Button from 'kingpin/atoms/Button';

const DashboardEditingButtons = ({
  onSave,
  onCancel,
  onSaveAs,
}: {
  onSave: () => void;
  onCancel: () => void;
  onSaveAs: () => void;
}) => (
  <Row centerAlign style={{ height: '100%' }}>
    <Button onClick={onCancel} type="Ghost" label="Cancel" size="Small" />
    <div style={{ marginRight: 8 }} />
    <Button
      size={'Small'}
      type={'Secondary'}
      label={'Save as'}
      onClick={onSaveAs}
    />
    <div style={{ marginRight: 8 }} />
    <Button onClick={onSave} size="Small" type="Primary" label="Save" />
  </Row>
);

export default DashboardEditingButtons;
