import { useCallback, useContext, useEffect, useState } from 'react';
import FeatureGatesContext from '../../contexts/FeatureGatesContext';
import STORE from '../../store';
import AccountPickerContext from '../../contexts/AccountPickerContext';

/**
 * Portals can be enable by one of two means.
 * 1) The customer's instance has been toggled as such by SE on
 * the advanced settings screen.
 * 2) The customer's instance has the ALLOW_PORTAL_PREVIEW feature gate enabled
 * and the customer has toggled on the "New UI".
 */
const useIsPortalsEnabled = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isPortalPreviewAvailableEnabled } = useContext(FeatureGatesContext);
  const [isPortalsEnabled, setIsPortalsEnabled] = useState<boolean>(false);
  const [isPortalPreviewEnabled, setIsPortalPreviewEnabled] =
    useState<boolean>(false);
  const [isPortalsEnabledAtAccountLevel, setIsPortalsEnabledAtAccountLevel] =
    useState<boolean | undefined>(undefined);

  const onPreviewNewUiClicked = useCallback(() => {
    if (!isPortalPreviewEnabled) {
      return;
    }

    setIsPortalPreviewEnabled(true);
  }, [isPortalPreviewEnabled]);

  const onRevertToOldUiClicked = useCallback(() => {
    setIsPortalPreviewEnabled(false);
  }, []);

  /**
   * Opting to keep isPortalsEnabled outside of the AccountContext as it's a
   * little confusing due to the ability to "preview" it.
   *
   * My preference is to have a single point of truth for that state
   */
  useEffect(() => {
    setIsLoading(true);
    STORE.getAccountRef({ accountId: selectedAccountId })
      .get()
      .then((doc) => {
        const account = doc.data();
        if (account) {
          setIsPortalsEnabledAtAccountLevel(account.isPortalsEnabled);
          setIsLoading(false);
        }
      });
  }, [selectedAccountId]);

  /**
   * Using both the account level flag and the "preview" state,
   * determine if portals are enabled or not
   */
  useEffect(() => {
    if (isPortalsEnabledAtAccountLevel) {
      setIsPortalsEnabled(true);
    } else if (isPortalPreviewAvailableEnabled && isPortalPreviewEnabled) {
      setIsPortalsEnabled(true);
    } else {
      setIsPortalsEnabled(false);
    }
  }, [
    isPortalPreviewAvailableEnabled,
    isPortalPreviewEnabled,
    isPortalsEnabledAtAccountLevel,
  ]);

  return {
    isPortalsEnabled,
    onPreviewNewUiClicked,
    onRevertToOldUiClicked,
    isLoading,
  };
};

export default useIsPortalsEnabled;
