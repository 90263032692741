import STORE from '../store';

const updateDashboardGadget = async (
  id: string,
  gadget: DashboardGadget,
  accountId: string,
) => {
  await STORE.visualisations
    .getDashboardGadgetsRef({ accountId })
    .doc(id)
    .set(gadget);
  return id;
};

export default updateDashboardGadget;
