import React, { useContext, useState } from 'react';
import DashboardEditingButtons from './DashboardEditingButtons';
import DashboardForm from '../DashboardForm';
import usePopup from '../../hooks/usePopup';
import DashboardContext from '../../contexts/DashboardContext';
import ConfirmationModal from '../ConfirmationModal';
import useTemplateInstances from '../../hooks/dashboards/useTemplateInstances';

const DashboardEditingButtonsContainer = ({
  onSave,
  onCancel,
  isEditing,
  saveEditAs,
}: {
  onSave: () => void;
  onCancel: () => void;
  isEditing: boolean;
  saveEditAs: (name: string) => Promise<any>;
}) => {
  const { dashboard } = useContext(DashboardContext);
  const templateInstances = useTemplateInstances(dashboard);
  const {
    isOpen: saveAsIsOpen,
    open: openSaveAs,
    close: closeSaveAs,
  } = usePopup();
  const [savingAs, setSavingAs] = useState(false);
  const [isShowingConfirmation, setIsShowingConfirmation] =
    useState<boolean>(false);

  if (!dashboard) {
    return null;
  }
  const isTemplate = dashboard.isTemplate;

  const onSaveAs = ({ name }: { name: string }) => {
    setSavingAs(true);
    saveEditAs(name).then(() => {
      setSavingAs(false);
      closeSaveAs();
    });
  };

  const onSaveClicked = () => {
    if (isTemplate) {
      setIsShowingConfirmation(true);
    } else {
      onSave();
    }
  };

  if (!isEditing) {
    return null;
  }

  return (
    <React.Fragment>
      <DashboardEditingButtons
        onSave={onSaveClicked}
        onCancel={onCancel}
        onSaveAs={openSaveAs}
      />
      <DashboardForm
        close={closeSaveAs}
        isOpen={saveAsIsOpen}
        onSaveAs={onSaveAs}
        isSaveAs
        isSavingAs={savingAs}
      />
      <ConfirmationModal
        isOpen={isShowingConfirmation}
        close={() => {
          setIsShowingConfirmation(false);
        }}
        title={'Update template'}
        body={`${templateInstances.length} dashboard${
          templateInstances.length !== 1 ? 's' : ''
        } will be affected. Are you sure you want to make this change?`}
        confirmText={'Update'}
        onConfirmed={onSave}
      />
    </React.Fragment>
  );
};

export default DashboardEditingButtonsContainer;
