import React, { useMemo } from 'react';
import styled from 'styled-components';
import Typography from './atoms/Typography';

export type AlertType = 'Default' | 'Info' | 'Warning' | 'Danger' | 'Success';

const getBackgroundColor = (type: AlertType): string => {
  switch (type) {
    case 'Success':
      return '#EBFAE2';
    case 'Danger':
      return '#FDF2F2';
    case 'Warning':
      return '#FFF2E4';
    case 'Default':
      return '#FFFFFF';
    case 'Info':
      return '#F7F9FF';
  }
};

const getBorderColor = (type: AlertType): string => {
  switch (type) {
    case 'Success':
      return '#D2F0C0';
    case 'Danger':
      return '#F8B0B4';
    case 'Warning':
      return '#FFC382';
    case 'Default':
      return '#DEDEDE';
    case 'Info':
      return '#BBCCFF';
  }
};

const AlertDiv = styled.div<{ type: AlertType }>`
  background-color: ${(props) => getBackgroundColor(props.type)};
  border: 1px solid ${(props) => getBorderColor(props.type)};
  border-radius: 8px;
  padding: 16px;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

const Alert = ({
  title,
  body,
  type,
}: {
  title: string | JSX.Element;
  body?: string | JSX.Element;
  type: AlertType;
}) => {
  const Title = useMemo(() => {
    if (typeof title === 'string') {
      return <Typography.Body type="Label">{title}</Typography.Body>;
    }

    return <>{title}</>;
  }, [title]);

  const Body = useMemo(() => {
    if (typeof body === 'string') {
      return <Typography.Body type="Body 12">{body}</Typography.Body>;
    }

    return <>{body}</>;
  }, [body]);

  return (
    <AlertDiv type={type}>
      {Title}
      {Body}
    </AlertDiv>
  );
};

export default Alert;
