import React from 'react';
import UpdateWarning from './UpdateWarning';
import useTemplateInstances from '../../../hooks/dashboards/useTemplateInstances';

const UpdateWarningContainer = ({
  openBulkUpdater,
  variableFilter,
  template,
}: {
  openBulkUpdater: () => void;
  variableFilter: VariableDrillDownType;
  template: PersistedDashboardType;
}) => {
  const templateInstances = useTemplateInstances(template);
  const withoutFilterSet = templateInstances.filter(
    (dashboard) =>
      !dashboard.variableDrillDowns.some((d) => d.id === variableFilter.id),
  ).length;

  if (withoutFilterSet === 0) {
    return null;
  }

  return (
    <UpdateWarning
      onUpdateClicked={openBulkUpdater}
      withoutFilterSet={withoutFilterSet}
    />
  );
};

export default UpdateWarningContainer;
