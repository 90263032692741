import aguid from 'aguid';
import { DateTime } from 'luxon';
import firebase from 'firebase/compat/app';

import { DASHBOARDS_COLLECTION } from '../constants';
import withoutNulls from './search/withoutNulls';
import { CurrentUserContextType } from '../contexts/CurrentUserContext';

const createDashboard = async ({
  currentUser,
  name,
  isTemplate,
  access,
  accountRef,
  wallboard,
}: {
  currentUser: CurrentUserContextType;
  name: string;
  isTemplate?: boolean;
  access: ResourceAccess;
  accountRef: firebase.firestore.DocumentReference;
  wallboard?: Wallboard;
}) => {
  const newDashboard = {
    version: '5' as '5',
    id: aguid(),
    name,
    canvas: {
      cards: [],
    },
    variableDrillDowns: [],
    isTemplate: !!isTemplate,
    createdBy: currentUser.id,
    updatedBy: currentUser.id,
    createdOn: DateTime.utc().toISO(),
    updatedOn: DateTime.utc().toISO(),
    access,
  } as PersistedDashboardType;

  if (wallboard) {
    newDashboard.isWallboardSlide = true;
    newDashboard.wallboardId = wallboard.id;
  }

  await (accountRef as any)
    .collection(DASHBOARDS_COLLECTION)
    .doc(newDashboard.id)
    .set(withoutNulls(newDashboard));
  return {
    newDashboardId: newDashboard.id,
    newDashboard,
  };
};

export default createDashboard;
