import React, { useCallback, useContext, useEffect, useState } from 'react';
import Row from 'components/Common/Row';
import UserIcon from 'components/Common/UserIcon';
import UsersContext from 'contexts/UsersContext';
import userTypeCheckers from 'contextProviders/UsersProvider/userTypeCheckers';
import Typography from 'kingpin/atoms/Typography';
import UserOptionRight from './UserOptionRight';

const useUserOptions = ({
  selectedUsers,
  setSelectedUsers,
  sharedWith,
  type,
  typeId,
}: {
  selectedUsers: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<
      (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
    >
  >;
  sharedWith: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  type: 'report' | 'dashboard';
  typeId: string;
}) => {
  const { allUsers } = useContext(UsersContext);
  const [userOptions, setUserOptions] = useState<DropdownOption[]>([]);

  const getUsersWithoutAccess = useCallback(() => {
    return allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .filter((u) => !sharedWith.some((sU) => sU.id === u.id));
  }, [allUsers, sharedWith]);

  useEffect(() => {
    const newOptions: DropdownOption[] = getUsersWithoutAccess().map((user) => {
      const isSelected = selectedUsers.some((su) => su.id === user.id);
      return {
        key: user.id,
        label: user.displayName,
        renderLabel: () => (
          <Row centerAlign style={{ marginRight: 32 }}>
            <div style={{ marginRight: 8, display: 'flex' }}>
              <UserIcon {...user} noMargin isSmall />
            </div>
            <Typography.Body type={'Button Text'}>
              {user.displayName}
            </Typography.Body>
          </Row>
        ),
        renderRight: () => (
          <UserOptionRight
            user={user}
            isSelected={isSelected}
            type={type}
            typeId={typeId}
          />
        ),
        isSelected,
        onSelected: () => {
          if (isSelected) {
            setSelectedUsers((currentlySelected) =>
              currentlySelected.filter((u) => u.id !== user.id),
            );
          } else {
            setSelectedUsers((currentlySelected) => [
              ...currentlySelected,
              user,
            ]);
          }
        },
      };
    });

    setUserOptions(newOptions);
  }, [getUsersWithoutAccess, selectedUsers, setSelectedUsers, type, typeId]);

  return userOptions;
};

export default useUserOptions;
