import { useCallback, useEffect, useState } from 'react';

export interface PublishedLookup {
  reports: { [reportId: string]: ExecutivePortal[] | undefined };
  dashboards: { [dashboardId: string]: ExecutivePortal[] | undefined };
  targetApps: { [targetAppId: string]: ExecutivePortal[] | undefined };
  scorecards: { [scorecardId: string]: ExecutivePortal[] | undefined };
}

const usePublishedLookup = (allExecutivePortals: ExecutivePortal[]) => {
  const buildLookup = useCallback((): PublishedLookup => {
    const newLookup: PublishedLookup = {
      reports: {},
      dashboards: {},
      targetApps: {},
      scorecards: {},
    };

    allExecutivePortals.forEach((executivePortal: ExecutivePortal) => {
      executivePortal.reportIds.forEach((reportId: string) => {
        const current = newLookup.reports[reportId] || [];
        if (!current.some((p) => p.id === executivePortal.id)) {
          current.push(executivePortal);
          newLookup.reports[reportId] = current;
        }
      });

      executivePortal.dashboardIds.forEach((dashboardId: string) => {
        const current = newLookup.dashboards[dashboardId] || [];
        if (!current.some((p) => p.id === executivePortal.id)) {
          current.push(executivePortal);
          newLookup.dashboards[dashboardId] = current;
        }
      });

      executivePortal.scorecardIds.forEach((scorecardId: string) => {
        const current = newLookup.scorecards[scorecardId] || [];
        if (!current.some((p) => p.id === executivePortal.id)) {
          current.push(executivePortal);
          newLookup.scorecards[scorecardId] = current;
        }
      });

      executivePortal.targetAppIds.forEach((targetAppId: string) => {
        const current = newLookup.targetApps[targetAppId] || [];
        if (!current.some((p) => p.id === executivePortal.id)) {
          current.push(executivePortal);
          newLookup.targetApps[targetAppId] = current;
        }
      });
    });

    return newLookup;
  }, [allExecutivePortals]);

  const [publishedLookup, setPublishedLookup] = useState<PublishedLookup>(() =>
    buildLookup(),
  );

  useEffect(() => {
    setPublishedLookup(buildLookup());
  }, [buildLookup]);

  return publishedLookup;
};

export default usePublishedLookup;
