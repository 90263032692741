import React, { useContext, useEffect, useState } from 'react';
import CustomerLaneCommitBoard from './CustomerLaneCommitBoard';
import BoardContext from '../../contexts/BoardContext';
import CustomerLaneCommitsContext from '../../contexts/CustomerLaneCommitsContext';
import useQuickFilters from '../../hooks/useQuickFilters';
import toFilterInput from '../../toFilterInput';
import CustomerLaneSlideOutProvider, {
  useLaneParams,
} from '../../contextProviders/CustomerLaneSlideOutProvider';
import useReasonCodes from '../../hooks/useReasonCodes';
import AccountContext from '../../contexts/AccountContext';
import CommitmentQueryProvider from '../../contextProviders/CommitmentQueryProvider';
import toDrillDowns from '../Report/toReportDrillDown';
import { DATA_TYPE } from './constants';
import { DateTime } from 'luxon';
import withDateFilter from '../../hocs/withDateFIlter';
import DateInputContext from '../../contexts/DateInputContext';
import { DEMO_DATE } from '../../hooks/useBoardMetricFilter';
import moment from 'moment';
import GqlClientProvider from '../../contextProviders/GqlClientProvider';
import useBoardOpenedTracking from '../../hooks/useBoardTracking';
import ReportDrillDownsProvider from '../../contextProviders/ReportDrillDownsProvider';
import { platesToFilterInput } from 'screens/GoalShow/useGoalData';
import useHideIntercom from '../../hooks/useHideIntercom';

const DATA_TYPES = [DATA_TYPE];

const CustomerLaneCommitBoardContainer = ({
  board,
  onDeleted,
  isDeletable,
  initialRunTimeFilters,
  initialScopeFilters,
}: {
  board: CustomerLaneBoard;
  onDeleted?: () => void;
  isDeletable?: boolean;
  initialRunTimeFilters?: FilterPlate[];
  initialScopeFilters?: FilterPlate[];
}) => {
  const { weekStartsOn, isDemoAccount } = useContext(AccountContext);
  const { setDateRange } = useContext(DateInputContext);
  const quickFilters = useQuickFilters(
    `${board.dataType} - board: ${board.id}`,
  );
  const [variableDrillDowns] = useState<VariableDrillDownType[]>([]);
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(() =>
    initialRunTimeFilters ? initialRunTimeFilters : [],
  );
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<
    SavedFilter[]
  >([]);
  const [selectedLane, setSelectedLane] = useState<string | undefined>();
  const [netQuickFilters, setNetQuickFilters] = useState<FilterInput[]>([]);
  const reasonCodes = useReasonCodes(board.dataType);

  const { onManualFilterChanged, onQuickFilterChanged } =
    useBoardOpenedTracking({ board });
  useHideIntercom();

  const [commitmentSlideoutParams, setCommitmentSlideoutParams] =
    useState<CommitmentSlideoutParams>({});

  const { lane } = useLaneParams({ commitmentSlideoutParams });

  useEffect(() => {
    if (isDemoAccount) {
      setDateRange({
        startDate: moment(DEMO_DATE).subtract({ week: 1 }).format('YYYY-MM-DD'),
        endDate: DEMO_DATE,
      });
    }
  }, [isDemoAccount, setDateRange]);

  useEffect(() => {
    setSelectedLane(lane);
  }, [lane]);

  useEffect(() => {
    setNetQuickFilters([
      ...selectedQuickFilters.map((qf) =>
        toFilterInput(qf, variableDrillDowns),
      ),
      ...(initialScopeFilters
        ? [platesToFilterInput(initialScopeFilters)]
        : []),
    ]);
  }, [
    initialScopeFilters,
    selectedQuickFilters,
    variableDrillDowns,
    weekStartsOn,
  ]);

  return (
    <ReportDrillDownsProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      scope={initialScopeFilters}
      variableDrillDowns={variableDrillDowns}
      dataTypes={DATA_TYPES}
      quickFilters={netQuickFilters}
    >
      <div data-testid="popup-board" />
      <CustomerLaneCommitsContext.Provider
        value={{
          drillDowns: toDrillDowns({
            plates: drillDowns,
            variableDrillDowns,
          }),
          selectedLane,
          commitmentSlideoutParams,
          setCommitmentSlideoutParams,
        }}
      >
        <BoardContext.Provider
          value={{
            board,
            reasonCodes,
            isDeletable,
            onDeleted,
            quickFilters,
            selectedQuickFilters,
            setSelectedQuickFilters,
          }}
        >
          <GqlClientProvider
            visType={'customerLaneBoard'}
            visName={board.name}
            visId={board.id}
          >
            <CommitmentQueryProvider>
              <CustomerLaneSlideOutProvider>
                <CustomerLaneCommitBoard
                  board={board}
                  onManualFilterChanged={onManualFilterChanged}
                  onQuickFilterChanged={onQuickFilterChanged}
                />
              </CustomerLaneSlideOutProvider>
            </CommitmentQueryProvider>
          </GqlClientProvider>
        </BoardContext.Provider>
      </CustomerLaneCommitsContext.Provider>
    </ReportDrillDownsProvider>
  );
};

export default withDateFilter(CustomerLaneCommitBoardContainer, {
  initialDataTypes: DATA_TYPES,
  initialDateField: 'endDate',
  initialDateRange: {
    startDate: DateTime.utc().toISODate(),
  },
});
