import React, { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ExecutivePortalsProvider from './ExecutivePortalsProvider';
import EngagementPortalsProvider from './EngagementPortalsProvider';
import useIsPortalsEnabled from './useIsPortalsEnabled';
import FlexCentered from '../../components/Common/FlexCentered';
import Loading from '../../components/Loading';
import useHasAvailablePortal from './useHasAvailablePortal';
import appRoutes from '../../navigation/appRoutes';
import portalTypeCheckers from '../../types/portalTypeCheckers';
import ToastContext from '../../contexts/ToastContext';

interface PortalsProviderContextType {
  selectedPortal?: Portal;
  onPortalSelected: (portal: Portal) => void;
  isPortalsEnabled: boolean;
  onPreviewNewUiClicked: () => void;
  onRevertToOldUiClicked: () => void;
  hasAvailablePortal: boolean;
  availablePortals: Portal[];
  onPortalUrlSlugChanged: (portalUrlSlug: string) => void;
  onPortalExit: () => void;
}

export const PortalsContext = createContext<PortalsProviderContextType>({
  onPortalSelected: window.tokenFunction,
  isPortalsEnabled: false,
  onPreviewNewUiClicked: window.tokenFunction,
  onRevertToOldUiClicked: window.tokenFunction,
  hasAvailablePortal: false,
  availablePortals: window.emptyArray,
  onPortalUrlSlugChanged: window.tokenFunction,
  onPortalExit: window.tokenFunction,
});

const PortalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const navigate = useNavigate();
  const {
    isPortalsEnabled,
    isLoading,
    onPreviewNewUiClicked,
    onRevertToOldUiClicked,
  } = useIsPortalsEnabled();
  const { showToast } = useContext(ToastContext);
  const [selectedPortal, setSelectedPortal] = useState<Portal | undefined>();
  const { hasAvailablePortal, availablePortals } = useHasAvailablePortal();

  const onPortalSelected = useCallback(
    (portal: Portal) => {
      setSelectedPortal(portal);
      if (portalTypeCheckers.isAdminPortal(portal)) {
        navigate(appRoutes.home);
      } else if (portalTypeCheckers.isExecutivePortal(portal)) {
        navigate(`/${portal.urlSlug}/dashboards`);
      } else if (portalTypeCheckers.isEngagementPortal(portal)) {
        navigate(`/${portal.urlSlug}`);
      }
    },
    [navigate],
  );

  const onPortalExit = useCallback(() => {
    setSelectedPortal((p) => {
      return undefined;
    });
  }, []);

  /**
   * The PortalsProvider is higher in the React tree than the Route where
   * the portalUrlSlug is defined. Therefore we need to provide
   * this via context
   */
  const onPortalUrlSlugChanged = useCallback(
    (portalUrlSlug: string) => {
      if (selectedPortal && portalTypeCheckers.isAdminPortal(selectedPortal)) {
        return;
      }

      if (selectedPortal && selectedPortal.urlSlug === portalUrlSlug) {
        return;
      }

      const newSelectedPortal = availablePortals.find(
        (p) => p.urlSlug === portalUrlSlug,
      );

      if (!newSelectedPortal) {
        showToast('We could not find that portal');
        navigate(appRoutes.home);
        return;
      }

      setSelectedPortal(newSelectedPortal);
    },
    [availablePortals, navigate, selectedPortal, showToast],
  );

  if (isLoading) {
    return (
      <FlexCentered>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <PortalsContext.Provider
      value={{
        onPortalSelected,
        selectedPortal,
        isPortalsEnabled,
        onPreviewNewUiClicked,
        onRevertToOldUiClicked,
        hasAvailablePortal,
        availablePortals,
        onPortalUrlSlugChanged,
        onPortalExit,
      }}
    >
      {children}
    </PortalsContext.Provider>
  );
};

const Gate = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <ExecutivePortalsProvider>
      <EngagementPortalsProvider>
        <PortalsProvider>{children}</PortalsProvider>
      </EngagementPortalsProvider>
    </ExecutivePortalsProvider>
  );
};

export default Gate;
