import VariableFiltersContext from 'contexts/VariableFiltersContext';
import React, { useEffect, useState } from 'react';
import VariableFiltersSlideOut from './VariableFiltersSlideOut';
import SlideOut from '../Slideout';

const VariableFiltersSlideOutContainer = ({
  dashboardTemplate,
  isOpen,
  close,
}: {
  dashboardTemplate: DashboardTemplate;
  isOpen: boolean;
  close: () => void;
}) => {
  const [variableFilters, setVariableFilters] = useState<
    VariableDrillDownType[]
  >(dashboardTemplate.variableDrillDowns);

  useEffect(() => {
    setVariableFilters(dashboardTemplate.variableDrillDowns);
  }, [dashboardTemplate.variableDrillDowns]);

  return (
    <SlideOut isOpen={isOpen} close={close}>
      <VariableFiltersContext.Provider
        value={{
          variableFilters,
          setVariableFilters,
        }}
      >
        <VariableFiltersSlideOut
          close={close}
          dashboardTemplate={dashboardTemplate}
        />
      </VariableFiltersContext.Provider>
    </SlideOut>
  );
};
export default VariableFiltersSlideOutContainer;
