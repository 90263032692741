const isExecutivePortal = (portal: Portal): portal is ExecutivePortal =>
  portal.type === 'ExecutivePortal';

const isEngagementPortal = (portal: Portal): portal is EngagementPortal =>
  portal.type === 'Engagement Portal';

const isAdminPortal = (portal: Portal): portal is AdminPortal =>
  portal.type === 'Admin Portal';

const portalTypeCheckers = {
  isExecutivePortal,
  isEngagementPortal,
  isAdminPortal,
};

export default portalTypeCheckers;
