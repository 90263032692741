import cardTypeCheckers from '../../../types/cardTypeCheckers';
import isDefined from '../../../isDefined';
import { useCallback, useContext, useEffect, useState } from 'react';
import DashboardGadgetsContext from '../../../contexts/DashboardGadgetsContext';
import MetricListsContext from '../../../contexts/MetricListsContext';
import SavedFiltersContext from '../../../contexts/SavedFiltersContext';
import DashboardsContext from '../../../contexts/DashboardsContext';

// Variable filter plates which use this variable filter
const useInstances = (
  dashboardTemplate: DashboardTemplate,
  variableFilter: VariableDrillDownType,
) => {
  const { allDashboards } = useContext(DashboardsContext);
  const { allGadgets } = useContext(DashboardGadgetsContext);
  const { metricLists: metricListGadgets } = useContext(MetricListsContext);
  const { savedFilters } = useContext(SavedFiltersContext);

  const getInstances = useCallback((): {
    gadgets: DashboardGadget[];
    savedFilters: SavedFilter[];
    dashboards: PersistedDashboardType[];
  } => {
    const { canvas } = dashboardTemplate;
    // Find dashboard gadgets
    const dashboardGadgets = canvas.cards
      .filter(cardTypeCheckers.isDashboardGadget)
      .map((c) => allGadgets.find((g) => g.id === c.content.dashboardGadgetId))
      .filter(isDefined);
    const gadgetsWithThisVariableFilter = dashboardGadgets.filter(
      (dg) =>
        dg.drillDowns.some(
          (d) => d.type === 'Variable' && d.variableId === variableFilter.id,
        ) ||
        dg.scope.some(
          (d) => d.type === 'Variable' && d.variableId === variableFilter.id,
        ),
    );

    // Find metric list items
    const metricLists = canvas.cards
      .filter(cardTypeCheckers.isMetricList)
      .map((c) =>
        metricListGadgets.find((mlg) => mlg.id === c.content.metricListId),
      )
      .filter(isDefined);
    const metricListItems = metricLists.reduce(
      (allItems, ml) => [...allItems, ...ml.list],
      [] as MetricListItemType[],
    );
    const usedSavedFilters = metricListItems
      .map((mli) => savedFilters.find((sf) => sf.id === mli.savedFilterId))
      .filter((sf) => !!sf) as SavedFilter[];
    const usedSavedFiltersWithThisVariableFilter = usedSavedFilters.filter(
      (sf) =>
        sf.scope.some(
          (d) => d.type === 'Variable' && d.variableId === variableFilter.id,
        ) ||
        sf.drillDowns.some(
          (d) => d.type === 'Variable' && d.variableId === variableFilter.id,
        ),
    );

    // Find dashboards
    const dashboards = allDashboards
      .filter((d) =>
        d.variableDrillDowns.some((vd) => vd.id === variableFilter.id),
      )
      .filter((d) => !d.isTemplate);

    return {
      gadgets: gadgetsWithThisVariableFilter,
      savedFilters: usedSavedFiltersWithThisVariableFilter,
      dashboards,
    };
  }, [
    allDashboards,
    allGadgets,
    dashboardTemplate,
    metricListGadgets,
    savedFilters,
    variableFilter.id,
  ]);

  const [instances, setInstances] = useState(() => getInstances());
  useEffect(() => {
    setInstances(getInstances());
  }, [getInstances]);

  return instances;
};

export default useInstances;
