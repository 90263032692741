import React, { useContext, useEffect, useState } from 'react';
import NavItem from '../../NavItem';
import NavSection from '../../NavSection';
import { executivePortalRouteBuilders } from '../../../../navigation/portalRoutes/executivePortalRoutes';
import WorkSpacesContext from '../../../../contexts/WorkSpacesContext';
import { isTargetsApp } from '../../../../types/workSpaceTypeCheckers';

const useTargetApp = (targetAppId: string) => {
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const [targetApp, setTargetApp] = useState<TargetsApp.App | undefined>();

  useEffect(() => {
    setTargetApp(
      allWorkSpaces.filter(isTargetsApp).find((a) => a.id === targetAppId),
    );
  }, [allWorkSpaces, targetAppId]);

  return targetApp;
};

const TargetAppNavItem = ({
  targetAppId,
  portal,
}: {
  targetAppId: string;
  portal: ExecutivePortal;
}) => {
  const targetApp = useTargetApp(targetAppId);
  if (!targetApp) {
    return null;
  }

  return (
    <NavItem
      to={executivePortalRouteBuilders(portal.urlSlug).targetAppShow(
        targetAppId,
      )}
      label={targetApp.title}
      tooltip={targetApp.title}
      icon={'navigation-metrics'}
    />
  );
};

const ExecutivePortalNavSection = ({ portal }: { portal: ExecutivePortal }) => {
  const hasReports = portal.reportIds.length > 0;
  const hasScorecards = portal.scorecardIds.length > 0;

  return (
    <>
      <NavSection label="View">
        <>
          <NavItem
            to={executivePortalRouteBuilders(portal.urlSlug).dashboardsIndex}
            label={'Dashboards'}
            tooltip={'Dashboards'}
            icon={'dashboard'}
            onClickEvent={'Navigation Sidebar - Clicked Dashboards'}
          />
          {hasReports && (
            <NavItem
              to={executivePortalRouteBuilders(portal.urlSlug).reportsIndex}
              label={'Reports'}
              tooltip={'Reports'}
              icon={'find-in-page'}
              onClickEvent={'Navigation Sidebar - Clicked Reports'}
            />
          )}
          {hasScorecards && (
            <NavItem
              to={executivePortalRouteBuilders(portal.urlSlug).scorecardsIndex}
              label={'KPI Lists'}
              tooltip={'KPI Lists'}
              icon={'navigation-goals'}
              onClickEvent={'Navigation Sidebar - Clicked KPI Lists'}
            />
          )}
          {portal.targetAppIds.map((targetAppId) => (
            <TargetAppNavItem
              portal={portal}
              targetAppId={targetAppId}
              key={targetAppId}
            />
          ))}
        </>
      </NavSection>
    </>
  );
};

export default ExecutivePortalNavSection;
