import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import usePopup from '../hooks/usePopup';
import CustomerLaneSlideOutContext from '../contexts/CustomerLaneSlideOut';
import { buildShowBoard } from '../navigation/appRoutes';
import BoardContext from '../contexts/BoardContext';
import { DASHBOARDS_COLLECTION } from '../constants';
import useQueryParams from '../hooks/useQueryParams';
import CustomerLaneCommitsContext from 'contexts/CustomerLaneCommitsContext';

export const useLaneParams = ({
  commitmentSlideoutParams,
}: {
  commitmentSlideoutParams: CommitmentSlideoutParams;
}) => {
  const query = useQueryParams();
  const [params, setParams] = useState<{
    lane?: string;
    customer?: string;
    billTo?: string;
    customerType?: string;
    documentId?: string;
  }>({});

  const getParam = useCallback(
    (name: string) => {
      if (query.has(name)) {
        return query.get(name) as string;
      }
      return undefined;
    },
    [query],
  );

  useEffect(() => {
    if (
      commitmentSlideoutParams &&
      commitmentSlideoutParams.lane !== undefined
    ) {
      setParams(commitmentSlideoutParams);
    } else {
      setParams({
        lane: getParam('lane'),
        customer: getParam('customer'),
        billTo: getParam('billTo'),
        customerType: getParam('customerType'),
        documentId: getParam('documentId'),
      });
    }
  }, [commitmentSlideoutParams, getParam, query]);

  return params;
};

const CustomerLaneSlideOutProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isOpen, open, close } = usePopup();

  const location = useLocation();
  const navigate = useNavigate();
  const { board } = useContext(BoardContext);

  const { commitmentSlideoutParams } = useContext(CustomerLaneCommitsContext);

  const { lane, customer, billTo, customerType, documentId } = useLaneParams({
    commitmentSlideoutParams,
  });

  const onClose = useCallback(() => {
    close();
    if (lane && !location.pathname.includes('dashboards')) {
      if (
        location.pathname.includes('workspaces') ||
        location.pathname.includes('performance-apps') ||
        location.pathname.includes(DASHBOARDS_COLLECTION)
      ) {
        const newUri = location.pathname.split('?')[0];
        navigate(newUri);
      } else {
        navigate(buildShowBoard(board.id));
      }
    }
  }, [board.id, close, lane, location.pathname, navigate]);

  useEffect(() => {
    const customerParam = customerType === 'customer' ? customer : billTo;
    if (lane && documentId && customerParam) {
      open();
    } else {
      onClose();
    }
  }, [billTo, close, customer, customerType, documentId, lane, onClose, open]);

  return (
    <CustomerLaneSlideOutContext.Provider
      value={{
        lane,
        customer: customer ? customer : billTo,
        isCustomerLaneSlideOpen: isOpen,
        open,
        close: onClose,
        documentId,
      }}
    >
      {children}
    </CustomerLaneSlideOutContext.Provider>
  );
};

export default CustomerLaneSlideOutProvider;
