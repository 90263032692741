import React, { useCallback, useContext, useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import updateDashboard from '../../api/updateDashboard';
import { useLocation, useNavigate } from 'react-router-dom';
import appRoutes, { buildDashboardShow } from '../../navigation/appRoutes';
import aguid from 'aguid';
import { DateTime } from 'luxon';

import deleteDashboard from '../../api/deleteDashboard';
import withoutNulls from '../../api/expression/withoutNulls';
import createDashboardGadget from '../../api/createDashboardGadget';
import useWindowSize from '../../hooks/useWindowSize';
import { getCanvasMode } from '../ReportCanvas';
import { hideIntercomButton, showIntercomButton } from '../../openIntercom';
import PopupProvider from '../../contextProviders/PopupProvider';
import DashboardContext from '../../contexts/DashboardContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import Loading from 'components/Loading';
import VariableFiltersContext from '../../contexts/VariableFiltersContext';
import usePopup from '../../hooks/usePopup';
import useHasAccess from 'hooks/useHasAccess';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import BoardsProvider from '../../contextProviders/BoardsProvider';
import useContentViewTracking from '../../hooks/useContentViewTracking';
import PopupContext from '../../contexts/PopupContext';
import ReportsContext from '../../contexts/ReportsContext';
import DashboardsContext from 'contexts/DashboardsContext';
import buildTemplateNotFoundError from './buildTemplateNotFoundError';
import captureException from '../../services/captureException';
import BrokenDashboardTemplate from './BrokenDashboardTemplate';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';
import CopyGadgetProvider from '../../contextProviders/CopyGadgetProvider';
import { DASHBOARDS_COLLECTION } from '../../constants';
import WallboardContext from '../../contexts/WallboardContext';
import reloadWhenSafe from '../../reloadWhenSafe';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import GqlClientProvider from '../../contextProviders/GqlClientProvider';
import RolesContext from '../../contexts/RolesContext';
import useCreateDashboardCopy from '../../hooks/useCreateDashboardCopy';
import LocalTimelineProvider from '../../contextProviders/TimelineProvider/LocalTimelineProvider';
import TargetsAppContext from '../../screens/TargetsAppShow/TargetsAppConfigured/TargetsAppContext';
import VariableRuntimeFilters from './VariableRuntimeFilters';
import isDashboardTemplate from '../../types/isDashboardTemplate';
import EntityDetailsContext from '../../screens/EntityDetailsShow/EntityDetailsContext';

const usePopupReportId = () =>
  new URLSearchParams(useLocation().search).get('popupReportId');

const hasEditingSearchQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isEditing =
    urlParams.has('editing') && urlParams.get('editing') === 'true';
  return isEditing;
};

export const useTemplatedForm = (dashboard?: PersistedDashboardType) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { allDashboards, isLoading: isLoadingAllDashboards } =
    useContext(DashboardsContext);
  const [isBrokenTemplated, setIsBrokenTemplated] = useState<boolean>(false);
  const [templatedFrom, setTemplatedFrom] = useState<
    DashboardTemplate | undefined
  >();

  useEffect(() => {
    if (dashboard && dashboard.templateId) {
      if (!isLoadingAllDashboards && allDashboards.length > 0) {
        const t = allDashboards
          .filter(isDashboardTemplate)
          .find((d) => d.id === dashboard.templateId);
        setTemplatedFrom(t);
        const isBroken = t === undefined;
        setIsBrokenTemplated(isBroken);
      } else {
        if (process.env.NODE_ENV === 'test') {
          return;
        }

        let isActive = true;
        accountRef
          .collection(DASHBOARDS_COLLECTION)
          .doc(dashboard.templateId)
          .get()
          .then((doc) => {
            if (!isActive) {
              return;
            }
            if (doc.exists) {
              const dash = { ...doc.data() } as DashboardTemplate;
              setTemplatedFrom(dash);
            } else {
              setIsBrokenTemplated(true);
            }
          });

        return () => {
          isActive = false;
        };
      }
    }
  }, [accountRef, allDashboards, dashboard, isLoadingAllDashboards]);

  useEffect(() => {
    if (dashboard && isBrokenTemplated && dashboard.templateId) {
      const error = buildTemplateNotFoundError({
        templatedDashboardId: dashboard.id,
        dashboardTemplateId: dashboard.templateId,
      });
      captureException(error);
    }
  }, [dashboard, isBrokenTemplated]);

  return {
    isBrokenTemplated,
    templatedFrom,
  };
};

const DashboardContainer = ({
  dashboard,
  onDeleted,
  templatedFrom,
  isBrokenTemplated,
}: {
  dashboard: PersistedDashboardType;
  onDeleted?: () => void;
  templatedFrom?: DashboardTemplate;
  isBrokenTemplated?: boolean;
}) => {
  const getCanvas = useCallback(() => {
    if (dashboard.templateId) {
      if (templatedFrom) {
        return templatedFrom.canvas;
      }
    }

    return dashboard.canvas;
  }, [dashboard.canvas, dashboard.templateId, templatedFrom]);

  // Context
  const currentUser = useContext(CurrentUserContext);
  const { allReports } = useContext(ReportsContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const targetsAppContext = useContext(TargetsAppContext);
  const entityDetailsContext = useContext(EntityDetailsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { wallboard, isWallboard } = useContext(WallboardContext);
  const { openPopupReport, setSelectedReport } = useContext(PopupContext);
  const { isOpen: isPopupReportOpen } = useContext(PopupContext);
  const { addOrUpdateDashboard } = useContext(DashboardsContext);
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { currentPermissions } = useContext(RolesContext);

  // State
  const { access } = dashboard;
  const [previewCanvasMode, setPreviewCanvasMode] = useState<
    CanvasMode | undefined
  >();
  const [isSavingAs, setIsSavingAs] = useState<boolean>(false);
  const [currentCanvas, setCurrentCanvas] = useState<Canvas>(() => getCanvas());
  const [isEditing, setIsEditing] = useState<boolean>(hasEditingSearchQuery());
  const [isEditingCard, setIsEditingCard] = useState<boolean>(false);
  const [variableFilters, setVariableFilters] = useState<
    VariableDrillDownType[]
  >(() => dashboard.variableDrillDowns);
  // We change the key to trigger a refresh
  const [key, setKey] = useState<string>(aguid());
  const [isPopupGridOpen, setIsPopupGridOpen] = useState<boolean>(false);

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  useContentViewTracking({
    type: 'dashboard',
    typeId: dashboard.id,
    name: dashboard.name,
  });
  const { isMobile } = useWindowSize();
  const {
    isOpen: isVariableFiltersSlideOutOpen,
    open: openVariableFiltersSlideOut,
    close: closeVariableFiltersSlideOut,
  } = usePopup();
  const popupReportId = usePopupReportId();
  const createDashboardCopy = useCreateDashboardCopy();
  const hasAccess = useHasAccess();

  // Effects
  // Keep variable filters in sync with what is persisted
  useEffect(() => {
    setVariableFilters(dashboard.variableDrillDowns);
  }, [dashboard.variableDrillDowns]);

  // Set canvas to the template if applicable
  useEffect(() => {
    if (popupReportId) {
      const popupReport = allReports.find((r) => r.id === popupReportId);
      setSelectedReport(popupReport);
      openPopupReport([], 'Dashboard');
    }
  }, [allReports, openPopupReport, popupReportId, setSelectedReport]);

  useEffect(() => {
    trackEvent('Dashboard - Opened', {
      dashboardName: dashboard.name,
      dashboardId: dashboard.id,
      accessType: dashboard.access.type,
      source: workSpace ? 'Workspace' : 'Dashboard',
    });
  }, [
    dashboard.access.type,
    dashboard.id,
    dashboard.name,
    trackEvent,
    workSpace,
  ]);

  useEffect(() => {
    closeVariableFiltersSlideOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  // Refresh every 15 min
  useEffect(() => {
    if (
      !isWallboard &&
      !isEditing &&
      !isEditingCard &&
      !isPopupReportOpen &&
      !isPopupGridOpen
    ) {
      const refreshInterval = setInterval(
        () => {
          reloadWhenSafe(() => {
            trackEvent('Dashboard - Auto Refreshed', {
              dashboardName: dashboard.name,
              dashboardId: dashboard.id,
            });
            setKey(aguid());
          });
        },
        1000 * 60 * 15,
      );

      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [
    isPopupGridOpen,
    isPopupReportOpen,
    dashboard.id,
    dashboard.name,
    isEditing,
    isEditingCard,
    trackEvent,
    isWallboard,
  ]);

  useEffect(() => {
    if (isEditing) {
      hideIntercomButton();
      return () => {
        showIntercomButton();
      };
    }
  }, [isEditing, currentUser]);

  useEffect(() => {
    try {
      if (isEditing) {
        // @ts-ignore
        document.getElementById('intercom-container').style.display = 'none';
      } else {
        // @ts-ignore
        document.getElementById('intercom-container').style.display = 'block';
      }
    } catch (ex) {
      return;
    }
  }, [isEditing]);

  useEffect(() => {
    if (access && !workSpace && !targetsAppContext && !entityDetailsContext) {
      if (
        !hasAccess({
          access,
          resource: dashboard,
          type: 'dashboard',
          typeId: dashboard.id,
        })
      ) {
        navigate(appRoutes.home);
      }
    }
  }, [
    entityDetailsContext,
    access,
    currentUser,
    dashboard,
    workSpace,
    isWallboard,
    currentPermissions,
    hasAccess,
    targetsAppContext,
    navigate,
  ]);

  // Callbacks
  const onEditSave = useCallback(async () => {
    const newDashboard = {
      ...dashboard,
      canvas: currentCanvas,
      updatedBy: currentUser.id,
      updatedOn: DateTime.utc().toISO(),
    } as PersistedDashboardType;
    trackEvent('Dashboard - Edit - Saved', {
      isWallboardSlide: isWallboard ? 'true' : 'false',
    });

    await updateDashboard({
      id: dashboard.id,
      newDashboard: withoutNulls(newDashboard),
      accountRef,
    });
    setIsEditing(false);
    setPreviewCanvasMode(undefined);
    addOrUpdateDashboard(newDashboard);

    if (hasEditingSearchQuery()) {
      navigate(location.pathname);
    }
  }, [
    accountRef,
    addOrUpdateDashboard,
    currentCanvas,
    currentUser.id,
    dashboard,
    location,
    navigate,
    isWallboard,
    trackEvent,
  ]);

  const saveEditAs = useCallback(
    async (name: string) => {
      const { newDashboard } = await createDashboardCopy({
        name,
        dashboard: {
          ...dashboard,
          canvas: currentCanvas,
        },
        access: dashboard.access,
      });

      navigate(buildDashboardShow(newDashboard.id));
    },
    [createDashboardCopy, dashboard, currentCanvas, navigate],
  );

  // Misc
  const canvasMode =
    previewCanvasMode ||
    getCanvasMode({
      isMobile,
    });

  const startEditing = useCallback(() => {
    trackEvent('Dashboard - Edit - Started', {
      isWallboardSlide: isWallboard ? 'true' : 'false',
    });
    setIsEditing(true);
  }, [isWallboard, trackEvent]);

  const createAndAddDashboardWidget = useCallback(
    async (gadget: DashboardGadget, card: CanvasCard.Card) => {
      await createDashboardGadget(gadget, selectedAccountId);
      const newDashboard = {
        ...dashboard,
        updatedOn: DateTime.utc().toISO(),
        updatedBy: currentUser.id,
        canvas: {
          ...dashboard.canvas,
          cards: [...dashboard.canvas.cards, card],
        },
      };

      await updateDashboard({
        id: dashboard.id,
        newDashboard,
        accountRef,
      });
      setCurrentCanvas((c) => ({
        ...c,
        cards: [...c.cards, card],
      }));
      addOrUpdateDashboard(newDashboard);

      return;
    },
    [
      accountRef,
      addOrUpdateDashboard,
      currentUser.id,
      dashboard,
      selectedAccountId,
    ],
  );

  const onEditCancel = useCallback(() => {
    trackEvent('Dashboard - Edit - Cancelled', {
      isWallboardSlide: isWallboard ? 'true' : 'false',
    });
    setIsEditing(false);
    setPreviewCanvasMode(undefined);
    setCurrentCanvas(getCanvas());
    if (hasEditingSearchQuery()) {
      navigate(location.pathname);
    }
  }, [trackEvent, isWallboard, getCanvas, navigate, location.pathname]);

  const saveDashboardAs = useCallback(
    async (name: string) => {
      setIsSavingAs(true);
      const { newDashboard } = await createDashboardCopy({
        name,
        dashboard,
        templatedFrom,
        access: dashboard.access,
      });
      setIsSavingAs(false);
      navigate(buildDashboardShow(newDashboard.id));
    },
    [createDashboardCopy, dashboard, navigate, templatedFrom],
  );

  const createTemplateFrom = useCallback(async () => {
    setIsSavingAs(true);
    const { newDashboard } = await createDashboardCopy({
      name: dashboard.name,
      dashboard,
      templatedFrom: undefined,
      isCreatingTemplate: true,
      access: dashboard.access,
    });

    setIsSavingAs(false);
    navigate(buildDashboardShow(newDashboard.id));
  }, [createDashboardCopy, dashboard, navigate]);

  const onDelete = useCallback(() => {
    deleteDashboard(dashboard.id, accountRef).then(() => {
      if (onDeleted) {
        onDeleted();
      } else {
        navigate(appRoutes.loggedIn.dashboards);
        alert('Dashboard deleted!');
      }
    });
  }, [accountRef, dashboard.id, navigate, onDeleted]);

  if (!currentUser) {
    return null;
  }

  if (!dashboard) {
    return <Loading />;
  }

  if (isBrokenTemplated) {
    return <BrokenDashboardTemplate />;
  }

  if (popupReportId) {
    return null;
  }

  return (
    <DashboardContext.Provider
      value={{
        dashboard,
        setIsEditingCard,
        setIsPopupGridOpen,
        templatedFrom,
      }}
    >
      <GqlClientProvider>
        <PopupProvider>
          <VariableFiltersContext.Provider
            value={{
              variableFilters,
              setVariableFilters,
            }}
          >
            <CopyGadgetProvider
              currentDashboard={{
                id: dashboard.id,
                setCurrentCanvas,
                setIsEditing,
              }}
            >
              <BoardsProvider>
                <VariableRuntimeFilters dashboard={dashboard} />
                <Dashboard
                  key={key}
                  currentCanvas={currentCanvas}
                  setCurrentCanvas={setCurrentCanvas}
                  isEditing={isEditing}
                  onEditSave={onEditSave}
                  onEditCancel={onEditCancel}
                  saveDashboardAs={saveDashboardAs}
                  createTemplateFrom={createTemplateFrom}
                  saveEditAs={saveEditAs}
                  startEditing={startEditing}
                  onDelete={onDelete}
                  createAndAddDashboardWidget={createAndAddDashboardWidget}
                  canvasMode={canvasMode}
                  previewCanvasMode={previewCanvasMode}
                  isSavingAs={isSavingAs}
                  isWallboard={!!wallboard}
                  isMobile={isMobile}
                  dashboardId={dashboard.id}
                  dashboard={dashboard}
                  isVariableFiltersSlideOutOpen={isVariableFiltersSlideOutOpen}
                  openVariableFiltersSlideOut={openVariableFiltersSlideOut}
                  closeVariableFiltersSlideOut={closeVariableFiltersSlideOut}
                  isTargetsApp={!!targetsAppContext}
                  isEntityApp={!!entityDetailsContext}
                />
              </BoardsProvider>
            </CopyGadgetProvider>
          </VariableFiltersContext.Provider>
        </PopupProvider>
      </GqlClientProvider>
    </DashboardContext.Provider>
  );
};

const Gate = ({
  dashboard,
  onDeleted,
}: {
  dashboard: PersistedDashboardType;
  onDeleted?: () => void;
}) => {
  const { isBrokenTemplated, templatedFrom } = useTemplatedForm(dashboard);
  if (dashboard.templateId && !templatedFrom && !isBrokenTemplated) {
    return <Loading />;
  }
  return (
    <LocalTimelineProvider sources={window.emptyArray}>
      <DashboardContainer
        dashboard={dashboard}
        templatedFrom={templatedFrom}
        isBrokenTemplated={isBrokenTemplated}
        onDeleted={onDeleted}
      />
    </LocalTimelineProvider>
  );
};

export default Gate;
