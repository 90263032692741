import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import DashboardsContext from '../../../../contexts/DashboardsContext';
import usePopup from '../../../../hooks/usePopup';
import Typography from 'kingpin/atoms/Typography';
import { DropdownButton, List, ListItem } from '../../../Inputs/Dropdown';
import Search from '../../../Search';
import toSentenceCase from '../../../../services/toSentenceCase';

const DashboardPicker = ({
  dashboard,
  onDashboardSelected,
}: {
  dashboard?: PersistedDashboardType;
  onDashboardSelected: (d: PersistedDashboardType) => void;
}) => {
  const { allDashboards } = useContext(DashboardsContext);
  const { isOpen, open, close } = usePopup();
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );
  const baseDashboards = allDashboards.filter(
    (d) => !d.isWallboardSlide && !d.isTemplate && !d.isEntityDetailsTab,
  );
  const filteredDashboards =
    searchText === ''
      ? baseDashboards
      : baseDashboards.filter((d) =>
          d.name.toLowerCase().includes(searchText.toLowerCase()),
        );

  return (
    <div>
      <DropdownButton
        isOpen={isOpen}
        open={open}
        close={close}
        placeholder={dashboard ? undefined : 'Select dashboard'}
        selectedLabel={dashboard ? dashboard.name : undefined}
      />
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={800}
          >
            <div style={{ padding: '16px' }}>
              <Typography.Header type="H5">
                Select a Dashboard
              </Typography.Header>
              <Search
                searchText={searchText}
                onSearchTextChanged={onSearchTextChanged}
              />
              <List style={{ maxHeight: 400, overflowY: 'scroll' }}>
                {filteredDashboards.map((d) => (
                  <ListItem
                    key={d.id}
                    onClick={() => {
                      onDashboardSelected(d);
                      close();
                    }}
                  >
                    {toSentenceCase(d.name)}
                  </ListItem>
                ))}
                {filteredDashboards.length === 0 && (
                  <Typography.Body type="Body 12">
                    No items found
                  </Typography.Body>
                )}
              </List>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default DashboardPicker;
