import { useEffect, useState } from 'react';
import withoutNulls from '../../api/search/withoutNulls';

const useDashboardZoomOptions = (
  wallBoard: Wallboard,
  saveWallboard: (newWb: Wallboard) => void,
) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setOptions([
      {
        label: 'Unset',
        isSelected: wallBoard.dashboardZoom === undefined,
        onSelected: () => {
          saveWallboard(
            withoutNulls({
              ...wallBoard,
              dashboardZoom: undefined,
            }),
          );
        },
      },
      ...[1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3].map((zoom) => ({
        label: zoom.toString(10),
        isSelected: wallBoard.dashboardZoom === zoom,
        onSelected: () => {
          saveWallboard({
            ...wallBoard,
            dashboardZoom: zoom,
          });
        },
      })),
    ]);
  }, [saveWallboard, wallBoard]);

  return options;
};

export default useDashboardZoomOptions;
