import TemplateNotFoundError from '../../errors/TemplateNotFoundError';

const buildTemplateNotFoundError = ({
  templatedDashboardId,
  dashboardTemplateId,
}: {
  templatedDashboardId: string;
  dashboardTemplateId: string;
}) => {
  const error = new TemplateNotFoundError(
    `Dashboard: ${templatedDashboardId} could not find the template: ${dashboardTemplateId}`,
  );
  return error;
};

export default buildTemplateNotFoundError;
