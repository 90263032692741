import React from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import Card from '../../Common/Card';
import Row from '../../Common/Row';
import ReportDrillDownContainer from '../../ReportDrillDown';
import ConfirmationModal from '../../ConfirmationModal';
import Tooltip from '../../Tooltip';
import ReportDrillDownsProvider from '../../../contextProviders/ReportDrillDownsProvider';
import Colors2 from '../../../theme/Colors2';

const InstancesPill = styled.div`
  padding: 4px 8px;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  background-color: #f8f8f8;
`;

const FieldPlate = styled.div`
  opacity: 0.5;
  border-radius: 4px;
  background-color: #e9e9e9;
  height: 34px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
`;

const Wrapper = styled.div`
  .showOnMouseOver {
    width: 0px;
    visibility: hidden;
  }

  &:hover {
    .showOnMouseOver {
      width: unset;
      visibility: visible;
    }
  }
`;

export const toPlate = (vf: VariableDrillDownType): FilterPlate => {
  return {
    id: vf.id,
    type: 'Variable',
    variableId: vf.id,
  };
};

const boxShadowStyle =
  '0 0 0 1px rgba(53,45,67,0.07), 0 1px 3px 0 rgba(53,45,67,0.12), 0 4px 8px 2px rgba(53,45,67,0.07)';

const VariableFilterCard = ({
  fieldName,
  instancesCount,
  templateInstancesCount,
  onDeleteClicked,
  onDeleteConfirmed,
  isDeleteConfirmOpen,
  closeDeleteConfirm,
  matchesFixedCount,
  onReplaceAllClicked,
  variableFilter,
  onUpdated,
  openEditTemplatedValues,
}: {
  fieldName: string;
  instancesCount: number;
  templateInstancesCount: number;
  onDeleteClicked: () => void;
  onDeleteConfirmed: () => void;
  isDeleteConfirmOpen: boolean;
  closeDeleteConfirm: () => void;
  matchesFixedCount: number;
  onReplaceAllClicked: () => void;
  variableFilter: VariableDrillDownType;
  onUpdated: (newDrillDown: ReportDrillDownType) => void;
  openEditTemplatedValues: () => void;
}) => (
  <React.Fragment>
    <Wrapper>
      <Card
        style={{
          marginBottom: 22,
          padding: '12px 16px',
          boxShadow: boxShadowStyle,
        }}
      >
        <Row spaceBetween centerAlign style={{ marginBottom: 20 }}>
          <Row centerAlign>
            {instancesCount > 0 && (
              <InstancesPill>
                <Typography.Body type={'Body 12'} color={Colors2.Grey['5']}>
                  {`${instancesCount} instance${instancesCount > 1 ? 's' : ''}`}
                </Typography.Body>
              </InstancesPill>
            )}
          </Row>
          <div className="showOnMouseOver">
            <Row centerAlign>
              <div style={{ marginRight: 8 }}>
                <Tooltip
                  content={
                    'Edit values for dashboards created from this template'
                  }
                >
                  <Button
                    icon="edit-filled"
                    size="Small"
                    type="Secondary"
                    onClick={openEditTemplatedValues}
                  />
                </Tooltip>
              </div>
              <Button
                onClick={onDeleteClicked}
                size="Small"
                type="Secondary"
                icon="cross"
              />
            </Row>
          </div>
        </Row>
        <Row style={{ marginBottom: 16 }} centerAlign>
          <div style={{ marginRight: 4 }}>
            <Typography.Body type="Label">Field:</Typography.Body>
          </div>
          <FieldPlate>
            <Typography.Body type="Body 12">{fieldName}</Typography.Body>
          </FieldPlate>
        </Row>
        <Row style={{ marginBottom: 16 }} centerAlign>
          <div style={{ marginRight: 4 }}>
            <Typography.Body type="Label">Value:</Typography.Body>
          </div>
          <ReportDrillDownsProvider dataType={variableFilter.value.dataset}>
            <ReportDrillDownContainer
              drillDown={toPlate(variableFilter)}
              isScope={false}
              isInVariableFilterList
              onUpdated={onUpdated}
            />
          </ReportDrillDownsProvider>
        </Row>
        {matchesFixedCount > 0 && (
          <Row centerAlign spaceBetween>
            <Typography.Body type={'Body 12'} color={Colors2.Grey['5']}>
              {`This plate matched ${matchesFixedCount} other filters`}
            </Typography.Body>
            <Button
              size={'Small'}
              type="Secondary"
              onClick={onReplaceAllClicked}
              label="Replace all"
              icon="refresh"
            />
          </Row>
        )}
      </Card>
    </Wrapper>
    <ConfirmationModal
      isOpen={isDeleteConfirmOpen}
      close={closeDeleteConfirm}
      title="Delete Variable Filter?"
      body={`${templateInstancesCount} dashboard${
        templateInstancesCount !== 1 ? 's' : ''
      } will be affected. Are you sure you want to make this change?`}
      confirmText={'Delete'}
      onConfirmed={onDeleteConfirmed}
    />
  </React.Fragment>
);

export default VariableFilterCard;
