import React from 'react';
import VariableFilterInput from '../../VariableFilterInput';
import Row from '../../Common/Row';
import Button from '../../../kingpin/atoms/Button';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

const ConfigureVariables = ({
  variableFilters,
  setVariableFilters,
  isLoading,
  onSave,
  close,
  canvas,
}: {
  variableFilters: VariableDrillDownType[];
  setVariableFilters: React.Dispatch<
    React.SetStateAction<VariableDrillDownType[]>
  >;
  isLoading: boolean;
  onSave: () => void;
  close: () => void;
  canvas: Canvas;
}) => (
  <Form>
    <FormHeader title="Configure Variable" onClose={close} />
    <FormContent>
      {variableFilters.map((vf) => (
        <VariableFilterInput
          key={vf.id}
          variableFilter={vf}
          setVariableFilters={setVariableFilters}
          canvas={canvas}
        />
      ))}
    </FormContent>
    <Row centerAlign spaceBetween>
      <Button onClick={close} type="Ghost" size="Small" label="Cancel" />
      <Button
        type="Primary"
        size="Small"
        label="Update"
        onClick={onSave}
        isDisabled={isLoading}
        isLoading={isLoading}
      />
    </Row>
  </Form>
);

export default ConfigureVariables;
