import React, { useCallback, useContext, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import ConfigureDashboardGadgetContext from '../contexts/ConfigureDashboardGadgetContext';
import ConfigureDashboardGadget from '../components/ConfigureDashboardGadget';
import updateDashboardGadget from '../api/updateDashboardGadget';
import AnalyticsContext from '../contexts/AnalyticsContext';
import isV5ChartDef from '../types/visTypeCheckers/isV5ChartDef';
import DashboardContext from '../contexts/DashboardContext';
import WallboardContext from '../contexts/WallboardContext';
import AccountPickerContext from '../contexts/AccountPickerContext';

const ConfigureDashboardGadgetProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { isWallboard } = useContext(WallboardContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dashboardGadget, setDashboardGadget] = useState<
    DashboardGadget | undefined
  >();
  const { dashboard } = useContext(DashboardContext);

  const open = useCallback(
    (gadget: DashboardGadget) => {
      trackEvent('Dashboard Card - Opened Edit Screen', {
        isWallboardSlide: isWallboard ? 'true' : 'false',
      });
      setDashboardGadget(gadget);
      setIsOpen(true);
    },
    [isWallboard, trackEvent],
  );

  const close = useCallback(() => {
    trackEvent('Dashboard Card - Edit - Cancelled', {
      isWallboardSlide: isWallboard ? 'true' : 'false',
    });
    setIsOpen(false);
  }, [isWallboard, trackEvent]);

  if (!dashboard) {
    return <>{children}</>;
  }

  const onSave = (g: DashboardGadget) => {
    trackEvent('Dashboard Card - Edit - Saved', {
      type: isV5ChartDef(g.chartDef) ? g.chartDef.gadgetType : '',
      dashboardId: dashboard.id,
      dashboardName: dashboard.name,
      isWallboardSlide: isWallboard ? 'true' : 'false',
    });
    updateDashboardGadget(g.id, g, selectedAccountId).then(() =>
      setIsOpen(false),
    );
  };

  return (
    <ConfigureDashboardGadgetContext.Provider
      value={{
        isOpen,
        open,
        close,
      }}
    >
      {children}
      <ModalTransition>
        {isOpen && !!dashboardGadget && (
          <Modal
            width={'95vw'}
            height={'80vh'}
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <WallboardContext.Provider
              value={{
                isWallboard: false,
                dashboardZoom: 1,
              }}
            >
              <ConfigureDashboardGadget
                key={dashboardGadget.id}
                editingGadget={dashboardGadget}
                onEditSave={onSave}
                close={close}
              />
            </WallboardContext.Provider>
          </Modal>
        )}
      </ModalTransition>
    </ConfigureDashboardGadgetContext.Provider>
  );
};

export default ConfigureDashboardGadgetProvider;
