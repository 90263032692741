import React, { useContext, useEffect, useState } from 'react';

import AccountProvider from '../contextProviders/AccountProvider';
import VersionProvider from '../contextProviders/VersionProvider';
import DatasetDefinitionsProvider from '../contextProviders/DatasetDefinitionsProvider';
import CurrentUserProvider from '../contextProviders/CurrentUserProvider';
import FeatureGatesProvider from '../contextProviders/FeatureGatesProvider';
import WorkSpacesProvider from '../contextProviders/WorkSpacesProvider';
import LastViewedAtProvider from '../contextProviders/LastViewedAtProvider';
import BoardsProvider from '../contextProviders/BoardsProvider';
import DriverBonusConfigProvider from '../contextProviders/DriverBonusConfigProvider';
import ReportsProvider from '../contextProviders/ReportsProvider';
import BaseViewsProvider from '../contextProviders/BaseViewsProvider';
import ChartDefinitionsProvider from '../contextProviders/ChartDefinitionsProvider';
import DashboardGadgetsProvider from '../contextProviders/DashboardGadgetsProvider';
import BonusPeriodsProvider from '../contextProviders/BonusPeriodsProvider';
import NavSidebarProvider from '../contextProviders/NavSideBarProvider';
import GoalsProvider from '../contextProviders/GoalsProvider';
import FavouritesProvider from '../contextProviders/FavouritesProvider';
import RolesProvider from '../contextProviders/RolesProvider';
import FeatureGatesContext from '../contexts/FeatureGatesContext';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import CompoundMetricsContext from '../contexts/CompoundMetricsContext';
import SpecialMetricsContext from '../contexts/SpecialMetricsContext';
import AccountContext from '../contexts/AccountContext';
import FlexCentered from '../components/Common/FlexCentered';
import Loading from '../components/Loading';
import BaseViewsContext from '../contexts/BaseViewsContext';
import ChartDefinitionsContext from '../contexts/ChartDefinitionsContext';
import MetricOptionsContext from '../contexts/MetricOptionsContext';
import IntercomSetup from '../components/Common/IntercomSetup';
import SavedFiltersProvider from '../contextProviders/SavedFiltersProvider';
import SavedFiltersContext from '../contexts/SavedFiltersContext';
import NotificationsProvider from '../contextProviders/NotificationsProvider';
import WallboardsProvider from '../contextProviders/WallboardsProvider';
import AccountPickerProvider from '../contextProviders/AccountPickerProvider';
import GqlClientProvider from '../contextProviders/GqlClientProvider';
import AnalyticsProvider from '../contextProviders/AnalyticsProvider';
import ScorecardsProvider from '../contextProviders/ScorecardsProvider';
import GlobalTasksProvider from '../contextProviders/TasksProvider/GlobalTasksProvider';
import GlobalTimelineProvider from '../contextProviders/TimelineProvider/GlobalTimelineProvider';
import DashboardsProvider from '../contextProviders/DashboardsProvider';
import DashboardMetricUsageLookupProvider from '../contextProviders/DashboardMetricUsageLookupProvider';
import MetricUsageLookupProvider from 'contextProviders/MetricUsageLookupProvider';
import ImpersonatorProvider from 'contextProviders/ImpersonatorProvider';
import ToastProvider from '../contextProviders/ToastProvider';
import UsersProvider from '../contextProviders/UsersProvider';
import CloudFunctionClientProvider from '../contextProviders/CloudFunctionClientProvider';
import EntityDefinitionsProvider from '../contextProviders/EntityDefinitionsProvider';
import RowHeightProvider from '../contextProviders/RowHeightProvider';
import PortalsProvider from '../contextProviders/PortalsProvider';

const useIsLoadingCriticalData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isLoading: isLoadingAccount } = useContext(AccountContext);
  const { isLoading: isLoadingGates } = useContext(FeatureGatesContext);
  const { isLoading: isLoadingSavedFilters } = useContext(SavedFiltersContext);

  const { isLoading: isLoadingDatasets } = useContext(
    DatasetDefinitionsContext,
  );
  const { isLoading: isLoadingBaseViews } = useContext(BaseViewsContext);
  const { isLoading: isLoadingMetrics } = useContext(MetricOptionsContext);
  const { isLoading: isLoadingCompoundMetrics } = useContext(
    CompoundMetricsContext,
  );
  const { isLoading: isLoadingSpecialMetrics } = useContext(
    SpecialMetricsContext,
  );
  const { isLoading: isLoadingChartDefs } = useContext(ChartDefinitionsContext);

  useEffect(() => {
    setIsLoading(
      isLoadingGates ||
        isLoadingDatasets ||
        isLoadingMetrics ||
        isLoadingCompoundMetrics ||
        isLoadingSpecialMetrics ||
        isLoadingAccount ||
        isLoadingBaseViews ||
        isLoadingChartDefs ||
        isLoadingSavedFilters,
    );
  }, [
    isLoadingAccount,
    isLoadingBaseViews,
    isLoadingChartDefs,
    isLoadingCompoundMetrics,
    isLoadingDatasets,
    isLoadingGates,
    isLoadingMetrics,
    isLoadingSpecialMetrics,
    isLoadingSavedFilters,
  ]);

  return isLoading;
};

export const SplashScreenLoadingGate = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const isLoading = useIsLoadingCriticalData();
  if (isLoading) {
    return (
      <FlexCentered style={{ height: '100vh' }} data-testid="splash-screen">
        <Loading isBlocking isGrouped />
      </FlexCentered>
    );
  } else {
    return <>{children}</>;
  }
};

const SplashScreen = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <VersionProvider>
      <ToastProvider>
        <AccountPickerProvider>
          <AccountProvider>
            <CloudFunctionClientProvider>
              <UsersProvider>
                <ImpersonatorProvider>
                  <CurrentUserProvider>
                    <FeatureGatesProvider>
                      <RolesProvider>
                        <PortalsProvider>
                          <EntityDefinitionsProvider>
                            <AnalyticsProvider>
                              <GqlClientProvider>
                                <GlobalTasksProvider>
                                  <GlobalTimelineProvider>
                                    <SavedFiltersProvider>
                                      <DatasetDefinitionsProvider>
                                        <WallboardsProvider>
                                          <DashboardsProvider>
                                            <WorkSpacesProvider>
                                              <LastViewedAtProvider>
                                                <BoardsProvider>
                                                  <DriverBonusConfigProvider>
                                                    <ScorecardsProvider>
                                                      <ReportsProvider>
                                                        <BaseViewsProvider>
                                                          <ChartDefinitionsProvider>
                                                            <GoalsProvider>
                                                              <DashboardGadgetsProvider>
                                                                <BonusPeriodsProvider>
                                                                  <NotificationsProvider>
                                                                    <NavSidebarProvider>
                                                                      <FavouritesProvider>
                                                                        <DashboardMetricUsageLookupProvider>
                                                                          <MetricUsageLookupProvider>
                                                                            <RowHeightProvider>
                                                                              <SplashScreenLoadingGate>
                                                                                {
                                                                                  children
                                                                                }
                                                                              </SplashScreenLoadingGate>
                                                                            </RowHeightProvider>
                                                                          </MetricUsageLookupProvider>
                                                                        </DashboardMetricUsageLookupProvider>
                                                                      </FavouritesProvider>
                                                                    </NavSidebarProvider>
                                                                  </NotificationsProvider>
                                                                </BonusPeriodsProvider>
                                                              </DashboardGadgetsProvider>
                                                            </GoalsProvider>
                                                          </ChartDefinitionsProvider>
                                                        </BaseViewsProvider>
                                                      </ReportsProvider>
                                                    </ScorecardsProvider>
                                                  </DriverBonusConfigProvider>
                                                </BoardsProvider>
                                              </LastViewedAtProvider>
                                            </WorkSpacesProvider>
                                            <IntercomSetup />
                                          </DashboardsProvider>
                                        </WallboardsProvider>
                                      </DatasetDefinitionsProvider>
                                    </SavedFiltersProvider>
                                  </GlobalTimelineProvider>
                                </GlobalTasksProvider>
                              </GqlClientProvider>
                            </AnalyticsProvider>
                          </EntityDefinitionsProvider>
                        </PortalsProvider>
                      </RolesProvider>
                    </FeatureGatesProvider>
                  </CurrentUserProvider>
                </ImpersonatorProvider>
              </UsersProvider>
            </CloudFunctionClientProvider>
          </AccountProvider>
        </AccountPickerProvider>
      </ToastProvider>
    </VersionProvider>
  );
};

export default SplashScreen;
