import { useEffect, useState } from 'react';
import { WEATHER_BOARD_ID } from './index';
import withoutNulls from '../../api/search/withoutNulls';

const useWeatherMapZoomOptions = (
  wallBoard: Wallboard,
  saveWallboard: (newWb: Wallboard) => void,
) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (!wallBoard.wallBoardDashboardIds.includes(WEATHER_BOARD_ID)) {
      setOptions([]);
      return;
    }

    setOptions([
      {
        label: 'Unset',
        isSelected: wallBoard.weatherMapZoom === undefined,
        onSelected: () => {
          saveWallboard(
            withoutNulls({
              ...wallBoard,
              weatherMapZoom: undefined,
            }),
          );
        },
      },
      {
        label: '1',
        isSelected: wallBoard.weatherMapZoom === '4',
        onSelected: () => {
          saveWallboard({
            ...wallBoard,
            weatherMapZoom: '4',
          });
        },
      },
      {
        label: '2',
        isSelected: wallBoard.weatherMapZoom === '5',
        onSelected: () => {
          saveWallboard({
            ...wallBoard,
            weatherMapZoom: '5',
          });
        },
      },
      {
        label: '3',
        isSelected: wallBoard.weatherMapZoom === '6',
        onSelected: () => {
          saveWallboard({
            ...wallBoard,
            weatherMapZoom: '6',
          });
        },
      },
      {
        label: '4',
        isSelected: wallBoard.weatherMapZoom === '7',
        onSelected: () => {
          saveWallboard({
            ...wallBoard,
            weatherMapZoom: '7',
          });
        },
      },
    ]);
  }, [saveWallboard, wallBoard]);

  return options;
};

export default useWeatherMapZoomOptions;
