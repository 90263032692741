import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import appRoutes from './appRoutes';
const OnBoardingLanding = lazy(() => import('../screens/OnBoarding/Landing'));
const OnBoardingBilling = lazy(() => import('../screens/OnBoarding/Billing'));
const IntercomSetup = lazy(() => import('../components/Common/IntercomSetup'));

const RedirectToOnBoardingLanding = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(appRoutes.onBoarding.landing);
  }, [navigate]);

  return null;
};

const OnBoardingSwitch = () => (
  <>
    <Routes>
      <Route
        path={appRoutes.onBoarding.billing}
        element={<OnBoardingBilling />}
      />
      <Route
        path={appRoutes.onBoarding.landing}
        element={<OnBoardingLanding />}
      />
      <Route path="*" element={<RedirectToOnBoardingLanding />} />
    </Routes>
    <IntercomSetup />
  </>
);

export default OnBoardingSwitch;
