import { useCallback, useContext } from 'react';
import { PortalsContext } from '../../contextProviders/PortalsProvider';
import portalTypeCheckers from '../../types/portalTypeCheckers';
import STORE from '../../store';
import AccountPickerContext from '../../contexts/AccountPickerContext';

const useAddToCurrentExecutivePortal = () => {
  const { selectedPortal } = useContext(PortalsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const addToCurrentExecutivePortal = useCallback(
    async (newDashboard: PersistedDashboardType): Promise<void> => {
      if (!selectedPortal) {
        return;
      }

      if (!portalTypeCheckers.isExecutivePortal(selectedPortal)) {
        return;
      }

      const newPortal: ExecutivePortal = {
        ...selectedPortal,
        dashboardIds: [...selectedPortal.dashboardIds, newDashboard.id],
      };

      return STORE.contentDistributions
        .getExecutivePortalsRef({ accountId: selectedAccountId })
        .doc(newPortal.id)
        .set(newPortal);
    },
    [selectedAccountId, selectedPortal],
  );

  return addToCurrentExecutivePortal;
};

export default useAddToCurrentExecutivePortal;
