import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';

import Typography from 'kingpin/atoms/Typography';
import Row from '../../../Common/Row';
import DashboardPicker from './DashboardPicker';
import Inputs from '../../../Inputs';

const CopyDashboardForm = ({
  title,
  onTitleChanged,
  dashboard,
  setDashboard,
  onSaveClicked,
  isLoading,
}: {
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  dashboard?: PersistedDashboardType;
  setDashboard: React.Dispatch<
    React.SetStateAction<PersistedDashboardType | undefined>
  >;
  onSaveClicked: () => void;
  isLoading: boolean;
}) => (
  <div style={{ padding: '24px 9px' }}>
    <Typography.Header type="H5">Create Slide</Typography.Header>
    <div style={{ marginBottom: 16 }}>
      <Typography.Body type="Label">Title</Typography.Body>
      <Inputs.TextInput
        value={title}
        onChange={onTitleChanged}
        data-testid={'slide-title'}
      />
    </div>
    <div style={{ marginBottom: 32 }}>
      <Typography.Body type="Label">Dashboard to copy</Typography.Body>
      <DashboardPicker
        dashboard={dashboard}
        onDashboardSelected={setDashboard}
      />
    </div>
    <Row centerAlign style={{ justifyContent: 'flex-end' }}>
      <Button
        onClick={onSaveClicked}
        isDisabled={dashboard === undefined || title === ''}
        isLoading={isLoading}
        label="Save"
        type="Primary"
        size="Small"
      />
    </Row>
  </div>
);

export default CopyDashboardForm;
