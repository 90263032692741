import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../Inputs';
import Row from '../../Common/Row';

const BulkSalesCommissionAction = ({
  selectedItems,
  isOpen,
  open,
  close,
  statusOptions,
  isSaving,
  date,
  status,
  onDateChanged,
  onSave,
}: {
  selectedItems: number;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  statusOptions: DropdownOption[];
  isSaving: boolean;
  date: string;
  status: 'paid' | 'due' | undefined;
  onDateChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
}) => (
  <>
    <Button
      onClick={open}
      type="Primary"
      size="Small"
      label={`Update ${selectedItems} commissions`}
    />
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
          <div style={{ marginTop: 16, marginBottom: 16, padding: '0px 16px' }}>
            <Typography.Header type="H5">
              Update commission status
            </Typography.Header>
            <div style={{ marginBottom: 24 }}>
              <div style={{ marginBottom: 12 }}>
                <Typography.Body type="Label">Status</Typography.Body>
                <Inputs.Dropdown
                  options={statusOptions}
                  selectedLabel={status}
                  testId={'select-commission-status'}
                />
              </div>
              <div style={{ marginBottom: 12 }}>
                {status === 'paid' && (
                  <div>
                    <Typography.Body type="Label">Paid On</Typography.Body>
                    <Inputs.TextInput
                      type="date"
                      value={date}
                      onChange={onDateChanged}
                    />
                  </div>
                )}
              </div>
            </div>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                onClick={onSave}
                isLoading={isSaving}
                isDisabled={isSaving}
                label="Save"
                type="Primary"
                size="Small"
              />
            </Row>
          </div>
        </Modal>
      )}
    </ModalTransition>
  </>
);

export default BulkSalesCommissionAction;
