import React, { useContext, useEffect, useState } from 'react';
import BulkUpdateFilterValues from './BulkUpdateFilterValues';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { DateTime } from 'luxon';
import updateDashboard from '../../../api/updateDashboard';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Confirmation from '../../Confirmation';
import { UnsavedChangesConfirmation } from '../../ConfirmationModal';
import usePopup from '../../../hooks/usePopup';
import useTemplateInstances from '../../../hooks/dashboards/useTemplateInstances';
import AccountPickerContext from '../../../contexts/AccountPickerContext';

const buildInitialValues = (
  instances: PersistedDashboardType[],
  variableFilter: VariableDrillDownType,
): VariableFilterBulkInputData => {
  const data = {} as VariableFilterBulkInputData;
  instances.forEach((t) => {
    data[t.id] = {
      value: t.variableDrillDowns.find((d) => d.id === variableFilter.id),
      dashboard: t,
    };
  });

  return data;
};

export const BulkUpdateFilterValuesContainer = ({
  close,
  variableFilter,
  template,
  setHasUnsavedChanged,
}: {
  close: () => void;
  variableFilter: VariableDrillDownType;
  template: PersistedDashboardType;
  setHasUnsavedChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);
  const templateInstances = useTemplateInstances(template);
  const [values, setValues] = useState<VariableFilterBulkInputData>(() =>
    buildInitialValues(templateInstances, variableFilter),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setHasUnsavedChanged(false);
    };
  }, [setHasUnsavedChanged]);

  const onSave = () => {
    setIsLoading(true);
    const updateRequests = templateInstances.map((dashboard) => {
      const newVariableDrillDowns = (() => {
        const match = dashboard.variableDrillDowns.find(
          (d) => d.id === variableFilter.id,
        );
        const item = values[dashboard.id];
        const newValue = item ? item.value : undefined;
        if (!newValue) {
          return dashboard.variableDrillDowns;
        }

        if (match) {
          return dashboard.variableDrillDowns.map((d) => {
            if (d.id === variableFilter.id) {
              return newValue;
            } else {
              return d;
            }
          });
        } else {
          return [...dashboard.variableDrillDowns, newValue];
        }
      })();
      const newDashboard = {
        ...dashboard,
        variableDrillDowns: newVariableDrillDowns,
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      return updateDashboard({
        id: dashboard.id,
        newDashboard,
        accountRef,
      });
    });
    Promise.all(updateRequests).then(() => {
      setIsLoading(false);
      setIsSaved(true);
    });
  };

  if (isSaved) {
    return (
      <Confirmation text={'Variable Filer Values Updated'} close={close} />
    );
  }

  return (
    <BulkUpdateFilterValues
      values={values}
      setValues={setValues}
      isLoading={isLoading}
      onSave={onSave}
      variableFilter={variableFilter}
      setHasUnsavedChanged={() => setHasUnsavedChanged(true)}
      canvas={template.canvas}
    />
  );
};

const Gate = ({
  isOpen,
  close,
  variableFilter,
  template,
}: {
  isOpen: boolean;
  close: () => void;
  variableFilter: VariableDrillDownType;
  template: PersistedDashboardType;
}) => {
  const {
    isOpen: isConfirmationOpen,
    open: openConfirmation,
    close: closeConfirmation,
  } = usePopup();
  const [hasUnsavedChanges, setHasUnsavedChanged] = useState<boolean>(false);

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={hasUnsavedChanges ? openConfirmation : close}
            shouldScrollInViewport={false}
            autoFocus={false}
            width="60%"
          >
            <div style={{ padding: '0px 16px', overflowY: 'scroll' }}>
              <BulkUpdateFilterValuesContainer
                variableFilter={variableFilter}
                template={template}
                close={close}
                setHasUnsavedChanged={setHasUnsavedChanged}
              />
            </div>
          </Modal>
        )}
      </ModalTransition>
      <UnsavedChangesConfirmation
        isOpen={isConfirmationOpen}
        close={closeConfirmation}
        onConfirmed={() => {
          close();
          closeConfirmation();
        }}
        body={
          'If you navigate away, all changes made will be lost. Are you' +
          ' sure you want to cancel updating variable filter values?'
        }
      />
    </>
  );
};

export default Gate;
