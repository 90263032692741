import React from 'react';
import TextInput from '../../atoms/TextInput';

const PortalSearchInput = ({
  searchText,
  onSearchTextChanged,
}: {
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <TextInput
        value={searchText}
        onChange={onSearchTextChanged}
        icon="search"
        placeholder="Select A Portal"
        autoFocus
      />
    </div>
  );
};

export default PortalSearchInput;
