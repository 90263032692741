import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AppRoutes from '../../navigation/appRoutes';
import Icon from '../atoms/Icon';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import NAVIGATION from './constants';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import usePopup from '../../hooks/usePopup';
import Tooltip from '../../components/Tooltip';
import Colors2 from '../../theme/Colors2';
import { Z_INDEX } from '../../constants';
import useIsSimpleViewDemo from '../../hooks/useIsSimpleViewDemo';

const MainNavTopDiv = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.isCollapsed ? 'center' : 'space-between'};
  background-color: #fbfbfb;
  padding: 0px 12px;
  margin-bottom: 9px;
  height: ${NAVIGATION.TOP_HEIGHT}px;
  border-bottom: solid 1px ${Colors2.Border};
`;

const BrandingDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  svg {
    height: 21px !important;
  }
`;

const Collapse = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const FloatingExpandButton = styled.div`
  position: absolute;
  left: ${NAVIGATION.COLLAPSED_NAV_WIDTH - 10}px;
  top: 16px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX.TEXT_GADGET};
  &:hover {
    background-color: #f4f4f4;
  }
`;

const MainNavTop = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const navigate = useNavigate();
  const { isOpen, open, close } = useContext(NavSideBarContext);
  const isSimpleView = useIsSimpleViewDemo();

  const {
    isOpen: isCollapseVisible,
    open: showCollapse,
    close: hideCollapse,
  } = usePopup();
  const onClick = useCallback(() => {
    trackEvent('Navigation Sidebar - Clicked Home');
    navigate(AppRoutes.home);
  }, [navigate, trackEvent]);
  const onCollapseClicked = useCallback(() => {
    close();
    hideCollapse();
  }, [close, hideCollapse]);

  if (!isOpen) {
    return (
      <MainNavTopDiv
        isCollapsed={true}
        onMouseEnter={isSimpleView ? undefined : showCollapse}
        onMouseLeave={isSimpleView ? undefined : hideCollapse}
      >
        <BrandingDiv onClick={onClick}>
          <Icon icon={'branding-small'} />
        </BrandingDiv>
        {isCollapseVisible && (
          <FloatingExpandButton onClick={open}>
            <Tooltip content="Expand">
              <Icon icon={'chevron-right'} width={16} height={16} />
            </Tooltip>
          </FloatingExpandButton>
        )}
      </MainNavTopDiv>
    );
  } else {
    return (
      <MainNavTopDiv isCollapsed={false}>
        <BrandingDiv onClick={onClick}>
          <Icon icon={'branding-large'} width={100} />
        </BrandingDiv>
        <Tooltip content="Collapse">
          <Collapse onClick={onCollapseClicked}>
            <Icon
              icon={'left-panel-close'}
              color="#A5A5A5"
              width={24}
              height={24}
            />
          </Collapse>
        </Tooltip>
      </MainNavTopDiv>
    );
  }
};

export default MainNavTop;
