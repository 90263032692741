import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import Row from '../Common/Row';
import CloseButton from '../Common/CloseButton';
import VariableFilterCard from './VariableFilterCard';

const Title = styled.div`
  width: 275px;
`;

const List = styled.div`
  height: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  padding-top: 12px;
  padding-left: 4px;
`;

const VariableFiltersSlideOut = ({
  close,
  dashboardTemplate,
}: {
  close: () => void;
  dashboardTemplate: DashboardTemplate;
}) => (
  <div
    style={{
      width: '100%',
      height: '100%',
    }}
  >
    <Row centerAlign spaceBetween style={{ marginBottom: 24 }}>
      <Title>
        <Typography.Header type={'H5'}>Variable List</Typography.Header>
      </Title>
      <CloseButton onClick={close} />
    </Row>
    <List data-testid={'variable-slideout'}>
      {dashboardTemplate.variableDrillDowns.map((vf) => (
        <VariableFilterCard
          variableFilter={vf}
          key={vf.id}
          dashboardTemplate={dashboardTemplate}
        />
      ))}
    </List>
  </div>
);

export default VariableFiltersSlideOut;
