import { useCallback, useContext } from 'react';
import aguid from 'aguid';
import updateDashboardGadget from '../../../api/updateDashboardGadget';
import withoutNulls from '../../../api/search/withoutNulls';
import createSavedFilter from '../../../api/createSavedFilter';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import VariableFiltersContext from '../../../contexts/VariableFiltersContext';
import useUpdateVariableFilters from '../useUpdateVariableFilters';
import updateDashboard from '../../../api/updateDashboard';

const useOnDeleteConfirmed = ({
  dashboardTemplate,
  variableFilter,
  instances,
}: {
  dashboardTemplate: DashboardTemplate;
  variableFilter: VariableDrillDownType;
  instances: {
    gadgets: DashboardGadget[];
    savedFilters: SavedFilter[];
    dashboards: PersistedDashboardType[];
  };
}) => {
  const { selectedAccountId, accountRef } = useContext(AccountPickerContext);
  const { setVariableFilters } = useContext(VariableFiltersContext);
  const updateVariableFilters = useUpdateVariableFilters(dashboardTemplate);

  const onDeleteConfirmed = useCallback(() => {
    const newPlate = {
      id: aguid(),
      type: 'Fixed',
      fixedValue: variableFilter.value,
    } as FilterPlate;
    const gadgetRequests = instances.gadgets.map((gadget) => {
      const scopeWithoutReplacement = gadget.scope.filter((scopePlate) => {
        if (scopePlate.type === 'Variable') {
          return scopePlate.variableId !== variableFilter.id;
        } else {
          return true;
        }
      });
      const drillDownsWithoutReplacement = gadget.drillDowns.filter(
        (drillPlate) => {
          if (drillPlate.type === 'Variable') {
            return drillPlate.variableId !== variableFilter.id;
          } else {
            return true;
          }
        },
      );
      const shouldAddNewPlate =
        scopeWithoutReplacement.length !== gadget.scope.length ||
        drillDownsWithoutReplacement.length !== gadget.drillDowns.length;
      const newDrillDowns = shouldAddNewPlate
        ? [...drillDownsWithoutReplacement, newPlate]
        : drillDownsWithoutReplacement;
      const newGadget = {
        ...gadget,
        scope: scopeWithoutReplacement,
        drillDowns: newDrillDowns,
      };

      return updateDashboardGadget(
        gadget.id,
        withoutNulls(newGadget),
        selectedAccountId,
      );
    });

    const savedFilterRequests = instances.savedFilters.map((savedFilter) => {
      const scopeWithoutReplacement = savedFilter.scope.filter((scopePlate) => {
        if (scopePlate.type === 'Variable') {
          return scopePlate.variableId !== variableFilter.id;
        } else {
          return false;
        }
      });
      const drillDownsWithoutReplacement = savedFilter.drillDowns.filter(
        (drillPlate) => {
          if (drillPlate.type === 'Variable') {
            return drillPlate.variableId !== variableFilter.id;
          } else {
            return true;
          }
        },
      );
      const shouldAddNewPlate =
        scopeWithoutReplacement.length !== savedFilter.scope.length ||
        drillDownsWithoutReplacement.length !== savedFilter.drillDowns.length;

      const newDrillDowns = shouldAddNewPlate
        ? [...drillDownsWithoutReplacement, newPlate]
        : drillDownsWithoutReplacement;
      const newSavedFilter = {
        ...savedFilter,
        scope: scopeWithoutReplacement,
        drillDowns: newDrillDowns,
      };

      return createSavedFilter(withoutNulls(newSavedFilter), accountRef);
    });

    const dashboardRequests = instances.dashboards.map((dashboard) => {
      const newDashboard = {
        ...dashboard,
        variableDrillDowns: dashboard.variableDrillDowns.filter(
          (vd) => vd.id !== variableFilter.id,
        ),
      };

      return updateDashboard({
        id: newDashboard.id,
        newDashboard: newDashboard,
        accountRef,
      });
    });

    Promise.all([
      ...gadgetRequests,
      ...savedFilterRequests,
      ...dashboardRequests,
    ]).then(() => {});
    const newVariableFilters = dashboardTemplate.variableDrillDowns.filter(
      (vf) => vf.id !== variableFilter.id,
    );

    setVariableFilters(newVariableFilters);
    if (updateVariableFilters) {
      updateVariableFilters(newVariableFilters);
    }
  }, [
    accountRef,
    dashboardTemplate.variableDrillDowns,
    instances,
    selectedAccountId,
    setVariableFilters,
    updateVariableFilters,
    variableFilter.id,
    variableFilter.value,
  ]);

  return onDeleteConfirmed;
};

export default useOnDeleteConfirmed;
