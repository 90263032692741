// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

import Card from '../Common/Card';
import FlexCentered from '../Common/FlexCentered';

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
`;

const Outer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100vh - 32px);
`;

const WeatherBoard = ({ zoom }: { zoom: '4' | '5' | '6' | '7' }) => (
  <Outer>
    <FlexCentered style={{ padding: 16, height: '100%', width: '100%' }}>
      <Card style={{ width: '100%', height: '100%', padding: 8 }}>
        <IFrame
          src={`https://www.rainviewer.com/map.html?loc=37.8228,-96.8335,${zoom}&oFa=0&oC=0&oU=0&oCS=1&oF=0&oAP=1&c=3&o=83&lm=1&layer=radar&sm=1&sn=1&hu=false`}
          width="100%"
          frameBorder="0"
          allowFullScreen
        ></IFrame>
      </Card>
    </FlexCentered>
  </Outer>
);

export default WeatherBoard;
