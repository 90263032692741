import React, { useContext, useState } from 'react';
import CardContext from '../../../contexts/CardContext';
import GridProvider from '../../../contextProviders/GridProvider';
import Grid from '../../Grid';
import { ColumnApi, GridApi } from 'ag-grid-community';
import useBoardBaseView from '../../../hooks/useBoardBaseView';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import useWindowSize from '../../../hooks/useWindowSize';
import ReactPortal from '../../ReactPortal';
import BulkSalesCommissionAction from '../BulkSalesCommissionAction';
import { SALES_COMMISSION_ACTIONS_ELEMENT_ID } from '../constants';
import isPerformanceBoard from '../../../isPerformanceBoard';

const GridContainer = ({
  baseView,
  sortOverride,
  hideTotalsRow,
  gridApi,
  setGridApi,
  columnApiRef,
  isSizedToFitOverridden,
}: {
  baseView: FleetOps.BaseView;
  sortOverride?: SimpleGridSort[];
  hideTotalsRow: boolean;
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
  columnApiRef: React.MutableRefObject<ColumnApi | undefined>;
  isSizedToFitOverridden: boolean;
}) => {
  const { isMobile } = useWindowSize();
  const newBaseView = useBoardBaseView(baseView);
  const [isSalesCommission] = useState<boolean>(baseView.type === 'commission');

  return (
    <CardContext.Provider
      value={{
        i: '',
        w: 1,
        h: 1,
      }}
    >
      <GridProvider
        dataType={baseView.type}
        baseViewOverride={newBaseView}
        sortOverride={sortOverride}
        gridApi={gridApi}
        setGridApi={setGridApi}
        columnApiRef={columnApiRef}
      >
        <>
          {isSalesCommission && (
            <ReactPortal elementId={SALES_COMMISSION_ACTIONS_ELEMENT_ID}>
              <BulkSalesCommissionAction />
            </ReactPortal>
          )}
          <Grid
            isBoard
            hideTotalsRow={hideTotalsRow}
            layoutOnFirstRender={true}
            layoutOnColumnChange={false}
            layoutOnModelUpdated={false}
            autoSizeFirstColumnOnly={isSizedToFitOverridden && !isMobile}
            hasBorders={true}
            hasDarkHeader={true}
          />
        </>
      </GridProvider>
    </CardContext.Provider>
  );
};

const Gate = ({
  board,
  sortOverride,
  gridApi,
  setGridApi,
  columnApiRef,
}: {
  board: DriverPerformanceBoard | GeneralBoard | PerformanceBoardTypes.Board;
  sortOverride?: SimpleGridSort[];
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
  columnApiRef: React.MutableRefObject<ColumnApi | undefined>;
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const baseView = baseViews[board.dataType];

  if (baseView) {
    return (
      <GridContainer
        baseView={baseView}
        sortOverride={sortOverride}
        hideTotalsRow={
          isPerformanceBoard(board) ? false : !!board.hideTotalsRow
        }
        gridApi={gridApi}
        setGridApi={setGridApi}
        columnApiRef={columnApiRef}
        isSizedToFitOverridden={
          isPerformanceBoard(board) ? false : !!board.isSizedToFitOverridden
        }
      />
    );
  } else {
    return null;
  }
};

export default Gate;
