import { useCallback, useContext, useEffect, useState } from 'react';
import usePersistedSharedContent from './usePersistedSharedContent';
import DashboardsContext from '../../contexts/DashboardsContext';
import ReportsContext from '../../contexts/ReportsContext';
import WorkSpacesContext from '../../contexts/WorkSpacesContext';
import isDefined from '../../isDefined';
import { isWorkSpace } from '../../isWorkSpace';

const useSharedContent = () => {
  const { allDashboards } = useContext(DashboardsContext);
  const { allReports } = useContext(ReportsContext);
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const { persistedSharedContent, isLoadingPersistedSharedContent } =
    usePersistedSharedContent();

  const buildSharedContent = useCallback((): SharedContent => {
    return {
      dashboards: persistedSharedContent.dashboardIds
        .map((id) => allDashboards.find((d) => d.id === id))
        .filter(isDefined),
      reports: persistedSharedContent.reportIds
        .map((id) => allReports.find((r) => r.id === id))
        .filter(isDefined),
      workSpaces: persistedSharedContent.workspaceIds
        .map((id) => allWorkSpaces.filter(isWorkSpace).find((w) => w.id === id))
        .filter(isDefined),
    };
  }, [
    allDashboards,
    allReports,
    allWorkSpaces,
    persistedSharedContent.dashboardIds,
    persistedSharedContent.reportIds,
    persistedSharedContent.workspaceIds,
  ]);
  const [sharedContent, setSharedContent] = useState<SharedContent>(() =>
    buildSharedContent(),
  );

  useEffect(() => {
    setSharedContent(buildSharedContent());
  }, [buildSharedContent]);

  return { sharedContent, isLoading: isLoadingPersistedSharedContent };
};

export default useSharedContent;
