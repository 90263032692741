import React, { useCallback, useContext } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import usePopup from '../../../hooks/usePopup';
import AddSlideButton from './AddSlideButton';
import DashboardForm from '../../DashboardForm';
import CopyDashboardForm from './CopyDashboardForm';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const AddSlideButtonContainer = ({
  onAddWeatherBoard,
  setPreviewDashboard,
}: {
  onAddWeatherBoard: () => void;
  setPreviewDashboard: (d: PersistedDashboardType | WeatherBoardType) => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { isOpen, open, close } = usePopup();
  const { isOpen: isNewOpen, open: openNew, close: closeNew } = usePopup();
  const { isOpen: isCopyOpen, open: openCopy, close: closeCopy } = usePopup();

  const onNewDashboardClicked = useCallback(() => {
    openNew();
    close();
    trackEvent('Wallboard Config - Add Slide - New');
  }, [close, openNew, trackEvent]);
  const onFromDashboardClicked = useCallback(() => {
    openCopy();
    close();
    trackEvent('Wallboard Config - Add Slide - From Dashboard');
  }, [close, openCopy, trackEvent]);
  const onAddWallboardClicked = useCallback(() => {
    onAddWeatherBoard();
    close();
  }, [close, onAddWeatherBoard]);

  return (
    <>
      <AddSlideButton
        close={close}
        isOpen={isOpen}
        open={open}
        onAddWallboardClicked={onAddWallboardClicked}
        onNewDashboardClicked={onNewDashboardClicked}
        onFromDashboardClicked={onFromDashboardClicked}
      />
      <ModalTransition>
        {isNewOpen && (
          <Modal
            onClose={closeNew}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={800}
          >
            <DashboardForm
              isAlreadyInModal
              isOpen
              close={closeNew}
              setWallboardPreviewSlide={setPreviewDashboard}
            />
          </Modal>
        )}
      </ModalTransition>
      <ModalTransition>
        {isCopyOpen && (
          <Modal
            onClose={closeCopy}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={800}
          >
            <CopyDashboardForm
              close={closeCopy}
              setWallboardPreviewSlide={setPreviewDashboard}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default AddSlideButtonContainer;
