import styled from 'styled-components';

const Col = styled.div<{ width: number }>`
  display: flex;

  width: ${(props) => props.width}px;
  margin-right: 16px;

  svg {
    fill: white;
  }
`;

export default Col;
