import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Board from './Board';
import useQuickFilters from '../../hooks/useQuickFilters';
import toFilterInput from '../../toFilterInput';
import BoardContext, { toDateRange } from 'contexts/BoardContext';
import BonusPeriodsContext from '../../contexts/BonusPeriodsContext';
import usePopup from '../../hooks/usePopup';
import BoardSlideOutContext from 'contexts/BoardSlideOutContext';
import FlexCentered from '../Common/FlexCentered';
import Loading from '../Loading';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColumnApi, GridApi, ModelUpdatedEvent } from 'ag-grid-community';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import isDriverPerformanceBoard from '../../isDriverPerformanceBoard';
import useContentViewTracking from '../../hooks/useContentViewTracking';
import DateInputContext from '../../contexts/DateInputContext';
import withDateFilter from '../../hocs/withDateFIlter';
import useDateScope from '../../hooks/useDateScope';
import GqlClientProvider from '../../contextProviders/GqlClientProvider';
import BulkGridActionsProvider from '../../contextProviders/BulkGridActionsProvider';
import useBoardOpenedTracking from '../../hooks/useBoardTracking';
import ReportDrillDownsProvider from '../../contextProviders/ReportDrillDownsProvider';
import useReasonCodes from '../../hooks/useReasonCodes';
import { platesToFilterInput } from 'screens/GoalShow/useGoalData';
import useHideIntercom from '../../hooks/useHideIntercom';

const getVisType = (board: GeneralBoard | DriverPerformanceBoard) => {
  if (isDriverPerformanceBoard(board)) {
    return 'driver-performance-board';
  }
  return 'general-board';
};

const getParam = (
  search: string,
  drillDownField: string,
): string | undefined => {
  const urlParams = new URLSearchParams(search);
  if (urlParams.has(drillDownField)) {
    return urlParams.get(drillDownField) as string;
  }
  return undefined;
};

const useSortOverride = (board: DriverPerformanceBoard | GeneralBoard) => {
  const getSort = useCallback(() => {
    return [
      {
        field: isDriverPerformanceBoard(board)
          ? 'driver'
          : board.drillDownField,
        direction: 'asc' as SortBy,
      },
    ];
  }, [board]);
  const [sortOverride, setSortOverride] = useState<SimpleGridSort[]>(() =>
    getSort(),
  );

  useEffect(() => {
    setSortOverride(getSort());
  }, [getSort]);

  return sortOverride;
};

const BoardContainer = ({
  board,
  initialRunTimeFilters,
  initialScopeFilters,
}: {
  board: DriverPerformanceBoard | GeneralBoard;
  initialRunTimeFilters: FilterPlate[];
  initialScopeFilters: FilterPlate[];
}) => {
  useContentViewTracking({
    type: 'board',
    name: board.name,
    typeId: board.id,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { setDateRange, setDataTypes } = useContext(DateInputContext);
  const dateRange = useDateScope({});

  const currentUser = useContext(CurrentUserContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [gridApi, setGridApi] = useState<GridApi>();
  const columnApiRef = useRef<ColumnApi | undefined>(undefined);
  const quickFilters = useQuickFilters(
    `${board.dataType} - board: ${board.id}`,
  );
  const {
    isOpen: isSlideOutOpen,
    open: openSlideOut,
    close: closeSlideOut,
  } = usePopup();
  const [selectedFieldValue, setSelectedFieldValue] = useState<
    string | undefined
  >();
  const [variableDrillDowns] = useState<VariableDrillDownType[]>([]);
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(
    () => initialRunTimeFilters,
  );
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<
    SavedFilter[]
  >([]);
  const [netQuickFilters, setNetQuickFilters] = useState<FilterInput[]>([]);
  const reasonCodes = useReasonCodes(board.dataType);
  const [gridRequiresRefresh, setGridRequiresRefresh] =
    useState<boolean>(false);
  const [dataTypes] = useState<string[]>([board.dataType]);
  const sortOverride = useSortOverride(board);

  const driverUrlParam = getParam(
    window.location.search,
    isDriverPerformanceBoard(board) ? 'driver' : board.drillDownField,
  );

  useHideIntercom();
  useEffect(() => {
    if (driverUrlParam) {
      setSelectedFieldValue(driverUrlParam);
      openSlideOut();
    } else {
      closeSlideOut();
    }
  }, [closeSlideOut, driverUrlParam, openSlideOut]);

  useEffect(() => {
    setDataTypes([board.dataType]);
    setDateRange(
      selectedBonusPeriod ? toDateRange(selectedBonusPeriod) : undefined,
    );
  }, [board.dataType, selectedBonusPeriod, setDataTypes, setDateRange]);

  const onSlideOutClosed = useCallback(() => {
    const hasQueryParams = window.location.search.length > 0;
    if (hasQueryParams) {
      navigate(-1);
    }
    setTimeout(() => {
      if (columnApiRef.current) {
        columnApiRef.current.autoSizeAllColumns(true);
      }
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    }, 100);
  }, [gridApi, navigate]);

  const { onManualFilterChanged, onQuickFilterChanged } =
    useBoardOpenedTracking({ board, onSlideOutClosed });

  const updateHighlightedRows = useCallback(
    (event: ModelUpdatedEvent) => {
      event.api.forEachNode((node) => {
        if (!node.data) {
          return;
        }
        const f =
          node.data[
            isDriverPerformanceBoard(board) ? 'driver' : board.drillDownField
          ];
        if (f !== selectedFieldValue) {
          node.setSelected(false);
        } else {
          node.setSelected(true);
        }
      });
    },
    [board, selectedFieldValue],
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener('modelUpdated', updateHighlightedRows);
      return () => {
        if (gridApi.isDestroyed()) {
          return;
        }
        gridApi.removeEventListener('modelUpdated', updateHighlightedRows);
      };
    }
  }, [gridApi, updateHighlightedRows]);

  const selectFieldValue = useCallback(
    (newFieldValue: string) => {
      setSelectedFieldValue(newFieldValue);
      const newSearch = `${
        isDriverPerformanceBoard(board) ? 'driver' : board.drillDownField
      }=${newFieldValue}`;
      navigate({ search: newSearch }, { replace: location.search.length > 0 });

      openSlideOut();
      setTimeout(() => {
        if (columnApiRef.current) {
          columnApiRef.current.autoSizeAllColumns(true);
        }
        if (gridApi) {
          gridApi.sizeColumnsToFit();
        }
      }, 100);
      trackEvent('Board - Slide out opened', {
        boardName: board.name,
        boardId: board.id,
        boardDataType: board.dataType,
      });
    },
    [
      board,
      navigate,
      location.search.length,
      openSlideOut,
      trackEvent,
      gridApi,
    ],
  );

  useEffect(() => {
    setNetQuickFilters([
      ...selectedQuickFilters.map((qf) =>
        toFilterInput(qf, variableDrillDowns),
      ),
      ...(board.dataSourceFilter ? [board.dataSourceFilter] : []),
      ...(initialScopeFilters
        ? [platesToFilterInput(initialScopeFilters)]
        : []),
    ]);
  }, [
    board.dataSourceFilter,
    initialScopeFilters,
    selectedQuickFilters,
    variableDrillDowns,
  ]);

  if (!dateRange) {
    return (
      <FlexCentered>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <ReportDrillDownsProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      scope={initialScopeFilters}
      variableDrillDowns={variableDrillDowns}
      dataTypes={dataTypes}
      quickFilters={netQuickFilters}
    >
      <BoardSlideOutContext.Provider
        value={{
          sections: board.sidePanel,
          isOpen: isSlideOutOpen,
          close: onSlideOutClosed,
          selectedFieldValue,
          drillDownField: isDriverPerformanceBoard(board)
            ? 'driver'
            : board.drillDownField,
          selectFieldValue,
          gridRequiresRefresh,
          setGridRequiresRefresh,
        }}
      >
        <BoardContext.Provider
          value={{
            board,
            reasonCodes,
            quickFilters,
            selectedQuickFilters,
            setSelectedQuickFilters,
            isBoardOwner: isDriverPerformanceBoard(board)
              ? board.assignedToId.includes(currentUser.id)
              : false,
          }}
        >
          <BulkGridActionsProvider>
            <GqlClientProvider
              visId={board.id}
              visName={board.name}
              visType={getVisType(board)}
            >
              <Board
                board={board}
                sortOverride={sortOverride}
                onQuickFilterChanged={onQuickFilterChanged}
                onManualFilterChanged={onManualFilterChanged}
                gridApi={gridApi}
                setGridApi={setGridApi}
                columnApiRef={columnApiRef}
              />
            </GqlClientProvider>
          </BulkGridActionsProvider>
        </BoardContext.Provider>
      </BoardSlideOutContext.Provider>
    </ReportDrillDownsProvider>
  );
};

export default withDateFilter(BoardContainer, {});
