import React, { useContext } from 'react';
import WeatherBoard from './WeatherBoard';
import WallboardContext from '../../contexts/WallboardContext';
import useIsFireTv from '../../hooks/useIsFireTv';

const WeatherBoardContainer = () => {
  const isFireTv = useIsFireTv();
  const { weatherMapZoom } = useContext(WallboardContext);
  return (
    <WeatherBoard
      zoom={weatherMapZoom ? weatherMapZoom : isFireTv ? '5' : '4'}
    />
  );
};

export default WeatherBoardContainer;
