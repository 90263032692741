import React, { useContext } from 'react';
import usePopup from 'hooks/usePopup';
import InlineDialog from 'components/InlineDialog';
import { DropdownButton } from 'components/Inputs/Dropdown';

import PortalPickerDialog from './PortalPickerDialog';
import { PortalsContext } from '../../../contextProviders/PortalsProvider';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import CreatePortalForm from './CreatePortalForm';
import PERMISSIONS from '../../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';
import NavSideBarContext from '../../../contexts/NavSideBarContext';
import Button from 'kingpin/atoms/Button';

const PortalPicker = () => {
  const { isOpen: isNavOpen } = useContext(NavSideBarContext);
  const { selectedPortal } = useContext(PortalsContext);
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isPortalFormOpen,
    open: openPortalForm,
    close: closePortalForm,
  } = usePopup();

  return (
    <>
      <InlineDialog
        content={
          <PortalPickerDialog close={close} openPortalForm={openPortalForm} />
        }
        onClose={close}
        isOpen={isOpen}
      >
        <div style={{ marginTop: 8 }}>
          {isNavOpen && (
            <DropdownButton
              isOpen={isOpen}
              open={open}
              close={close}
              label={selectedPortal ? selectedPortal.name : 'Select a Portal'}
            />
          )}
          {!isNavOpen && (
            <Button
              size="Small"
              type="Secondary"
              onClick={isOpen ? close : open}
              icon={isOpen ? 'chevron-up' : 'chevron-down'}
            />
          )}
        </div>
      </InlineDialog>
      {/* Portal Creation Form */}
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.CONTENT_ACCESS.CREATE_PORTALS}
      >
        <ModalTransition>
          {isPortalFormOpen && (
            <Modal
              onClose={closePortalForm}
              shouldScrollInViewport={false}
              autoFocus={false}
            >
              <CreatePortalForm close={closePortalForm} />
            </Modal>
          )}
        </ModalTransition>
      </PermissionGates.Has>
    </>
  );
};

export default PortalPicker;
