const VIEW_ALL_PRIVATE: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_private';
const VIEW_ALL_USERS: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_users';
const VIEW_ALL_ADMIN_ONLY: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_admin_only';

const SET_ANY_AVAILABLE_TO_PRIVATE: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_private';
const SET_ANY_AVAILABLE_TO_ADMIN_ONLY: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_admin_only';
const SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_users_or_public';

const CREATE_PORTALS: FleetOps.Permission =
  'fleetops.permissions.content_access_create_portals';
const VIEW_ADMIN_PORTAL: FleetOps.Permission =
  'fleetops.permissions.content_access_view_admin_portal';
const VIEW_EXECUTIVE_PORTALS: FleetOps.Permission =
  'fleetops.permissions.content_access_view_executive_portals';
const MANAGE_ENGAGEMENT_PORTALS: FleetOps.Permission =
  'fleetops.permissions.content_access_manage_engagement_portals';

const CONTENT_ACCESS_PERMISSIONS = {
  VIEW_ALL_PRIVATE,
  VIEW_ALL_USERS,
  VIEW_ALL_ADMIN_ONLY,
  SET_ANY_AVAILABLE_TO_PRIVATE,
  SET_ANY_AVAILABLE_TO_ADMIN_ONLY,
  SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
  CREATE_PORTALS,
  VIEW_ADMIN_PORTAL,
  VIEW_EXECUTIVE_PORTALS,
  MANAGE_ENGAGEMENT_PORTALS,
};
Object.freeze(CONTENT_ACCESS_PERMISSIONS);

export default CONTENT_ACCESS_PERMISSIONS;
