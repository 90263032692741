import { isEqual } from 'lodash';

const isDrillDownEqual = (
  a: ReportDrillDownType,
  b: ReportDrillDownType,
): boolean => {
  const aClone = {
    ...a,
    keywordValues: 'keywordValues' in a ? a.keywordValues.sort() : undefined,
  } as any;
  delete aClone['id'];
  delete aClone['mode'];

  const bClone = {
    ...b,
    keywordValues: 'keywordValues' in b ? b.keywordValues.sort() : undefined,
  } as any;
  delete bClone['id'];
  delete bClone['mode'];

  return isEqual(aClone, bClone);
};

export default isDrillDownEqual;
