import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import Row from '../../Common/Row';
import ReportDrillDownContainer from '../../ReportDrillDown';
import { toPlate } from '../VariableFilterCard/VariableFilterCard';
import VariableFiltersContext from '../../../contexts/VariableFiltersContext';
import { buildDashboardShow } from '../../../navigation/appRoutes';
import Colors2 from '../../../theme/Colors2';

import Card from 'components/Common/Card';
import useDrillDownFieldName from '../../../hooks/useDrillDownFieldName';
import ReportDrillDownsProvider from '../../../contextProviders/ReportDrillDownsProvider';
import { useVariableFilterDataTypes } from '../../VariableFilterInput';

const Col = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  height: 48px;
  width: 100%;
  background-color: white;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    background-color: ${Colors2.Grey['9']};

    .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const List = styled(Card)`
  overflow-y: scroll;
  padding: 0px;
`;

const buildBlankVarFilter = (
  variableFilter: VariableDrillDownType,
): VariableDrillDownType => {
  return {
    id: variableFilter.id,
    value: {
      id: variableFilter.value.id,
      field: variableFilter.value.field,
      fieldType: variableFilter.value.fieldType,
      mode: 'editing',
    },
  };
};

const VarFilterName = ({
  variableFilter,
}: {
  variableFilter: VariableDrillDownType;
}) => {
  const filterName = useDrillDownFieldName(variableFilter.value);

  return <Typography.Body type="Body 12">{filterName}</Typography.Body>;
};

const Item = ({
  setValues,
  variableFilter,
  setHasUnsavedChanged,
  item,
  canvas,
}: {
  setValues: React.Dispatch<React.SetStateAction<VariableFilterBulkInputData>>;
  variableFilter: VariableDrillDownType;
  setHasUnsavedChanged: () => void;
  item: {
    dashboard: PersistedDashboardType;
    value: VariableDrillDownType | undefined;
  };
  canvas: Canvas;
}) => {
  const dataTypes = useVariableFilterDataTypes(variableFilter, canvas);

  return (
    <VariableFiltersContext.Provider
      value={{
        variableFilters: item.value
          ? [item.value]
          : [buildBlankVarFilter(variableFilter)],
        setVariableFilters: () => {},
      }}
    >
      <ReportDrillDownsProvider dataTypes={dataTypes}>
        <ReportDrillDownContainer
          drillDown={
            item.value
              ? toPlate(item.value)
              : {
                  id: variableFilter.id,
                  type: 'Variable',
                  variableId: variableFilter.id,
                }
          }
          isScope={false}
          isInVariableFilterList
          onUpdated={(newDrill) => {
            const newValue = item.value
              ? { ...item.value, value: newDrill }
              : {
                  id: variableFilter.id,
                  value: newDrill,
                };
            const newItem = {
              ...item,
              value: newValue,
            };
            setHasUnsavedChanged();
            setValues((values) => ({
              ...values,
              [item.dashboard.id]: newItem,
            }));
          }}
        />
      </ReportDrillDownsProvider>
    </VariableFiltersContext.Provider>
  );
};

const BulkUpdateFilterValues = ({
  values,
  setValues,
  isLoading,
  onSave,
  variableFilter,
  setHasUnsavedChanged,
  canvas,
}: {
  values: VariableFilterBulkInputData;
  setValues: React.Dispatch<React.SetStateAction<VariableFilterBulkInputData>>;
  isLoading: boolean;
  onSave: () => void;
  variableFilter: VariableDrillDownType;
  setHasUnsavedChanged: () => void;
  canvas: Canvas;
}) => (
  <div
    style={{
      padding: '16px 24px',
      overflowY: 'hidden',
      flex: 1,
      position: 'relative',
    }}
  >
    <div style={{ marginBottom: 32 }}>
      <Typography.Header type="H4">
        Update Variable Filter Values
      </Typography.Header>
    </div>
    <div>
      <Row>
        <Col />
        <Col>
          <VarFilterName variableFilter={variableFilter} />
        </Col>
      </Row>
      <List>
        {Object.values(values).map((item) => {
          if (!item) {
            return null;
          } else {
            return (
              <Wrapper key={item.dashboard.id}>
                <Col>
                  <a
                    href={buildDashboardShow(item.dashboard.id)}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <Typography.Body type="Link">
                      {item.dashboard.name}
                    </Typography.Body>
                  </a>
                </Col>
                <Col>
                  <Item
                    setValues={setValues}
                    variableFilter={variableFilter}
                    setHasUnsavedChanged={setHasUnsavedChanged}
                    item={item}
                    canvas={canvas}
                  />
                </Col>
              </Wrapper>
            );
          }
        })}
      </List>
      <div style={{ marginTop: 32 }}>
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <Button
            onClick={onSave}
            isLoading={isLoading}
            isDisabled={isLoading}
            label="Save"
            type="Primary"
            size="Small"
          />
        </Row>
      </div>
    </div>
  </div>
);

export default BulkUpdateFilterValues;
