import React, { useContext, useState } from 'react';
import ConfigureVariables from './ConfigureVariables';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import DashboardContext from '../../../contexts/DashboardContext';
import updateDashboard from '../../../api/updateDashboard';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { DateTime } from 'luxon';
import VariableFiltersContext from '../../../contexts/VariableFiltersContext';
import DashboardsContext from '../../../contexts/DashboardsContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';

// Popup to configure the variable filters on a given
// instance of a template
const ConfigureVariablesContainer = ({
  close,
  dashboard,
}: {
  close: () => void;
  dashboard: PersistedDashboardType;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);
  const { allDashboards } = useContext(DashboardsContext);
  const templateDash = allDashboards.find((d) => d.id === dashboard.templateId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [variableFilters, setVariableFilters] = useState<
    VariableDrillDownType[]
  >(() => dashboard.variableDrillDowns);

  const onSave = () => {
    const newDashboard = {
      ...dashboard,
      variableDrillDowns: variableFilters,
      updatedBy: currentUser.id,
      updatedOn: DateTime.utc().toISO(),
    };

    setIsLoading(true);
    updateDashboard({
      id: dashboard.id,
      newDashboard,
      accountRef,
    }).then(() => {
      setIsLoading(false);
      close();
    });
  };

  return (
    <VariableFiltersContext.Provider
      value={{
        variableFilters,
        setVariableFilters,
      }}
    >
      <ConfigureVariables
        variableFilters={variableFilters}
        setVariableFilters={setVariableFilters}
        isLoading={isLoading}
        onSave={onSave}
        close={close}
        canvas={templateDash ? templateDash.canvas : dashboard.canvas}
      />
    </VariableFiltersContext.Provider>
  );
};

const Gate = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const { dashboard } = useContext(DashboardContext);

  if (!dashboard) {
    return null;
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
          <ConfigureVariablesContainer close={close} dashboard={dashboard} />
        </Modal>
      )}
    </ModalTransition>
  );
};

export default Gate;
