import { useCallback, useContext } from 'react';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import RolesContext from '../../../contexts/RolesContext';

const useGetHasExecutivePortalAccess = () => {
  const { currentPermissions } = useContext(RolesContext);
  const currentUser = useContext(CurrentUserContext);
  const getHasPortalAccess = useCallback(
    (executivePortal: ExecutivePortal) => {
      const isManager = executivePortal.managerIds.some(
        (mid) => mid === currentUser.id,
      );
      const isAdmin = currentPermissions.some(
        (p) => p === 'fleetops.permissions.content_access_view_all_admin_only',
      );

      const hasAccess = isManager || isAdmin;
      return {
        isManager,
        isAdmin,
        hasAccess,
      };
    },
    [currentPermissions, currentUser.id],
  );

  return getHasPortalAccess;
};

export default useGetHasExecutivePortalAccess;
