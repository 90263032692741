import React, { lazy, useContext, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { Layout } from './styles';
import appRoutes, { buildShowWallBoard } from './appRoutes';
import AccountContext from '../contexts/AccountContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import { SLIDE_OUT_ELEMENT_ID, FADE_IN_ELEMENT_ID } from '../constants';
import Loading from '../components/Loading';
import FlexCentered from '../components/Common/FlexCentered';
import FleetOpsSuspense from '../FleetOpsSuspense';
import MainNav from '../kingpin/navigation/MainNav';
import OnBoardingSwitch from './OnboardingSwitch';
import { PortalsContext } from '../contextProviders/PortalsProvider';
import portalTypeCheckers from '../types/portalTypeCheckers';
import LegacyAuthenticatedSwitch from './LegacyAuthenticatedSwitch';
import PortalsSwitch from './PortalsSwitch';
import OnboardingProvider from './OnboardingProvider';
import WallBoard from '../screens/WallBoard';

const HomeScreen = lazy(() => import('../screens/Home'));
const SharedIndexScreen = lazy(() => import('../screens/SharedIndex'));
const EntityDetailsShow = lazy(() => import('../screens/EntityDetailsShow'));
const MyAccountScreen = lazy(() => import('../screens/MyAccount'));
const IRGuideScreen = lazy(
  () => import('../screens/Settings/Integrations/IRGuide'),
);
const ReportShow = lazy(() => import('../screens/ReportShow'));
const DashboardShow = lazy(() => import('../screens/DashboardShow'));
const ConnectionView = lazy(
  () => import('../screens/Settings/Integrations/ConnectionView'),
);
const WorkSpacesIndex = lazy(() => import('../screens/WorkSpacesIndex'));
const WorkSpacesShow = lazy(() => import('../screens/WorkSpacesShow'));

const ScorecardShow = lazy(() => import('../screens/ScorecardShow'));
const OnBoardingLanding = lazy(() => import('../screens/OnBoarding/Landing'));
const OnBoardingBilling = lazy(() => import('../screens/OnBoarding/Billing'));
const IntercomSetup = lazy(() => import('../components/Common/IntercomSetup'));

const Sidebar = () => (
  <Routes>
    <Route path={appRoutes.loggedIn.showWallBoard} element={null} />
    <Route path="*" element={<MainNav />} />
  </Routes>
);

const AuthenticatedSwitch = () => {
  const { isPortalsEnabled, selectedPortal } = useContext(PortalsContext);

  if (isPortalsEnabled) {
    if (selectedPortal && portalTypeCheckers.isAdminPortal(selectedPortal)) {
      return <LegacyAuthenticatedSwitch />;
    }

    return (
      <Routes>
        <Route path={appRoutes.home} element={<HomeScreen />} />
        <Route
          path={appRoutes.loggedIn.workspaces}
          element={<WorkSpacesIndex />}
        />
        <Route
          path={appRoutes.loggedIn.showWorkSpace}
          element={<WorkSpacesShow />}
        />
        <Route
          path={appRoutes.loggedIn.showWorkSpaceTab}
          element={<WorkSpacesShow />}
        />
        <Route
          path={appRoutes.loggedIn.shared}
          element={<SharedIndexScreen />}
        />
        <Route
          path={`/shared${appRoutes.loggedIn.showDashboard}`}
          element={<DashboardShow />}
        />
        <Route
          path={`/shared${appRoutes.loggedIn.showReport}`}
          element={<ReportShow />}
        />
        <Route
          path={appRoutes.loggedIn.showDashboard}
          element={<DashboardShow />}
        />
        <Route path={appRoutes.loggedIn.showReport} element={<ReportShow />} />
        <Route
          path={appRoutes.loggedIn.showGlobalKpiList}
          element={<ScorecardShow />}
        />
        <Route
          path={appRoutes.loggedIn.showWallBoard}
          element={<WallBoard />}
        />
        <Route
          path={appRoutes.loggedIn.showEntityApp}
          element={<EntityDetailsShow />}
        />
        <Route
          path={appRoutes.loggedIn.showEntityAppContentTab}
          element={<EntityDetailsShow />}
        />
        <Route
          path={appRoutes.loggedIn.myAccount}
          element={<MyAccountScreen />}
        />
        <Route path={'/:portal/*'} element={<PortalsSwitch />} />
      </Routes>
    );
  }

  return <LegacyAuthenticatedSwitch />;
};

const Authenticated = () => (
  <div>
    <Layout>
      <Routes>
        <Route
          path={'*'}
          element={
            <>
              <Sidebar />
              <FleetOpsSuspense>
                <AuthenticatedSwitch />
              </FleetOpsSuspense>
              <div id={SLIDE_OUT_ELEMENT_ID} />
              <div id={FADE_IN_ELEMENT_ID} />
            </>
          }
        />
      </Routes>
    </Layout>
  </div>
);

const RedirectToOnBoardingLanding = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(appRoutes.onBoarding.landing);
  }, [navigate]);

  return null;
};

export const OnBoardingNavigator = () => (
  <OnboardingProvider>
    <Routes>
      <Route
        path={appRoutes.onBoarding.billing}
        element={<OnBoardingBilling />}
      />
      <Route
        path={appRoutes.onBoarding.provider}
        element={<ConnectionView />}
      />
      <Route path={appRoutes.onBoarding.irGuide} element={<IRGuideScreen />} />
      <Route
        path={appRoutes.onBoarding.landing}
        element={<OnBoardingLanding />}
      />
      <Route path="*" element={<RedirectToOnBoardingLanding />} />
    </Routes>
    <IntercomSetup />
  </OnboardingProvider>
);

const OnBoardingGate = () => {
  const { status } = useContext(AccountContext);
  const { isWallboardUser, wallBoardId, isFleetOpsStaff } =
    useContext(CurrentUserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isWallboardUser && wallBoardId) {
      if (location.pathname !== buildShowWallBoard(wallBoardId)) {
        navigate(buildShowWallBoard(wallBoardId));
      }
    }
  }, [isWallboardUser, location.pathname, navigate, wallBoardId]);

  if (!status) {
    return (
      <div style={{ height: '100vh' }}>
        <FlexCentered style={{ height: '100%' }}>
          <Loading />
        </FlexCentered>
      </div>
    );
  }

  if (status !== 'active' && !isFleetOpsStaff) {
    return <OnBoardingSwitch />;
  }

  return <Authenticated />;
};

export default OnBoardingGate;
