import { useCallback, useContext } from 'react';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import RolesContext from '../../../contexts/RolesContext';

const useGetHasEngagementPortalAccess = () => {
  const { currentPermissions } = useContext(RolesContext);
  const currentUser = useContext(CurrentUserContext);
  const getHasEngagementPortalAccess = useCallback(
    (app: EngagementPortal) => {
      const isManager = app.managerIds.some((mid) => mid === currentUser.id);
      const isViewer = Object.keys(app.viewerMappings).includes(currentUser.id);
      const isAdmin = currentPermissions.some(
        (p) => p === 'fleetops.permissions.content_access_view_all_admin_only',
      );

      const hasAccess = isManager || isViewer || isAdmin;
      return {
        isManager,
        isViewer,
        isAdmin,
        hasAccess,
      };
    },
    [currentPermissions, currentUser.id],
  );

  return getHasEngagementPortalAccess;
};

export default useGetHasEngagementPortalAccess;
