import React, { ChangeEvent, useCallback, useContext, useState } from 'react';

import CopyDashboardForm from './CopyDashboardForm';
import WallboardContext from '../../../../contexts/WallboardContext';
import { useTemplatedForm } from '../../../Dashboard';
import { updateWallBoard } from '../../../../api/createWallBoard';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import useCreateDashboardCopy from '../../../../hooks/useCreateDashboardCopy';

const CopyDashboardFormContainer = ({
  close,
  setWallboardPreviewSlide,
}: {
  close: () => void;
  setWallboardPreviewSlide: (d: PersistedDashboardType) => void;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { wallboard } = useContext(WallboardContext);
  const [title, setTitle] = useState<string>('');
  const [dashboard, setDashboard] = useState<PersistedDashboardType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { templatedFrom } = useTemplatedForm(dashboard);
  const createDashboardCopy = useCreateDashboardCopy();

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onSaveClicked = useCallback(() => {
    if (!wallboard || !dashboard || title === '') {
      return;
    }
    setIsLoading(true);
    createDashboardCopy({
      name: title,
      dashboard,
      templatedFrom,
      wallboard,
      access: dashboard.access,
    }).then(({ newDashboard }) => {
      const newWallboard = {
        ...wallboard,
        wallBoardDashboardIds: [
          ...wallboard.wallBoardDashboardIds,
          newDashboard.id,
        ],
      };
      updateWallBoard(newWallboard, accountRef).then(() => {
        setWallboardPreviewSlide(newDashboard);
        trackEvent('Wallboard Config - Add Slide Completed');
        close();
      });
    });
  }, [
    accountRef,
    close,
    createDashboardCopy,
    dashboard,
    setWallboardPreviewSlide,
    templatedFrom,
    title,
    trackEvent,
    wallboard,
  ]);

  return (
    <CopyDashboardForm
      dashboard={dashboard}
      setDashboard={setDashboard}
      title={title}
      onTitleChanged={onTitleChanged}
      onSaveClicked={onSaveClicked}
      isLoading={isLoading}
    />
  );
};

export default CopyDashboardFormContainer;
