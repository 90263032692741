import React, { useContext } from 'react';
import Row from '../Common/Row';
import VariableFilterInput from '../VariableFilterInput';
import VariableFiltersContext from '../../contexts/VariableFiltersContext';
import isDashboardTemplate from '../../types/isDashboardTemplate';
import { EXCLUDE_HEIGHT } from '../../constants';

// Configure the runtime filters for a dashboard template
// Changes made by the user here are not to be persisted
const VariableRuntimeFilters = ({
  dashboard,
}: {
  dashboard: DashboardTemplate;
}) => {
  const { variableFilters, setVariableFilters } = useContext(
    VariableFiltersContext,
  );

  return (
    <Row
      style={{
        padding: '16px 24px 0px 24px',
        flexWrap: 'wrap',
      }}
      centerAlign
      data-testid={'variable-run-time-filters'}
      className={`${EXCLUDE_HEIGHT}-${dashboard.id}`}
    >
      {variableFilters.map((vf) => (
        <div key={vf.id} style={{ marginRight: 16 }}>
          <VariableFilterInput
            variableFilter={vf}
            setVariableFilters={setVariableFilters}
            canvas={dashboard.canvas}
          />
        </div>
      ))}
    </Row>
  );
};

const Gate = ({ dashboard }: { dashboard: PersistedDashboardType }) => {
  if (isDashboardTemplate(dashboard)) {
    return <VariableRuntimeFilters dashboard={dashboard} />;
  }
  return null;
};

export default Gate;
