import styled from 'styled-components';
import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from './Common/Row';
import Button from 'kingpin/atoms/Button';

const TextWrapper = styled.div`
  margin: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  margin: 12px;
`;

const Confirmation = ({ text, close }: { text: string; close: () => void }) => (
  <Wrapper>
    <TextWrapper>
      <Typography.Header type="H4">{text}</Typography.Header>
    </TextWrapper>
    <Row style={{ justifyContent: 'flex-end' }}>
      <Button type="Primary" size="Small" onClick={close} label="Ok" />
    </Row>
  </Wrapper>
);

export default Confirmation;
