import React, { useCallback, useContext, useEffect, useState } from 'react';
import Row from 'components/Common/Row';
import { ExecutivePortalsContext } from 'contextProviders/PortalsProvider/ExecutivePortalsProvider';
import PortalPickerStyles from 'kingpin/navigation/PortalPicker/styles';
import Typography from 'kingpin/atoms/Typography';
import usePortalsWithAccess from './usePortalsWithAccess';

const usePortalOptions = ({
  selectedPortals,
  setSelectedPortals,
  type,
  typeId,
}: {
  selectedPortals: ExecutivePortal[];
  setSelectedPortals: React.Dispatch<React.SetStateAction<ExecutivePortal[]>>;
  type: ExecutivePortalPublishableContentType;
  typeId: string;
}) => {
  const { allExecutivePortals } = useContext(ExecutivePortalsContext);
  const portalsWithAccess = usePortalsWithAccess({ typeId, type });

  const getOptions = useCallback((): DropdownOption[] => {
    return allExecutivePortals
      .filter((portal) => !portalsWithAccess.some((p) => p.id === portal.id))
      .map((portal): DropdownOption => {
        const isSelected = selectedPortals.some((p) => p.id === portal.id);
        return {
          key: portal.id,
          label: portal.name,
          renderLabel: () => (
            <Row centerAlign style={{ marginRight: 32 }}>
              <div style={{ marginRight: 8, display: 'flex' }}>
                <PortalPickerStyles.ColorDot
                  color={portal.color}
                  width={8}
                  height={8}
                />
              </div>
              <Typography.Body type={'Button Text'}>
                {portal.name}
              </Typography.Body>
            </Row>
          ),
          isSelected,
          onSelected: () => {
            setSelectedPortals((currentlySelected) => {
              if (isSelected) {
                return currentlySelected.filter((p) => p.id !== portal.id);
              }
              return [...currentlySelected, portal];
            });
          },
        };
      });
  }, [
    portalsWithAccess,
    allExecutivePortals,
    selectedPortals,
    setSelectedPortals,
  ]);

  const [options, setOptions] = useState<DropdownOption[]>(() => getOptions());
  useEffect(() => {
    setOptions(getOptions());
  }, [getOptions]);

  return options;
};

export default usePortalOptions;
