import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import React from 'react';
import Report from './Report';
import WeekStartsOnOverrideContext from '../contexts/WeekStartsOnOverrideContext';
import Row from './Common/Row';
import { POPUP_REPORT_HEIGHT } from './ReportCanvas/canvasConstants';

const PopupReport = ({
  report,
  isOpen,
  close,
  parentDashboardGadget,
  unSavedFilter,
}: {
  report?: PersistedReportType;
  isOpen: boolean;
  close: () => void;
  parentDashboardGadget?: DashboardGadget;
  unSavedFilter?: UnSavedFilter;
}) => {
  return (
    <ModalTransition>
      {isOpen && report && (
        <Modal
          height={POPUP_REPORT_HEIGHT.MODAL_HEIGHT}
          width={'95vw'}
          onClose={close}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <WeekStartsOnOverrideContext.Provider
            value={{
              weekStartsOnOverride:
                parentDashboardGadget &&
                parentDashboardGadget.weekStartsOnOverride
                  ? parentDashboardGadget.weekStartsOnOverride
                  : undefined,
            }}
          >
            <Row>
              <div style={{ width: '100%' }}>
                <Report
                  report={report}
                  hideMenu
                  parentDashboardGadget={parentDashboardGadget}
                  linkedDashboardGadget={parentDashboardGadget}
                  unSavedFilter={unSavedFilter}
                />
              </div>
            </Row>
          </WeekStartsOnOverrideContext.Provider>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default PopupReport;
