import React from 'react';
import Row from 'components/Common/Row';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import Button from 'kingpin/atoms/Button';

import PortalMultiSelect from './PortalMultiSelect';
import AdditionalContentGate from './AdditionalContentGate';
import SuccessMessage from '../SuccessMessage';
import PortalsWithAccess from './PortalsWithAccess';

const ManageAccessModal = ({
  selectedPortals,
  setSelectedPortals,
  isPublished,
  contentName,
  type,
  typeId,
  isLoading,
  successMessage,
  onPublishClicked,
  close,
}: {
  selectedPortals: ExecutivePortal[];
  setSelectedPortals: React.Dispatch<React.SetStateAction<ExecutivePortal[]>>;
  isPublished: boolean;
  contentName: string;
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  isLoading: boolean;
  successMessage?: string;
  onPublishClicked: () => void;
  close: () => void;
}) => (
  <Form>
    <FormHeader
      title={
        isPublished
          ? `Manage Access to '${contentName}'`
          : `Publish '${contentName}'`
      }
      subTitle="Select the executive portals to add this dashboard to"
      onClose={close}
    />
    <FormContent>
      <Row spaceBetween centerAlign>
        <PortalMultiSelect
          selectedPortals={selectedPortals}
          setSelectedPortals={setSelectedPortals}
          type={type}
          typeId={typeId}
        />
        <Button
          label={isPublished ? 'Grant Access' : 'Publish'}
          size={'Medium'}
          type={'Primary'}
          isLoading={isLoading}
          isDisabled={selectedPortals.length === 0 || isLoading}
          onClick={onPublishClicked}
        />
      </Row>
      <AdditionalContentGate
        successMessage={successMessage}
        isPublished={isPublished}
      >
        <>
          {successMessage && (
            <div style={{ marginBottom: 16 }}>
              <SuccessMessage successMessage={successMessage} />
            </div>
          )}
          {isPublished && (
            <PortalsWithAccess
              type={type}
              typeId={typeId}
              contentName={contentName}
            />
          )}
        </>
      </AdditionalContentGate>
    </FormContent>
  </Form>
);

export default ManageAccessModal;
