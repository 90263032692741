import React from 'react';
import styled from 'styled-components';
import Typography from '../../atoms/Typography';
import ColorDot from './ColorDot';
import Colors2 from '../../../theme/Colors2';

const Divider = styled.div`
  border-bottom: #ededed;
  margin-bottom: 4px;
`;

const SectionTitleDiv = styled.div`
  padding: 4px 12px;
`;

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <SectionTitleDiv>
      <Typography.Body type={'Button Text'} color={'#8E8E8E'}>
        {title}
      </Typography.Body>
    </SectionTitleDiv>
  );
};

const PortalOptionDiv = styled.div<{ isSelected: boolean }>`
  padding: 8px 12px;
  background-color: ${(props) => (props.isSelected ? '#F6F6F6' : 'white')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: ${Colors2.Grey['9']};
  }
`;

const PortalList = styled.div`
  max-height: 250px;
  overflow-y: scroll;
`;

const CreatePortalDiv = styled.div`
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
`;

const PortalPickerStyles = {
  Divider,
  SectionTitle,
  PortalOptionDiv,
  ColorDot,
  CreatePortalDiv,
  PortalList,
};

export default PortalPickerStyles;
