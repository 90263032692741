import React from 'react';

import styled from 'styled-components';

import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Colors from '../../../theme/colors';
import Colors2 from '../../../theme/Colors2';
import Icon from '../../../kingpin/atoms/Icon';

const Wrapper = styled.div`
  padding: 12px;
  background-color: ${Colors2.Secondary.warning}30;
  margin-top: -22px;
  margin-bottom: 22px;
`;

const UpdateWarning = ({
  onUpdateClicked,
  withoutFilterSet,
}: {
  onUpdateClicked: () => void;
  withoutFilterSet: number;
}) => (
  <Wrapper>
    <Row style={{ marginBottom: 8 }} centerAlign>
      <div style={{ marginRight: 8 }}>
        <Icon icon="warning" color={Colors.AMBER} />
      </div>
      <Typography.Body type="Body 12">
        {`Update filter values for ${withoutFilterSet} dashboard${
          withoutFilterSet > 1 ? 's' : ''
        } created from this template`}
      </Typography.Body>
    </Row>
    <Row style={{ justifyContent: 'flex-end' }}>
      <Button
        onClick={onUpdateClicked}
        type="Ghost"
        size="Small"
        label="Update"
      />
    </Row>
  </Wrapper>
);

export default UpdateWarning;
