import aguid from 'aguid';
import updateDashboardGadget from '../../../api/updateDashboardGadget';
import createSavedFilter from '../../../api/createSavedFilter';
import { useCallback, useContext } from 'react';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import isDrillDownEqual from './useDrillDownEqual';

const useOnReplaceAllClicked = ({
  matches,
  variableFilter,
}: {
  matches: {
    gadgets: DashboardGadget[];
    savedFilters: SavedFilter[];
  };
  variableFilter: VariableDrillDownType;
}) => {
  const { selectedAccountId, accountRef } = useContext(AccountPickerContext);

  const onReplaceAllClicked = useCallback(() => {
    const newPlate = {
      id: aguid(),
      type: 'Variable',
      variableId: variableFilter.id,
    } as FilterPlate;
    const gadgetRequests = matches.gadgets.map((gadget) => {
      const scopeWithoutReplacement = gadget.scope.filter((scopePlate) => {
        if (scopePlate.type === 'Variable') {
          return true;
        } else {
          return (
            !!scopePlate.fixedValue &&
            !isDrillDownEqual(scopePlate.fixedValue, variableFilter.value)
          );
        }
      });
      const drillDownsWithoutReplacement = gadget.drillDowns.filter(
        (drillPlate) => {
          if (drillPlate.type === 'Variable') {
            return true;
          } else {
            return (
              !!drillPlate.fixedValue &&
              !isDrillDownEqual(drillPlate.fixedValue, variableFilter.value)
            );
          }
        },
      );
      const newDrillDowns = [...drillDownsWithoutReplacement, newPlate];
      const newGadget = {
        ...gadget,
        scope: scopeWithoutReplacement,
        drillDowns: newDrillDowns,
      };

      return updateDashboardGadget(gadget.id, newGadget, selectedAccountId);
    });

    const savedFilterRequests = matches.savedFilters.map((savedFilter) => {
      const scopeWithoutReplacement = savedFilter.scope.filter((scopePlate) => {
        if (scopePlate.type === 'Variable') {
          return true;
        } else {
          return (
            !!scopePlate.fixedValue &&
            !isDrillDownEqual(scopePlate.fixedValue, variableFilter.value)
          );
        }
      });
      const drillDownsWithoutReplacement = savedFilter.drillDowns.filter(
        (drillPlate) => {
          if (drillPlate.type === 'Variable') {
            return true;
          } else {
            return (
              !!drillPlate.fixedValue &&
              !isDrillDownEqual(drillPlate.fixedValue, variableFilter.value)
            );
          }
        },
      );
      const newDrillDowns = [...drillDownsWithoutReplacement, newPlate];
      const newSavedFilter = {
        ...savedFilter,
        scope: scopeWithoutReplacement,
        drillDowns: newDrillDowns,
      };

      return createSavedFilter(newSavedFilter, accountRef);
    });
    Promise.all([...gadgetRequests, ...savedFilterRequests]).then(() => {});
  }, [
    accountRef,
    matches.gadgets,
    matches.savedFilters,
    selectedAccountId,
    variableFilter.id,
    variableFilter.value,
  ]);

  return onReplaceAllClicked;
};

export default useOnReplaceAllClicked;
