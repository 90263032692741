import React from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';

import Row from '../Common/Row';
import ManageAccessModal from './ManageAccessModal';
import useIsPublished from './useIsPublished';
import ContextMenu, { Option } from '../../kingpin/atoms/ContextMenu';
import ShareModal from './ShareModal';

const Divider = styled.div`
  background-color: #d9d9d9;
  width: 1px;
  height: 24px;
  margin: 0px 16px;
`;

const ShareButton = ({
  isPublished,
  isShareEnabled,
  hasDivider,
  openShare,
  openAccess,
}: {
  isPublished: boolean;
  isShareEnabled: boolean;
  hasDivider?: boolean;
  openShare: () => void;
  openAccess: () => void;
}) => {
  if (!isPublished) {
    return (
      <Row centerAlign>
        <Button
          type="Primary"
          size="Small"
          label="Publish"
          onClick={openAccess}
        />
        {hasDivider && <Divider />}
      </Row>
    );
  }

  if (isShareEnabled) {
    return (
      <div style={{ marginRight: 16 }}>
        <ContextMenu label="Share" icon="shared" iconAfter="chevron-down">
          <Option label="Share" onClick={openShare} icon="shared" />
          <Option label="Manage Access" onClick={openAccess} icon="group" />
        </ContextMenu>
      </div>
    );
  }

  return (
    <div style={{ marginRight: 16 }}>
      <Button
        size={'Small'}
        type={'Secondary'}
        label={'Manage Access'}
        onClick={openAccess}
      />
    </div>
  );
};

const ShareContentButton = ({
  type,
  typeId,
  contentName,
  hasDivider,
}: {
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  contentName: string;
  hasDivider?: boolean;
}) => {
  const isPublished = useIsPublished({
    type,
    typeId,
  });
  const isShareEnabled = type === 'report' || type === 'dashboard';
  const {
    isOpen: isAccessOpen,
    open: openAccess,
    close: closeAccess,
  } = usePopup();
  const {
    isOpen: isShareOpen,
    open: openShare,
    close: closeShare,
  } = usePopup();

  return (
    <>
      <ShareButton
        isPublished={isPublished}
        isShareEnabled={isShareEnabled}
        hasDivider={hasDivider}
        openShare={openShare}
        openAccess={openAccess}
      />
      <ModalTransition>
        {isAccessOpen && (
          <Modal
            onClose={closeAccess}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <ManageAccessModal
              type={type}
              typeId={typeId}
              contentName={contentName}
              close={closeAccess}
              isPublished={isPublished}
            />
          </Modal>
        )}
        {isShareOpen && isShareEnabled && (
          <Modal
            onClose={closeShare}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <ShareModal
              type={type}
              typeId={typeId}
              contentName={contentName}
              close={closeShare}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ShareContentButton;
