import getFleetOpsStaffUsersRef from './getFleetOpsStaffUsersRef';
import getUsersRef from './getUsersRef';
import getSharedContentRef from './getSharedContentRef';

const users = {
  getUsersRef,
  getFleetOpsStaffUsersRef,
  getSharedContentRef,
};

export default users;
