import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const BrokenDashboardTemplate = () => (
  <Wrapper>
    <Typography.Header type="H4">
      We encountered a problem loading your dashboard. Please contact support
    </Typography.Header>
  </Wrapper>
);

export default BrokenDashboardTemplate;
