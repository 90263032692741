import React, { ChangeEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import DashboardForm from './DashboardForm';
import createDashboard from '../../api/createDashboard';
import { buildDashboardShow } from '../../navigation/appRoutes';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import updateDashboard from '../../api/updateDashboard';
import updateRecentName from '../../api/recents/updateRecentName';
import updateFavouriteName from '../../api/favourites/updateFavouriteName';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import WallboardContext from '../../contexts/WallboardContext';
import { updateWallBoard } from '../../api/createWallBoard';
import DashboardsContext from '../../contexts/DashboardsContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import LocalTimelineProvider from '../../contextProviders/TimelineProvider/LocalTimelineProvider';
import useContentSharedEmails from '../../hooks/useContentSharedEmails';
import useAddToCurrentExecutivePortal from './useAddToCurrentExecutivePortal';

interface Props {
  isOpen: boolean;
  close: () => void;
  isSaveAs?: boolean;
  isSavingAs?: boolean;
  onSaveAs?: ({ name }: { name: string }) => void;
  isTemplate?: boolean;
  onBackPressed?: () => void;
  dashboard?: PersistedDashboardType;
  isAlreadyInModal?: boolean;
  setWallboardPreviewSlide?: (d: PersistedDashboardType) => void;
}

const DashboardFormContainer = ({
  isOpen,
  close,
  isSaveAs,
  isSavingAs,
  onSaveAs,
  isTemplate,
  onBackPressed,
  dashboard,
  isAlreadyInModal,
  setWallboardPreviewSlide,
}: Props) => {
  const navigate = useNavigate();
  const addToCurrentExecutivePortal = useAddToCurrentExecutivePortal();
  const { accountRef } = useContext(AccountPickerContext);
  const { wallboard, isWallboard } = useContext(WallboardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { sendContentSharedEmails } = useContentSharedEmails();
  const currentUser = useContext(CurrentUserContext);
  const { addOrUpdateDashboard } = useContext(DashboardsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(dashboard ? dashboard.name : '');
  const [access, setAccess] = useState<ResourceAccess>(
    dashboard
      ? dashboard.access
      : {
          version: '2',
          type: 'Private',
        },
  );
  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const isValid = title !== '';

  const onSubmit = () => {
    if (!isValid || currentUser === undefined) {
      alert('Input is not valid');
      return;
    }

    if (dashboard) {
      setIsLoading(true);
      const newDashboard = {
        ...dashboard,
        name: title,
        access,
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      updateDashboard({
        id: newDashboard.id,
        newDashboard,
        accountRef,
      }).then(() => {
        addOrUpdateDashboard(newDashboard);
        const requests = [
          updateRecentName({
            type: 'dashboard',
            typeId: dashboard.id,
            newName: title,
            userId: currentUser.id,
            accountRef,
          }),
          updateFavouriteName({
            type: 'dashboard',
            typeId: dashboard.id,
            newName: title,
            userId: currentUser.id,
            accountRef,
          }),
          sendContentSharedEmails({
            type: 'Resource Access',
            initialAccess: dashboard.access,
            newAccess: newDashboard.access,
          }),
        ];
        Promise.all(requests).then(() => {
          setIsLoading(false);
          close();
        });
      });
    } else if (isSaveAs && onSaveAs) {
      setIsLoading(true);
      onSaveAs({ name: title });
    } else {
      setIsLoading(true);

      createDashboard({
        name: title,
        currentUser,
        isTemplate,
        access,
        accountRef,
        wallboard,
      }).then(async ({ newDashboardId, newDashboard }) => {
        await addToCurrentExecutivePortal(newDashboard);
        trackEvent(
          isTemplate
            ? 'Dashboard Creation - New Template'
            : 'Dashboard Creation - New',
        );
        addOrUpdateDashboard(newDashboard);
        await sendContentSharedEmails({
          type: 'Resource Access',
          initialAccess: undefined,
          newAccess: newDashboard.access,
        });
        if (wallboard) {
          const newWallboard = {
            ...wallboard,
            wallBoardDashboardIds: [
              ...wallboard.wallBoardDashboardIds,
              newDashboardId,
            ],
          };
          updateWallBoard(newWallboard, accountRef).then(() => {
            setIsLoading(false);
            close();
            if (setWallboardPreviewSlide) {
              setWallboardPreviewSlide(newDashboard);
              trackEvent('Wallboard Config - Add Slide Completed');
            }
          });
        } else {
          setIsLoading(false);
          close();
          navigate(buildDashboardShow(newDashboardId, true));
        }
      });
    }
  };

  return (
    <DashboardForm
      isWallboard={isWallboard}
      isEditing={!!dashboard}
      isOpen={isOpen}
      onSubmit={onSubmit}
      close={close}
      isLoading={isLoading || !!isSavingAs}
      isValid={isValid}
      title={title}
      onTitleChanged={onTitleChanged}
      isSaveAs={!!isSaveAs}
      onBackPressed={onBackPressed}
      access={access}
      setAccess={setAccess}
      isAlreadyInModal={isAlreadyInModal}
      canSetToPrivate={
        dashboard
          ? dashboard.createdBy === currentUser.id ||
            currentUser.isFleetOpsStaff
          : true
      }
    />
  );
};

const Gate = (props: Props) => {
  return (
    <LocalTimelineProvider sources={window.emptyArray}>
      <DashboardFormContainer {...props} />
    </LocalTimelineProvider>
  );
};

export default Gate;
