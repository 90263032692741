import React, { useContext } from 'react';
import { PortalsContext } from 'contextProviders/PortalsProvider';
import portalTypeCheckers from 'types/portalTypeCheckers';
import EngagementPortalNavSection from './EngagementPortalNavSection';
import AdminPortalNavSection from './AdminPortalNavSection';
import ExecutivePortalNavSection from './ExecutivePortalNavSection';

const PortalNavSection = () => {
  const { selectedPortal } = useContext(PortalsContext);

  if (!selectedPortal) {
    return null;
  } else if (portalTypeCheckers.isExecutivePortal(selectedPortal)) {
    return <ExecutivePortalNavSection portal={selectedPortal} />;
  } else if (portalTypeCheckers.isEngagementPortal(selectedPortal)) {
    return <EngagementPortalNavSection portal={selectedPortal} />;
  } else if (portalTypeCheckers.isAdminPortal(selectedPortal)) {
    return <AdminPortalNavSection />;
  }

  return null;
};

export default PortalNavSection;
