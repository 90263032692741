import React from 'react';

import Dropdown from 'components/Inputs/Dropdown';
import Row from 'components/Common/Row';
import Badge from 'components/Badge';
import Typography from 'kingpin/atoms/Typography';
import ColorDot from 'kingpin/navigation/PortalPicker/ColorDot';

import usePortalOptions from './usePortalOptions';

const PortalMultiSelect = ({
  selectedPortals,
  setSelectedPortals,
  type,
  typeId,
}: {
  selectedPortals: ExecutivePortal[];
  setSelectedPortals: React.Dispatch<React.SetStateAction<ExecutivePortal[]>>;
  type: ExecutivePortalPublishableContentType;
  typeId: string;
}) => {
  const portalOptions = usePortalOptions({
    selectedPortals,
    setSelectedPortals,
    type,
    typeId,
  });

  return (
    <div style={{ flex: 1, marginRight: 16 }}>
      <Dropdown
        isSearchEnabled
        searchPlaceholder={'Search Portals'}
        size="Medium"
        placeholder={'Select Portals'}
        isMulti
        options={portalOptions}
        renderSelectedLabel={() => (
          <>
            {selectedPortals.length === 0 && (
              <Typography.Body type={'Placeholder'}>
                Select Portals
              </Typography.Body>
            )}
            <Row centerAlign>
              {selectedPortals.map((portal) => (
                <div key={portal.id} style={{ marginRight: 8 }}>
                  <Badge
                    text={portal.name}
                    badgeType={'Default'}
                    iconBefore={
                      <div style={{ marginRight: 8, display: 'flex' }}>
                        <ColorDot color={portal.color} height={8} width={8} />
                      </div>
                    }
                  />
                </div>
              ))}
            </Row>
          </>
        )}
      />
    </div>
  );
};

export default PortalMultiSelect;
