const isWeatherBoard = (
  board: PersistedDashboardType | WeatherBoardType,
): board is WeatherBoardType => {
  // @ts-ignore
  if (board.canvas) {
    return false;
  } else {
    return true;
  }
};

export default isWeatherBoard;
