import React, { useContext } from 'react';
import Row from '../Common/Row';
import OmniSearch from '../OmniSearch';
import ReportDrillDowns from '../ReportDrillDowns';

import Grid from './Grid';
import Settings from '../CustomerLaneCommitsBoard/Settings';
import QuickFilters from '../CustomerLaneCommitsBoard/QuickFilters';
import SignOff from './BonusSignOff';
import { ColumnApi, GridApi } from 'ag-grid-community';
import PeriodSelector from './PeriodSelector';
import ExportButton from '../Grid/ExportButton';
import { SALES_COMMISSION_ACTIONS_ELEMENT_ID } from './constants';
import Typography from 'kingpin/atoms/Typography';
import {
  BoardFilterBar,
  BoardMainContent,
  BoardTopBar,
  BoardWrapper,
} from '../CustomerLaneCommitsBoard/CustomerLaneCommitBoard';
import BoardContext from '../../contexts/BoardContext';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';

const Board = ({
  board,
  sortOverride,
  onQuickFilterChanged,
  onManualFilterChanged,
  gridApi,
  setGridApi,
  columnApiRef,
}: {
  board: DriverPerformanceBoard | GeneralBoard;
  sortOverride?: SimpleGridSort[];
  onQuickFilterChanged: () => void;
  onManualFilterChanged: () => void;
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
  columnApiRef: React.MutableRefObject<ColumnApi | undefined>;
}) => {
  const { quickFilters } = useContext(BoardContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const isWorkSpace = !!workSpace;
  const hasQuickFilters = quickFilters.length > 0;

  return (
    <BoardWrapper>
      <BoardTopBar>
        <Typography.Header type="H5">{board.name}</Typography.Header>
        <Row centerAlign>
          <Row style={{ marginRight: 8 }} centerAlign>
            <div style={{ marginRight: 8 }}>
              <ExportButton overrideGridApi={gridApi} exportName={board.name} />
            </div>
            <PeriodSelector isWorkSpaceTopBar={false} />
          </Row>

          <div style={{ marginRight: 8 }}>
            <SignOff />
          </div>
          <Settings />
        </Row>
      </BoardTopBar>
      <BoardFilterBar hasBorder={hasQuickFilters}>
        <Row centerAlign>
          <div style={{ marginRight: 8, width: 150 }}>
            <OmniSearch.DrillDownBuilder
              dataTypes={[board.dataType]}
              onManualFilterChanged={onManualFilterChanged}
            />
          </div>
          <ReportDrillDowns onManualFilterChanged={onManualFilterChanged} />
        </Row>
        <div id={SALES_COMMISSION_ACTIONS_ELEMENT_ID} />
      </BoardFilterBar>
      {hasQuickFilters && (
        <BoardFilterBar>
          <QuickFilters onQuickFilterChanged={onQuickFilterChanged} />
        </BoardFilterBar>
      )}
      <BoardMainContent
        isWorkSpace={isWorkSpace}
        hasQuickFilters={hasQuickFilters}
      >
        <Grid
          board={board}
          sortOverride={sortOverride}
          gridApi={gridApi}
          setGridApi={setGridApi}
          columnApiRef={columnApiRef}
        />
      </BoardMainContent>
    </BoardWrapper>
  );
};

export default Board;
