import React from 'react';
import ContextMenu, { Break, Info, Option } from 'kingpin/atoms/ContextMenu';
import Tooltip from '../../Tooltip';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';
import FeatureGate, { FEATURE_GATES } from '../../FeatureGate';

const DashboardMenu = ({
  onStartEditing,
  onAddGadgetFromGalleryClicked,
  onCreateNewGadgetClicked,
  onSaveAs,
  createTemplateFrom,
  onDelete,
  onSettingsClicked,
  isFullScreen,
  onFullscreenClicked,
  onExitFullscreenClicked,
  openIFrameForm,
  onImageCardAdded,
  openVideoCardForm,
  openTextCardForm,
  onMetricListAdded,
  onAddScorecardClicked,
  openVariableFiltersSlideOut,
  onConfigureVariables,
  onViewSourceTemplate,
  isTemplate,
  isTemplated,
  isDeletable,
  isWallboard,
  isScorecardDashboard,
  isCreatedByMe,
  dashboard,
}: {
  onStartEditing: () => void;
  onAddGadgetFromGalleryClicked: () => void;
  onCreateNewGadgetClicked: () => void;
  onSaveAs: () => void;
  createTemplateFrom: () => Promise<void>;
  onDelete: () => void;
  onSettingsClicked: () => void;
  isFullScreen: boolean;
  onFullscreenClicked: () => void;
  onExitFullscreenClicked: () => void;
  openIFrameForm: () => void;
  onImageCardAdded: () => void;
  openVideoCardForm: () => void;
  openTextCardForm: () => void;
  onMetricListAdded: () => void;
  onAddScorecardClicked: () => void;
  openVariableFiltersSlideOut: () => void;
  onConfigureVariables: () => void;
  onViewSourceTemplate: (templateId: string | undefined) => void;
  isTemplate: boolean;
  isTemplated: boolean;
  isDeletable: boolean;
  isWallboard?: boolean;
  isScorecardDashboard: boolean;
  isCreatedByMe: boolean;
  dashboard: PersistedDashboardType;
}) => (
  <ContextMenu testId="dashboard-menu">
    <>
      <PermissionGates.Has
        requiredPermission={
          PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC
        }
      >
        <Option label={'Settings'} onClick={onSettingsClicked} />
      </PermissionGates.Has>
      {!isTemplated && (
        <PermissionGates.Has requiredPermission={PERMISSIONS.DASHBOARDS.EDIT}>
          <Option label={'Edit'} onClick={onStartEditing} />
        </PermissionGates.Has>
      )}
      {!isFullScreen && (
        <Option label={'Fullscreen'} onClick={onFullscreenClicked} />
      )}
      {isFullScreen && (
        <Option label={'Exit Fullscreen'} onClick={onExitFullscreenClicked} />
      )}
      {!isTemplated && (
        <>
          <PermissionGates.Has requiredPermission={PERMISSIONS.DASHBOARDS.EDIT}>
            <Option
              label={'Add Visualization'}
              subMenuPlacement={'left'}
              subMenu={
                <>
                  <Option
                    label={'Add gadget from gallery'}
                    onClick={onAddGadgetFromGalleryClicked}
                  />
                  <Option
                    label={'Create new gadget'}
                    onClick={onCreateNewGadgetClicked}
                  />
                  <Option
                    label={'Add metric list'}
                    onClick={onMetricListAdded}
                  />
                  <Option
                    label={'Add KPI List'}
                    onClick={onAddScorecardClicked}
                    isDisabled={isTemplate}
                    disabledMessage="KPI Lists are not available on templates yet"
                  />
                  <Option label={'Add image card'} onClick={onImageCardAdded} />
                  <Option
                    label={'Add video card'}
                    onClick={openVideoCardForm}
                  />
                  <Option label={'Add text card'} onClick={openTextCardForm} />
                  <Option label={'Add IFrame'} onClick={openIFrameForm} />
                </>
              }
            />
          </PermissionGates.Has>
          {!isWallboard && (
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.DASHBOARDS.CREATE}
            >
              <Option label={'Save as'} onClick={onSaveAs} />
            </PermissionGates.Has>
          )}
        </>
      )}
      {isTemplate && (
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.DASHBOARDS.CREATE_TEMPLATE}
        >
          <Option
            label={'Variable Filters'}
            onClick={() => {
              openVariableFiltersSlideOut();
            }}
          />
        </PermissionGates.Has>
      )}
      {!isWallboard && !isTemplate && !isTemplated && (
        <FeatureGate featureName={FEATURE_GATES.TEMPLATES}>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DASHBOARDS.CREATE_TEMPLATE}
          >
            <Option
              isDisabled={isScorecardDashboard}
              disabledMessage={'KPI Lists are not supported in templates yet'}
              label={'Create Template From'}
              onClick={createTemplateFrom}
            />
          </PermissionGates.Has>
        </FeatureGate>
      )}
      {isTemplated && (
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.DASHBOARDS.CREATE_TEMPLATE}
        >
          <Option
            label={'Configure Variables'}
            onClick={onConfigureVariables}
          />

          <>
            {dashboard.templateId && (
              <Option
                label={'View Source Template'}
                onClick={() => onViewSourceTemplate(dashboard.templateId)}
              />
            )}
          </>

          <Option label={'Save as'} onClick={onSaveAs} />
        </PermissionGates.Has>
      )}
      <Info {...dashboard} />
      {!isWallboard && isDeletable && (
        <PermissionGates.Has
          requiredPermission={
            isCreatedByMe
              ? PERMISSIONS.DASHBOARDS.DELETE_CREATED_BY_ME
              : PERMISSIONS.DASHBOARDS.DELETE_ANY_DASHBOARD
          }
        >
          <>
            <Break />
            <Option label={'Delete'} onClick={onDelete} isDanger />
          </>
        </PermissionGates.Has>
      )}
      {!isWallboard && !isDeletable && (
        <>
          <Break />
          <Tooltip
            content={
              "You cannot delete this template until you have first deleted all of it's instances"
            }
          >
            <Option
              isDanger
              label={'Delete'}
              onClick={() => {
                alert(
                  "You cannot delete this template until you have first deleted all of it's instances",
                );
              }}
            />
          </Tooltip>
        </>
      )}
    </>
  </ContextMenu>
);

export default DashboardMenu;
