import React, { useContext, useEffect, useState } from 'react';

import WorkSpacesContext from 'contexts/WorkSpacesContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import RolesContext from 'contexts/RolesContext';
import useAllWorkSpaces from 'hooks/workspaces/useAllWorkSpaces';
import useHasAccess from 'hooks/useHasAccess';

const WorkSpacesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { currentPermissions } = useContext(RolesContext);
  const { allWorkSpaces, isLoading: isLoadingAll } = useAllWorkSpaces();
  const [availableWorkSpaces, setAvailableWorkSpaces] = useState<
    (WorkSpace | TargetsApp.App)[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const currentUser = useContext(CurrentUserContext);
  const hasAccess = useHasAccess();
  const isShowingPerformance = currentUser.isFleetOpsStaff;

  useEffect(() => {
    setAvailableWorkSpaces(
      allWorkSpaces.filter((w) =>
        hasAccess({
          access: w.access,
          resource: w,
          type: 'workspace',
          typeId: w.id,
        }),
      ),
    );
    if (!isLoadingAll) {
      setIsLoading(false);
    }
  }, [
    allWorkSpaces,
    currentUser,
    isLoadingAll,
    isShowingPerformance,
    currentPermissions,
    hasAccess,
  ]);

  return (
    <WorkSpacesContext.Provider
      value={{ allWorkSpaces, availableWorkSpaces, isLoading }}
    >
      {children}
    </WorkSpacesContext.Provider>
  );
};

export default WorkSpacesProvider;
