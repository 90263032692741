import firebase from 'firebase/compat/app';

const getSavedFilter = async (
  id: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  const doc = await accountRef.collection('savedFilters').doc(id).get();
  return doc.data() as SavedFilter;
};

export default getSavedFilter;
