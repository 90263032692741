import React from 'react';
import Button from 'kingpin/atoms/Button';
import Row from '../../Common/Row';
import InlineDialog from '../../InlineDialog';
import ListPicker from '../../Inputs/ListPicker';

const AddSlideButton = ({
  close,
  isOpen,
  open,
  onAddWallboardClicked,
  onNewDashboardClicked,
  onFromDashboardClicked,
}: {
  close: () => void;
  isOpen: boolean;
  open: () => void;
  onAddWallboardClicked: () => void;
  onNewDashboardClicked: () => void;
  onFromDashboardClicked: () => void;
}) => (
  <Row style={{ width: '100%' }}>
    <InlineDialog
      isAnchorDisplayFlex
      isOpen={isOpen}
      onClose={close}
      content={
        <ListPicker
          options={[
            { label: 'New', onClick: onNewDashboardClicked },
            { label: 'From dashboard', onClick: onFromDashboardClicked },
            { label: 'Weatherboard', onClick: onAddWallboardClicked },
          ]}
        />
      }
    >
      <Button
        onClick={isOpen ? close : open}
        type="Primary"
        size="Small"
        icon="add"
        label="Add Slide"
      />
    </InlineDialog>
  </Row>
);

export default AddSlideButton;
