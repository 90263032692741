import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';

import BulkSalesCommissionAction from './BulkSalesCommissionAction';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import BulkGridActionsContext from '../../../contexts/BulkGridActionsContext';
import usePopup from '../../../hooks/usePopup';
import CloudFunctionClientContext from '../../../contexts/CloudFunctionClientContext';
import GridContext from '../../../contexts/GridContext';
import postBulkCommission from './postBulkCommission';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

const BulkSalesCommissionActionContainer = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const { restartGrid } = useContext(GridContext);
  const { selectedItems, setSelectedItems } = useContext(
    BulkGridActionsContext,
  );
  const { email } = useContext(CurrentUserContext);
  const { isOpen, open, close } = usePopup();

  const [date, setDate] = useState<string>(() => moment().format('YYYY-MM-DD'));
  const [status, setStatus] = useState<'paid' | 'due' | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const getStatusOptions = useCallback(
    () => [
      {
        key: 'paid',
        label: 'Paid',
        onSelected: () => {
          setStatus('paid');
          setDate(moment().format('YYYY-MM-DD'));
        },
        isSelected: status === 'paid',
      },
      {
        key: 'due',
        label: 'Due',
        onSelected: () => {
          setStatus('due');
        },
        isSelected: status === 'due',
      },
    ],
    [status],
  );
  const [statusOptions, setStatusOptions] = useState<DropdownOption[]>(() =>
    getStatusOptions(),
  );

  useEffect(() => {
    setStatusOptions(getStatusOptions());
  }, [getStatusOptions]);

  const onDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  }, []);

  const onSave = useCallback(() => {
    setIsSaving(true);
    if (!status) {
      return;
    }

    const body =
      status === 'paid'
        ? {
            paidBy: email,
            paidOn: date,
            ids: selectedItems,
          }
        : {
            ids: selectedItems,
          };

    postBulkCommission({ api, status, body }).then((response) => {
      if (response.ok) {
        setIsSaving(false);
        setSelectedItems([]);
        restartGrid(true);
        close();
      }
    });
  }, [
    api,
    close,
    date,
    email,
    restartGrid,
    selectedItems,
    setSelectedItems,
    status,
  ]);

  if (selectedItems.length === 0) {
    return null;
  }

  return (
    <BulkSalesCommissionAction
      selectedItems={selectedItems.length}
      isOpen={isOpen}
      open={open}
      close={close}
      statusOptions={statusOptions}
      isSaving={isSaving}
      date={date}
      onDateChanged={onDateChange}
      onSave={onSave}
      status={status}
    />
  );
};

const Gate = () => {
  return (
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.INTERNAL_USE.MODIFY_COMMISSIONS}
    >
      <BulkSalesCommissionActionContainer />
    </PermissionGates.Has>
  );
};

export default Gate;
