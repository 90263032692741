import React, { ChangeEvent } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import Button from 'kingpin/atoms/Button';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import colors from '../../theme/colors';
import isWeatherBoard from '../../screens/WallBoard/isWeatherBoard';
import AddSlideButton from './AddSlideButton';
import { TOP_BAR_HEIGHT } from '../../constants';
import TextInput from '../../kingpin/atoms/TextInput';
import Card from '../Common/Card';
import Colors2 from '../../theme/Colors2';
import Icon from '../../kingpin/atoms/Icon';
import Tooltip from '../Tooltip';
import Row from '../Common/Row';

const Item = styled(Card)<{ noBorder?: boolean; isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: pointer;
  height: 48px;
  padding: 16px 8px;
  background-color: white;
  border: ${(props) =>
    props.noBorder ? 'unset' : `1px solid ${Colors2.Border}`};

  ${(props) =>
    props.isSelected &&
    `
    border: 1px solid ${colors.NAVY_BLUE};  
  `}

  &:hover {
    background-color: #ededed;
  }
`;

const Wrapper = styled.div`
  padding: 16px 24px;
  width: 300px;
  min-width: 300px;
  background-color: white;
  border-right: 1px solid ${Colors2.Border};
  overflow-y: auto;
  height: calc(100vh - ${TOP_BAR_HEIGHT}px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SlidesLayout = ({
  wallBoardDashboards,
  onWallBoardDashboardRemoved,
  onDragEnd,
  previewDashboard,
  setPreviewDashboard,
}: {
  wallBoardDashboards: (PersistedDashboardType | WeatherBoardType)[];
  onWallBoardDashboardRemoved: (
    wbd: PersistedDashboardType | WeatherBoardType,
  ) => void;
  onDragEnd: (result: DropResult) => void;
  previewDashboard?: PersistedDashboardType | WeatherBoardType;
  setPreviewDashboard: React.Dispatch<
    React.SetStateAction<PersistedDashboardType | WeatherBoardType | undefined>
  >;
}) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId={'quick-filters-drop'}>
      {(provided) => (
        <div ref={provided.innerRef}>
          {wallBoardDashboards.map((dash, index) => (
            <Draggable draggableId={dash.id} index={index} key={dash.id}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Item
                    data-testid={`${dash.id}-${
                      previewDashboard && previewDashboard.id === dash.id
                        ? 'selected'
                        : 'not selected'
                    }`}
                    isSelected={
                      previewDashboard && previewDashboard.id === dash.id
                    }
                    onClick={() => {
                      if (!isWeatherBoard(dash)) {
                        if (
                          previewDashboard &&
                          // @ts-ignore
                          previewDashboard.id === dash.id
                        ) {
                          setPreviewDashboard(undefined);
                        } else {
                          setPreviewDashboard(dash);
                        }
                      } else {
                        if (
                          previewDashboard &&
                          previewDashboard.id === dash.id
                        ) {
                          setPreviewDashboard(undefined);
                        } else {
                          setPreviewDashboard(dash);
                        }
                      }
                    }}
                  >
                    <Typography.Body type="Label" isEllipsis>
                      {dash.name}
                    </Typography.Body>
                    <div style={{ pointerEvents: 'none' }}>
                      <div style={{ pointerEvents: 'auto' }}>
                        <Button
                          type="Secondary"
                          size="Small"
                          onClick={() => onWallBoardDashboardRemoved(dash)}
                          icon={'cross'}
                        />
                      </div>
                    </div>
                  </Item>
                </div>
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

const ZoomExplanation = () => (
  <div>
    <div style={{ marginBottom: 8 }}>
      <Typography.Body type="Body 14" color="white">
        You will NOT see changes in the preview on your right.
      </Typography.Body>
    </div>
    <div style={{ marginBottom: 8 }}>
      <Typography.Body type="Body 14" color="white">
        To see changes, please load the wallboard on the display screen and then
        modify this setting.
      </Typography.Body>
    </div>
    <Typography.Body type="Body 14" color="white">
      You will see the changes instantly on the display screen.
    </Typography.Body>
  </div>
);

const WallBoardForm = ({
  name,
  onNameChanged,
  intervalTimeSeconds,
  onIntervalTimeChanged,
  wallBoardDashboards,
  onWallBoardDashboardRemoved,
  onAddWeatherBoard,
  weatherMapZoomOptions,
  dashboardZoomOptions,
  onDragEnd,
  enterWallBoardMode,
  onGenerateAccessCodeClicked,
  previewDashboard,
  setPreviewDashboard,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  intervalTimeSeconds: string;
  onIntervalTimeChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  wallBoardDashboards: (PersistedDashboardType | WeatherBoardType)[];
  onWallBoardDashboardRemoved: (
    wbd: PersistedDashboardType | WeatherBoardType,
  ) => void;
  onAddWeatherBoard: () => void;
  weatherMapZoomOptions: DropdownOption[];
  dashboardZoomOptions: DropdownOption[];
  onDragEnd: (result: DropResult) => void;
  enterWallBoardMode: () => void;
  onGenerateAccessCodeClicked: () => void;
  previewDashboard?: PersistedDashboardType | WeatherBoardType;
  setPreviewDashboard: React.Dispatch<
    React.SetStateAction<PersistedDashboardType | WeatherBoardType | undefined>
  >;
}) => (
  <Wrapper>
    <div>
      <div style={{ marginBottom: 16 }}>
        <TextInput
          label="Name (max. 40 characters)"
          value={name}
          onChange={onNameChanged}
          maxLength={40}
          width="100%"
          inputSize="Small"
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <TextInput
          value={intervalTimeSeconds}
          onChange={onIntervalTimeChanged}
          label={'Interval (seconds)'}
          type="number"
          width="100%"
          inputSize="Small"
        />
      </div>
      {!!weatherMapZoomOptions && weatherMapZoomOptions.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">Weather Map Zoom</Typography.Body>
          </div>
          <Inputs.Dropdown options={weatherMapZoomOptions} />
        </div>
      )}
      <div style={{ marginBottom: 16 }}>
        <Row style={{ marginBottom: 8 }} centerAlign>
          <div style={{ marginRight: 4 }}>
            <Typography.Body type="Label">Dashboard Zoom</Typography.Body>
          </div>
          <Tooltip content={<ZoomExplanation />}>
            <Icon icon="info" color={Colors2.Grey['3']} />
          </Tooltip>
        </Row>
        <Inputs.Dropdown options={dashboardZoomOptions} />
      </div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <div style={{ marginBottom: 8 }}>
          <Typography.Body type="Label">Slides</Typography.Body>
        </div>
        <SlidesLayout
          onDragEnd={onDragEnd}
          onWallBoardDashboardRemoved={onWallBoardDashboardRemoved}
          setPreviewDashboard={setPreviewDashboard}
          wallBoardDashboards={wallBoardDashboards}
          previewDashboard={previewDashboard}
        />
      </div>

      <AddSlideButton
        onAddWeatherBoard={onAddWeatherBoard}
        setPreviewDashboard={setPreviewDashboard}
      />
    </div>

    <div>
      <Button
        onClick={onGenerateAccessCodeClicked}
        type="Secondary"
        size="Small"
        label="Generate Access Code"
      />
      <div style={{ marginBottom: 8 }} />
      <Button
        onClick={enterWallBoardMode}
        label="Preview Wall Board"
        type="Secondary"
        size="Small"
      />
    </div>
  </Wrapper>
);

export default WallBoardForm;
