import usePopup from '../hooks/usePopup';
import React, { useCallback, useEffect, useState } from 'react';
import PopupContext from '../contexts/PopupContext';
import { toFilterPlate } from '../migrateScope';
import PopupReport from '../components/PopupReport';
import PopupBoard from '../components/PopupBoard';
import { useNavigate } from 'react-router-dom';
import PopupInnerContext from '../contexts/PopupInnerContext';
import PeriodsContext from '../contexts/PeriodsContext';

const PopupProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isOpen, open, close: closePopup } = usePopup();
  const navigate = useNavigate();
  const [selectedReport, setSelectedReport] = useState<
    PersistedReportType | undefined
  >();
  const [selectedBoard, setSelectedBoard] = useState<
    PerformanceBoardTypes.Board | Board | undefined
  >();
  const [dashboardGadget, setDashboardGadget] = useState<
    DashboardGadget | undefined
  >();
  const [drillDowns, setDrillDowns] = useState<ReportDrillDownType[]>([]);
  const [unSavedFilter, setUnSavedFilter] = useState<
    UnSavedFilter | undefined
  >();

  const [runTimeFilters, setRunTimeFilters] = useState<FilterPlate[]>([]);
  const [scopeFilters, setScopeFilters] = useState<FilterPlate[]>([]);
  const [runTimeDateRange, setRunTimeDateRange] = useState<
    DateRangeInput | undefined
  >();
  const [bonusPeriodId, setBonusPeriodId] = useState<string>('current');
  const [gadgetWithDrillDown, setGadgetWithDrillDown] = useState<
    DashboardGadget | undefined
  >();
  const [selectedScorecardPeriod, setSelectedScorecardPeriod] = useState<
    Period | undefined
  >();
  const [availableScorecardPeriods, setAvailableScorecardPeriods] = useState<
    Period[]
  >([]);
  const [poppedUpKpi, setPoppedUpKpi] = useState<
    Scorecards.ScorecardKpi | Scorecards.ManualKpiRow | undefined
  >();
  const [origin, setOrigin] = useState<
    'Dashboard' | 'Goal' | 'Scorecard' | undefined
  >();

  const close = useCallback(() => {
    // Remove query params
    const hasQueryParams = window.location.search.length > 0;
    if (hasQueryParams) {
      navigate(-1);
    }
    setOrigin(undefined);
    setUnSavedFilter(undefined);
    setPoppedUpKpi(undefined);
    setDrillDowns(window.emptyArray);
    closePopup();
  }, [closePopup, navigate]);

  const openPopupReport = useCallback(
    (
      d: ReportDrillDownType[],
      o: 'Dashboard' | 'Goal' | 'Scorecard',
      scorecardPeriodSettings?: {
        kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
        selectedPeriod: Period;
        availablePeriods: Period[];
      },
    ) => {
      setOrigin(o);
      setSelectedBoard(undefined);
      setDrillDowns(d);
      setAvailableScorecardPeriods(
        scorecardPeriodSettings ? scorecardPeriodSettings.availablePeriods : [],
      );
      setSelectedScorecardPeriod(
        scorecardPeriodSettings
          ? scorecardPeriodSettings.selectedPeriod
          : undefined,
      );
      setPoppedUpKpi(
        scorecardPeriodSettings ? scorecardPeriodSettings.kpi : undefined,
      );

      setTimeout(open, 500);
    },
    [open],
  );

  const openPopupBoard = useCallback(
    ({
      popupFilters,
      scope,
      popupDateRange,
      bonusPeriodId,
      o,
      scorecardPeriodSettings,
    }: {
      popupFilters: FilterPlate[];
      scope: FilterPlate[];
      popupDateRange?: DateRangeInput;
      bonusPeriodId?: string;
      o?: 'Dashboard' | 'Goal' | 'Scorecard';
      scorecardPeriodSettings?: {
        kpi: Scorecards.ScorecardKpi;
        selectedPeriod: Period;
        availablePeriods: Period[];
      };
    }) => {
      setOrigin(o);
      setSelectedReport(undefined);
      setRunTimeFilters(popupFilters);
      setScopeFilters(scope);
      if (bonusPeriodId) {
        setBonusPeriodId(bonusPeriodId);
        setRunTimeDateRange(undefined);
      } else if (popupDateRange) {
        setRunTimeDateRange(popupDateRange);
      } else {
        setRunTimeDateRange(undefined);
      }
      setAvailableScorecardPeriods(
        scorecardPeriodSettings ? scorecardPeriodSettings.availablePeriods : [],
      );
      setSelectedScorecardPeriod(
        scorecardPeriodSettings
          ? scorecardPeriodSettings.selectedPeriod
          : undefined,
      );
      setPoppedUpKpi(
        scorecardPeriodSettings ? scorecardPeriodSettings.kpi : undefined,
      );

      setTimeout(open, 500);
    },
    [open],
  );

  useEffect(() => {
    setGadgetWithDrillDown(
      dashboardGadget
        ? {
            ...dashboardGadget,
            drillDowns: [
              ...dashboardGadget.drillDowns,
              ...drillDowns.map(toFilterPlate),
            ],
          }
        : undefined,
    );
  }, [dashboardGadget, drillDowns]);

  return (
    <PopupContext.Provider
      value={{
        isOpen,
        openPopupReport,
        openPopupBoard,
        close,
        selectedReport,
        selectedBoard,
        setSelectedReport,
        setSelectedBoard,
        dashboardGadget,
        setDashboardGadget,
        setUnSavedFilter,
        origin,
        poppedUpKpi,
      }}
    >
      {children}
      <PopupInnerContext.Provider
        value={{
          isPopup: true,
        }}
      >
        <PeriodsContext.Provider
          value={{
            periods: availableScorecardPeriods,
            selectedPeriod: selectedScorecardPeriod,
            setSelectedPeriod: setSelectedScorecardPeriod,
          }}
        >
          <PopupReport
            isOpen={isOpen}
            report={selectedReport}
            close={close}
            parentDashboardGadget={gadgetWithDrillDown}
            unSavedFilter={unSavedFilter}
          />
          <PopupBoard
            isOpen={isOpen}
            board={selectedBoard}
            close={close}
            runTimeFilters={runTimeFilters}
            scopeFilters={scopeFilters}
            runTimeDateRange={runTimeDateRange}
            bonusPeriodId={bonusPeriodId}
          />
        </PeriodsContext.Provider>
      </PopupInnerContext.Provider>
    </PopupContext.Provider>
  );
};

export default PopupProvider;
