import firebase from 'firebase/compat/app';

const getCommitment = async (
  id: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  try {
    const commitment = await accountRef
      .collection('commitments')
      .doc(id)
      .get()
      .then((d) => d.data() as Commitment);

    return commitment;
  } catch (ex) {
    return undefined;
  }
};

export default getCommitment;
