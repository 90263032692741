import { ApisauceInstance } from 'apisauce';

const postBulkCommission = ({
  api,
  status,
  body,
}: {
  api: ApisauceInstance;
  status: 'paid' | 'due';
  body: { paidBy?: string; paidOn?: string; ids: string[] };
}) => api.patch(`/commission/bulk/${status}`, body);

export default postBulkCommission;
