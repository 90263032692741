import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Dropdown from 'components/Inputs/Dropdown';
import Row from 'components/Common/Row';
import Badge from 'components/Badge';
import UserIcon from 'components/Common/UserIcon';

import useUserOptions from './useUserOptions';

const UserMultiSelect = ({
  selectedUsers,
  setSelectedUsers,
  sharedWith,
  type,
  typeId,
}: {
  selectedUsers: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<
      (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
    >
  >;
  sharedWith: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  type: 'report' | 'dashboard';
  typeId: string;
}) => {
  const userOptions = useUserOptions({
    selectedUsers,
    setSelectedUsers,
    sharedWith,
    type,
    typeId,
  });

  return (
    <div style={{ flex: 1, marginRight: 16 }}>
      <Dropdown
        isSearchEnabled
        searchPlaceholder={'Search Users'}
        size="Medium"
        placeholder={'Select Users'}
        isMulti
        options={userOptions}
        renderSelectedLabel={() => (
          <>
            {selectedUsers.length === 0 && (
              <Typography.Body type={'Placeholder'}>
                Select Users
              </Typography.Body>
            )}
            <Row centerAlign>
              {selectedUsers.map((user) => (
                <div key={user.id} style={{ marginRight: 8 }}>
                  <Badge
                    text={user.displayName}
                    badgeType={'Default'}
                    iconBefore={
                      <div style={{ marginRight: 8, display: 'flex' }}>
                        <UserIcon {...user} noMargin isSmall />
                      </div>
                    }
                  />
                </div>
              ))}
            </Row>
          </>
        )}
      />
    </div>
  );
};

export default UserMultiSelect;
