import React, { useContext, useEffect, useState } from 'react';
import usePopup from '../hooks/usePopup';
import CommitmentQueryContext from '../contexts/CommitmentQueryContext';
import useCommitments from '../hooks/useCommitments';
import {
  DESTINATION_ZIP_CODE,
  ORIGIN_ZIP_CODE,
} from '../components/OmniSearch/constants';
import { GetContextMenuItemsParams } from 'ag-grid-community';
import getCommitment from '../api/commitments/getCommitment';
import AccountPickerContext from '../contexts/AccountPickerContext';
import { DATA_TYPES } from '../components/CustomerLaneCommitsBoard/AddNewCommitForm/Commit';
import { CUSTOMER_LANE_COMMITMENTS } from '../constants';

const DEFAULT_FILTER_INPUT = {
  keywords: [] as KeywordFilter[],
  booleanFilters: [] as BooleanInput[],
  ranges: [] as RangeInput[],
  wildcardFilters: [] as WildcardFilter[],
};

const CommitmentQueryProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { accountRef } = useContext(AccountPickerContext);

  // State
  const {
    isOpen: isPopupOpen,
    open: openPopup,
    close: closePopup,
  } = usePopup();
  const {
    isOpen: isSlideOutOpen,
    open: openSlideOut,
    close: closeSlideOut,
  } = usePopup();
  const commitConfigs = useCommitments(CUSTOMER_LANE_COMMITMENTS);

  const [mode, setMode] = useState<CommitmentFormPopupMode>('create');
  const [selectedCommitmentDoc, setSelectedCommitmentDoc] = useState<
    Commitment | undefined
  >();
  const [customerField, setCustomerField] = useState<
    'customer' | 'billTo' | undefined
  >();
  const [customerValue, setCustomerValue] = useState<string | undefined>();
  const [originField, setOriginField] = useState<OriginField | undefined>();
  const [originValue, setOriginValue] = useState<string>();
  const [destinationField, setDestinationField] = useState<
    DestinationField | undefined
  >();
  const [destinationValue, setDestinationValue] = useState<string>();
  const [commodity, setCommodity] = useState<string | undefined>();
  const [isQueryValid, setIsQueryValid] = useState<boolean>(false);
  const [queryFilterInput, setQueryFilterInput] =
    useState<FilterInput>(DEFAULT_FILTER_INPUT);
  const [selectedRateDoc, setSelectedRateDoc] = useState<RateDoc | undefined>();
  const [configForQuery, setConfigForQuery] = useState<
    Commitment | undefined
  >();
  const [isFlashVisible, setIsFlashVisible] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (
      undefined !== customerField &&
      !!customerValue &&
      '' !== customerValue &&
      // @ts-ignore
      !!originField &&
      // @ts-ignore
      '' !== originField &&
      !!originValue &&
      '' !== originValue &&
      // @ts-ignore
      !!destinationField &&
      // @ts-ignore
      '' !== destinationField &&
      !!destinationValue &&
      '' !== destinationValue
    ) {
      setIsQueryValid(true);
      const matchingConfig = commitConfigs.find(
        (c) =>
          c.customer === customerValue &&
          c.lane[
            originField as
              | 'startCityAndState'
              | 'originState'
              | 'pickUpArea'
              | 'originRegion'
              | 'originZipCode'
          ] === originValue &&
          c.lane[
            destinationField as
              | 'finishCityAndState'
              | 'destinationState'
              | 'destinationArea'
              | 'destinationRegion'
              | 'destinationZipCode'
          ] === destinationValue,
      );
      if (matchingConfig) {
        setConfigForQuery(matchingConfig);
      } else {
        setConfigForQuery(undefined);
      }
    } else {
      setIsQueryValid(false);
      setConfigForQuery(undefined);
    }
  }, [
    commitConfigs,
    customerField,
    customerValue,
    destinationField,
    destinationValue,
    isQueryValid,
    originField,
    originValue,
    setIsQueryValid,
  ]);

  useEffect(() => {
    const keywords = [] as KeywordFilter[];
    const wildCards = [] as WildcardFilter[];
    if (
      !!customerField &&
      // @ts-ignore
      !!customerField !== '' &&
      !!customerValue &&
      customerValue !== ''
    ) {
      keywords.push({ field: customerField, values: [customerValue] });
    }

    if (
      !!originField &&
      // @ts-ignore
      originField !== '' &&
      !!originValue &&
      originValue !== ''
    ) {
      if (
        originField === ORIGIN_ZIP_CODE &&
        originValue[originValue.length - 1] === '*'
      ) {
        wildCards.push({ field: originField, pattern: originValue });
      } else if (originField === ORIGIN_ZIP_CODE) {
        wildCards.push({
          field: originField,
          pattern: `${originValue}*`,
        });
      } else {
        keywords.push({ field: originField, values: [originValue] });
      }
    }

    if (
      !!destinationField &&
      // @ts-ignore
      destinationField !== '' &&
      !!destinationValue &&
      destinationValue !== ''
    ) {
      if (
        destinationField === DESTINATION_ZIP_CODE &&
        destinationValue[destinationValue.length - 1] === '*'
      ) {
        wildCards.push({ field: destinationField, pattern: destinationValue });
      } else if (destinationField === DESTINATION_ZIP_CODE) {
        wildCards.push({
          field: destinationField,
          pattern: `${destinationValue}*`,
        });
      } else {
        keywords.push({ field: destinationField, values: [destinationValue] });
      }
    }

    if (commodity) {
      keywords.push({ field: 'commodity', values: [commodity] });
    }

    const newFilterInput = {
      keywords,
      ranges: [] as RangeInput[],
      wildcardFilters: wildCards,
      booleanFilters: [] as BooleanInput[],
      dataType: DATA_TYPES,
    } as FilterInput;
    setQueryFilterInput(newFilterInput);
  }, [
    commodity,
    customerField,
    customerValue,
    destinationField,
    destinationValue,
    originField,
    originValue,
  ]);

  // Functions
  const clearState = () => {
    setSelectedRateDoc(undefined);
    setSelectedCommitmentDoc(undefined);
    setCustomerField(undefined);
    setCustomerValue(undefined);
    setOriginValue(undefined);
    setOriginField(undefined);
    setDestinationValue(undefined);
    setDestinationField(undefined);
    setCommodity(undefined);
    setQueryFilterInput(DEFAULT_FILTER_INPUT);
    setIsQueryValid(false);
    setMode('create');
  };

  const fillValuesFrom = (
    commit: Commitment,
    mode: CommitmentFormPopupMode,
  ) => {
    const { lane, customer, commodity, billTo } = commit;
    setMode(mode);
    setCustomerValue(billTo ? billTo : customer);
    setCustomerField(billTo ? 'billTo' : 'customer');
    setCommodity(commodity);

    const { originField, originValue } = (() => {
      if (lane.startCityAndState) {
        return {
          originField: 'startCityAndState',
          originValue: lane.startCityAndState,
        };
      } else if (lane.originZipCode) {
        return {
          originField: 'originZipCode',
          originValue: lane.originZipCode,
        };
      } else if (lane.originState) {
        return {
          originField: 'originState',
          originValue: lane.originState,
        };
      } else if (lane.pickUpArea) {
        return {
          originField: 'pickUpArea',
          originValue: lane.pickUpArea,
        };
      } else if (lane.originRegion) {
        return {
          originField: 'originRegion',
          originValue: lane.originRegion,
        };
      } else {
        return {
          originField: undefined,
          originValue: undefined,
        };
      }
    })();
    setOriginField(originField as OriginField | undefined);
    setOriginValue(originValue);

    const { destinationField, destinationValue } = (() => {
      if (lane.finishCityAndState) {
        return {
          destinationField: 'finishCityAndState',
          destinationValue: lane.finishCityAndState,
        };
      } else if (lane.destinationZipCode) {
        return {
          destinationField: 'destinationZipCode',
          destinationValue: lane.destinationZipCode,
        };
      } else if (lane.destinationState) {
        return {
          destinationField: 'destinationState',
          destinationValue: lane.destinationState,
        };
      } else if (lane.destinationArea) {
        return {
          destinationField: 'destinationArea',
          destinationValue: lane.destinationArea,
        };
      } else if (lane.destinationRegion) {
        return {
          destinationField: 'destinationRegion',
          destinationValue: lane.destinationRegion,
        };
      } else {
        return {
          destinationField: undefined,
          destinationValue: undefined,
        };
      }
    })();
    setDestinationField(destinationField as DestinationField | undefined);
    setDestinationValue(destinationValue);

    setSelectedCommitmentDoc(commit);
    openPopup();
    openSlideOut();
  };

  const getContextMenuItems = (params: GetContextMenuItemsParams) => [
    {
      name: 'Renew',
      action: () => {
        if (!params.node) {
          return;
        }
        const { configId } = params.node.data;
        getCommitment(configId, accountRef).then((commitment) => {
          if (!commitment) {
            alert('Commitment not found');
          } else {
            fillValuesFrom(commitment, 'renew');
          }
        });
      },
    },
    {
      name: 'Modify',
      action: () => {
        if (!params.node) {
          return;
        }
        const { configId } = params.node.data;
        getCommitment(configId, accountRef).then((commitment) => {
          if (!commitment) {
            alert('Commitment not found');
          } else {
            fillValuesFrom(commitment, 'modify');
          }
        });
      },
    },
    {
      name: 'End',
      action: () => {
        if (!params.node) {
          return;
        }
        const { configId } = params.node.data;
        getCommitment(configId, accountRef).then((commitment) => {
          setSelectedCommitmentDoc(commitment);
          setMode('end');
        });
      },
    },
    {
      name: 'Delete',
      action: () => {
        if (!params.node) {
          return;
        }
        const { configId } = params.node.data;
        getCommitment(configId, accountRef).then((commitment) => {
          setSelectedCommitmentDoc(commitment);
          setMode('delete');
        });
      },
    },
  ];

  return (
    <CommitmentQueryContext.Provider
      value={{
        selectedCommitmentDoc,
        setSelectedCommitmentDoc,
        mode,
        setMode,
        customerField,
        setCustomerField,
        customerValue,
        setCustomerValue,
        originField,
        setOriginField,
        originValue,
        setOriginValue,
        destinationField,
        setDestinationField,
        destinationValue,
        setDestinationValue,
        commodity,
        setCommodity,
        selectedRateDoc,
        setSelectedRateDoc,
        isQueryValid,
        queryFilterInput,
        isPopupOpen,
        openPopup,
        closePopup: () => {
          clearState();
          closeSlideOut();
          closePopup();
        },
        isSlideOutOpen,
        openSlideOut,
        closeSlideOut: () => {
          closeSlideOut();
          clearState();
          setSelectedRateDoc(undefined);
        },
        clearState,
        configForQuery,
        isFlashVisible,
        setIsFlashVisible,
        getContextMenuItems,
      }}
    >
      {children}
    </CommitmentQueryContext.Provider>
  );
};

export default CommitmentQueryProvider;
