import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import reloadWhenSafe from 'reloadWhenSafe';
import DashboardsContext from 'contexts/DashboardsContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import WallboardContext from 'contexts/WallboardContext';
import WallboardsContext from 'contexts/WallboardsContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import GqlClientProvider from 'contextProviders/GqlClientProvider';
import FlexCentered from 'components/Common/FlexCentered';
import Loading from 'components/Loading';
import { WEATHER_BOARD_ID } from 'components/WallBoardForm';
import { ScreenWrapper } from 'navigation/styles';
import useHideIntercom from 'hooks/useHideIntercom';

import WallBoard from './WallBoard';
import useIsFireTv from '../../hooks/useIsFireTv';

const WallBoardContainer = () => {
  const { wallBoardId } = useParams<{ wallBoardId: string }>();
  const currentUser = useContext(CurrentUserContext);
  const { wallBoards, isLoading } = useContext(WallboardsContext);
  const [selectedWallBoard, setSelectedWallBoard] = useState<Wallboard>();
  const { allDashboards } = useContext(DashboardsContext);
  const isFireTv = useIsFireTv();
  const [dashboards, setDashboards] = useState<
    (PersistedDashboardType | WeatherBoardType)[]
  >([]);
  const [dashboard, setDashboard] = useState<
    PersistedDashboardType | WeatherBoardType | undefined
  >();
  const [index, setIndex] = useState<number>(1);
  const [isCloseVisible, setIsCloseVisible] = useState<boolean>(true);
  const [isWallboardUser, setIsWallboardUser] = useState<boolean>(true);
  const { trackEvent } = useContext(AnalyticsContext);

  useEffect(() => {
    trackEvent('Wallboard - Started', {
      wallBoardId: wallBoardId,
    });

    return () => {
      trackEvent('Wallboard - Stopped', {
        wallBoardId: wallBoardId,
      });
    };
  }, [wallBoardId, trackEvent]);

  useEffect(() => {
    if (currentUser) {
      setIsWallboardUser(!!currentUser.wallBoardId);
    }
  }, [currentUser]);

  useHideIntercom();

  useEffect(() => {
    if (!selectedWallBoard) {
      return;
    }

    const { wallBoardDashboardIds } = selectedWallBoard;
    const newDashboards = wallBoardDashboardIds
      .map((id) => {
        if (id === WEATHER_BOARD_ID) {
          return { id: WEATHER_BOARD_ID, name: 'Weather' };
        } else {
          return allDashboards.find((wbd) => wbd.id === id);
        }
      })
      .filter((wbd) => !!wbd) as (PersistedReportType | WeatherBoardType)[];
    setDashboards(newDashboards);
    if (newDashboards.length === 0) {
      setDashboard(undefined);
    } else {
      setDashboard(newDashboards[0]);
    }
  }, [selectedWallBoard, allDashboards]);

  const moveToNextSlide = useCallback(() => {
    const nextItem = dashboards[index % dashboards.length];
    setDashboard(nextItem);
    setIndex(index + 1);
  }, [dashboards, index]);

  useEffect(() => {
    if (!selectedWallBoard) {
      return;
    }

    const { intervalTimeSeconds } = selectedWallBoard;
    const interval = setInterval(moveToNextSlide, intervalTimeSeconds * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dashboards, index, moveToNextSlide, selectedWallBoard]);

  useEffect(() => {
    const refresh = setTimeout(
      () => {
        reloadWhenSafe();
      },
      1000 * 60 * 5,
    );
    // Reload every 5 minutes to prevent memory leaks crashing low RAM TV displays
    return () => {
      clearTimeout(refresh);
    };
  }, []);

  useEffect(() => {
    window.onkeypress = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        moveToNextSlide();
      }
    };
  }, [moveToNextSlide]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setSelectedWallBoard(wallBoards.find((w) => w.id === wallBoardId));
  }, [isLoading, wallBoardId, wallBoards]);

  useEffect(() => {
    window.addEventListener('mousemove', () => {
      if (!isCloseVisible) {
        setIsCloseVisible(true);
      }
    });
  }, [isCloseVisible]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCloseVisible(false);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isCloseVisible]);

  if (isLoading || !selectedWallBoard) {
    return (
      <ScreenWrapper>
        <FlexCentered>
          <Loading />
        </FlexCentered>
      </ScreenWrapper>
    );
  }

  return (
    <WallboardContext.Provider
      value={{
        wallboard: selectedWallBoard,
        isWallboard: !!selectedWallBoard,
        weatherMapZoom: selectedWallBoard.weatherMapZoom,
        dashboardZoom: selectedWallBoard.dashboardZoom
          ? selectedWallBoard.dashboardZoom
          : isFireTv
            ? 1
            : 2,
      }}
    >
      <GqlClientProvider>
        <ScreenWrapper isFullWidth>
          <WallBoard
            dashboard={dashboard}
            isCloseVisible={isCloseVisible}
            isWallboardUser={isWallboardUser}
          />
        </ScreenWrapper>
      </GqlClientProvider>
    </WallboardContext.Provider>
  );
};

export default WallBoardContainer;
