import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import tokenVideoSrcMp4 from './tokenVideoMp4.mp4';
import tokenVideoSrcWebm from './tokenVideoWebm.webm';
import FleetOpsImage from '../../images/fleetops-side.svg';
import Dashboard from '../../components/Dashboard';
import { showIntercomButton } from '../../openIntercom';
import { WEATHER_BOARD_ID } from '../../components/WallBoardForm';
import WeatherBoard from '../../components/WeatherBoard';
import Row from '../../components/Common/Row';
import isWeatherBoard from './isWeatherBoard';
import firebase from 'firebase/compat/app';
import Button from '../../kingpin/atoms/Button';
import WallboardContext from '../../contexts/WallboardContext';
import Typography from 'kingpin/atoms/Typography';

const ButtonPosition = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 999999999999;
`;

const PoweredByFleetOps = styled.div`
  position: absolute;
  bottom: 16px;
  right: 28px;
  z-index: 999999999999;
`;

const Logo = styled.img`
  height: 21px;
  margin-right: 6px;
`;

const WallboardWrapper = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

const WallboardDashboard = ({
  dashboard,
}: {
  dashboard: PersistedDashboardType | WeatherBoardType;
}) => {
  const { dashboardZoom } = useContext(WallboardContext);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        zoom: isWeatherBoard(dashboard) ? 1 : dashboardZoom,
      }}
    >
      <WallboardWrapper>
        <Row style={{ justifyContent: 'flex-end' }}>
          <video width="2px" height="2px" autoPlay muted loop>
            <source src={tokenVideoSrcWebm} type="video/webm" />
            <source src={tokenVideoSrcMp4} type="video/mp4" />
          </video>
        </Row>
        {isWeatherBoard(dashboard) && <WeatherBoard key={WEATHER_BOARD_ID} />}
        {!isWeatherBoard(dashboard) && (
          <Dashboard
            dashboard={dashboard}
            // @ts-ignore
            key={dashboard.id}
          />
        )}
      </WallboardWrapper>
    </div>
  );
};

const WallBoard = ({
  dashboard,
  isCloseVisible,
  isWallboardUser,
}: {
  dashboard?: PersistedDashboardType | WeatherBoardType;
  isCloseVisible: boolean;
  isWallboardUser: boolean;
}) => (
  <React.Fragment>
    {!isWallboardUser && isCloseVisible && <CloseButton />}
    {isWallboardUser && isCloseVisible && <SignOutButton />}
    {dashboard && (
      <WallboardDashboard dashboard={dashboard} key={dashboard.id} />
    )}
    <PoweredByFleetOps>
      <Row centerAlign>
        <Logo src={FleetOpsImage} />
        <Typography.Body type="Body 14">Powered By FleetOps</Typography.Body>
      </Row>
    </PoweredByFleetOps>
  </React.Fragment>
);

const CloseButton = () => {
  const navigate = useNavigate();
  const onClick = () => {
    showIntercomButton();
    navigate(-1);
  };

  return (
    <ButtonPosition>
      <Button size={'Medium'} type={'Primary'} onClick={onClick} icon="cross" />
    </ButtonPosition>
  );
};

const SignOutButton = () => {
  const onClick = () => {
    if (
      window.confirm(
        'You will need to get a new access token to set up this wallboard' +
          ' again. Are you sure?',
      )
    ) {
      firebase.auth().signOut();
    }
  };

  return (
    <ButtonPosition>
      <Button size={'Medium'} type={'Primary'} onClick={onClick} icon="cross" />
    </ButtonPosition>
  );
};

export default WallBoard;
