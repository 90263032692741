import { Route, Routes } from 'react-router-dom';
import React, { lazy } from 'react';
import appRoutes from './appRoutes';

const DashboardsIndex = lazy(() => import('../screens/Dashboards'));
const DashboardShow = lazy(() => import('../screens/DashboardShow'));
const ReportsIndex = lazy(() => import('../screens/Reports'));
const ReportShow = lazy(() => import('../screens/ReportShow'));
const ScorecardsIndex = lazy(() => import('../screens/ScorecardsIndex'));
const ScorecardShow = lazy(() => import('../screens/ScorecardShow'));
const TargetsAppShow = lazy(() => import('../screens/TargetsAppShow'));

const ExecutivePortalSwitch = () => {
  return (
    <Routes>
      <Route
        path={appRoutes.loggedIn.dashboards}
        element={<DashboardsIndex />}
      />
      <Route
        path={appRoutes.loggedIn.showDashboard}
        element={<DashboardShow />}
      />
      <Route path={appRoutes.loggedIn.reports} element={<ReportsIndex />} />
      <Route path={appRoutes.loggedIn.showReport} element={<ReportShow />} />
      <Route path={appRoutes.loggedIn.kpiLists} element={<ScorecardsIndex />} />
      <Route
        path={appRoutes.loggedIn.showTargetApp}
        element={<TargetsAppShow />}
      />
      <Route
        path={appRoutes.loggedIn.showGlobalKpiList}
        element={<ScorecardShow />}
      />
    </Routes>
  );
};

export default ExecutivePortalSwitch;
