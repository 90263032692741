import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import Commit from './Commit';
import useFilterValues from '../../../../hooks/useFilterValues';
import getGridRows from '../../../../api/getGridRows';
import GqlClientContext from '../../../../contexts/GqlClientContext';
import useDateScope from '../../../../hooks/useDateScope';

export const DATA_TYPES = ['rates', 'jobs', 'legs', 'orders'];

export const getCustomer = (commit: Commitment) => {
  const { billTo, customer } = commit;
  if (customer) {
    return {
      field: 'customer',
      value: customer,
    };
  }
  if (billTo) {
    return {
      field: 'billTo',
      value: billTo,
    };
  }

  throw new Error('Undefined');
};

export const getOrigin = (commit: Commitment) => {
  const { lane } = commit;
  if (lane.startCityAndState) {
    return {
      field: 'startCityAndState',
      value: lane.startCityAndState,
    };
  }

  if (lane.originZipCode) {
    return {
      field: 'originZipCode',
      value: lane.originZipCode,
    };
  }

  if (lane.originState) {
    return {
      field: 'originState',
      value: lane.originState,
    };
  }

  if (lane.pickUpArea) {
    return {
      field: 'pickUpArea',
      value: lane.pickUpArea,
    };
  }

  if (lane.originRegion) {
    return {
      field: 'originRegion',
      value: lane.originRegion,
    };
  }

  return {
    field: undefined,
    value: undefined,
  };
};

export const getDestination = (commit: Commitment) => {
  const { lane } = commit;
  if (lane.finishCityAndState) {
    return {
      field: 'finishCityAndState',
      value: lane.finishCityAndState,
    };
  }

  if (lane.destinationZipCode) {
    return {
      field: 'destinationZipCode',
      value: lane.destinationZipCode,
    };
  }

  if (lane.destinationState) {
    return {
      field: 'destinationState',
      value: lane.destinationState,
    };
  }

  if (lane.destinationArea) {
    return {
      field: 'destinationArea',
      value: lane.destinationArea,
    };
  }

  if (lane.destinationRegion) {
    return {
      field: 'destinationRegion',
      value: lane.destinationRegion,
    };
  }

  return {
    field: undefined,
    value: undefined,
  };
};

export const useDataType = () => {
  const [dataType, setDataType] = useState<string>('jobs');
  const dateScope = useDateScope({});
  const { values: dataTypes } = useFilterValues({
    field: 'dataType',
    value: '',
    dateScope,
  });

  useEffect(() => {
    const hasDataRatesData = !!dataTypes.find((d) => {
      return d === 'rates';
    });
    if (hasDataRatesData) {
      setDataType('rates');
    }
  }, [dataTypes]);

  return {
    dataType,
  };
};

const CommitContainer = ({
  commit,
  setCommit,
  originFields,
  destinationFields,
  deleteCommit,
}: {
  commit: Commitment;
  setCommit: (c: Commitment) => void;
  originFields: string[];
  destinationFields: string[];
  deleteCommit: (c: Commitment) => void;
}) => {
  // State
  const {
    loads,
    cadence,
    commodity,
    startDate,
    endDate,
    rate,
    mileage,
    lineHaulCharge,
    isDraft,
  } = commit;

  const { client } = useContext(GqlClientContext);
  const dateScope = useDateScope({});

  const [cadenceOptions, setCadenceOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setCadenceOptions(
      ['weekly', 'monthly', 'quarterly', 'yearly'].map((value) => ({
        label: value,
        isSelected: value === cadence,
        onSelected: () => {
          const newCommit = {
            ...commit,
            cadence: value as 'weekly' | 'monthly' | 'quarterly' | 'yearly',
          };
          setCommit(newCommit);
        },
      })),
    );
  }, [cadence, commit, setCommit]);

  const [origin, setOrigin] = useState<string | undefined>(
    getOrigin(commit).value,
  );
  const [originField, setOriginField] = useState<string | undefined>(
    getOrigin(commit).field as string | undefined,
  );
  const [destination, setDestination] = useState<string | undefined>(
    getDestination(commit).value,
  );
  const [destinationField, setDestinationField] = useState<string | undefined>(
    getDestination(commit).field as string | undefined,
  );
  const { dataType } = useDataType();
  const hasRatesData = dataType === 'rates';

  const customer = getCustomer(commit).value;
  const customerField = getCustomer(commit).field;

  // Effects
  useEffect(() => {
    if (destination && destinationField && originField && origin) {
      const newLane = {};
      // @ts-ignore
      newLane[destinationField] = destination;
      // @ts-ignore
      newLane[originField] = origin;

      const newCommit = {
        ...commit,
        lane: newLane,
      };

      if (!_.isEqual(newCommit, commit)) {
        setCommit(newCommit);
      }
    }
  }, [commit, destination, destinationField, origin, originField, setCommit]);

  // Interactions
  const onCommitsChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newCommit = {
      ...commit,
      loads: Number.parseInt(event.target.value),
    };
    setCommit(newCommit);
  };

  const onStartDateChanged = (date: string) => {
    if (date === '') {
      const newCommit = {
        ...commit,
      };
      delete newCommit['startDate'];
      setCommit(newCommit);
    } else {
      const newCommit = {
        ...commit,
        startDate: date,
      };
      setCommit(newCommit);
    }
  };

  const onEndDateChanged = (date: string) => {
    if (date === '') {
      const newCommit = {
        ...commit,
      };
      delete newCommit['endDate'];
      setCommit(newCommit);
    } else {
      const newCommit = {
        ...commit,
        endDate: date,
      };
      setCommit(newCommit);
    }
  };

  const onRateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newCommit = {
      ...commit,
      rate: Number.parseInt(event.target.value),
    } as Commitment;
    setCommit(newCommit);
  };

  const onMileageChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newCommit = {
      ...commit,
      mileage: Number.parseInt(event.target.value),
    } as Commitment;
    setCommit(newCommit);
  };

  const onLineHaulChargeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newCommit = {
      ...commit,
      lineHaulCharge: Number.parseInt(event.target.value),
    } as Commitment;
    setCommit(newCommit);
  };

  const onCommodityChanged = (comm: string | undefined) => {
    if (
      origin &&
      originField &&
      destination &&
      destinationField &&
      hasRatesData &&
      comm &&
      isDraft &&
      !rate
    ) {
      const keywords = [] as KeywordFilter[];
      keywords.push({ field: originField, values: [origin] });
      keywords.push({ field: destinationField, values: [destination] });
      keywords.push({ field: 'commodity', values: [comm] });
      const filterInput = {
        dataType: DATA_TYPES,
        keywords,
        ranges: [],
        booleanFilters: [],
        wildcardFilters: [],
      };

      getGridRows({
        query: 'fetchRecords',
        from: 0,
        limit: 10,
        sortBy: [],
        dataType,
        filters: [filterInput],
        client,
        dateScope,
      }).then((rows) => {
        if (rows.length > 0) {
          const effectiveStart = rows[0].effectiveStartDate;
          const effectiveEnd = rows[0].effectiveEndDate;
          const newCommit = {
            ...commit,
            commodity: comm,
          };
          if (effectiveStart) {
            newCommit.startDate = effectiveStart;
          }
          if (effectiveEnd) {
            newCommit.endDate = effectiveStart;
          }
          if (rows[0].rate) {
            newCommit.rate = rows[0].rate;
          }

          setCommit(newCommit);
        } else {
          setCommit({ ...commit, commodity: comm });
        }
      });
    } else {
      const newCommit = {
        ...commit,
        commodity: comm,
      } as Commitment;
      setCommit(newCommit);
    }
  };

  const onDelete = () => {
    deleteCommit(commit);
  };

  return (
    <Commit
      originField={originField}
      origin={origin}
      originFields={originFields}
      setOriginField={setOriginField}
      setOrigin={setOrigin}
      destinationField={destinationField}
      destination={destination}
      destinationFields={destinationFields}
      setDestinationField={setDestinationField}
      setDestination={setDestination}
      commits={loads}
      onCommitsChanged={onCommitsChanged}
      cadenceOptions={cadenceOptions}
      startDate={startDate}
      onStartDateChanged={onStartDateChanged}
      endDate={endDate}
      onEndDateChanged={onEndDateChanged}
      onDelete={onDelete}
      customer={customer}
      customerField={customerField}
      dataType={DATA_TYPES}
      commodity={commodity}
      setCommodity={onCommodityChanged}
      rate={rate}
      onRateChanged={onRateChanged}
      mileage={mileage}
      onMileageChanged={onMileageChanged}
      lineHaulCharge={lineHaulCharge}
      onLineHaulChargeChanged={onLineHaulChargeChanged}
      isSaved={isDraft ? false : true}
    />
  );
};

export default CommitContainer;
