import { useCallback, useContext, useMemo, useState } from 'react';
import STORE from '../../../store';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import usePopup from '../../../hooks/usePopup';
import RemovePortalAccessConfirmation from './RemovePortalAccessConfirmation';

const useRemovePortalAccessConfirmation = ({
  portal,
  type,
  typeId,
  contentName,
}: {
  portal: ExecutivePortal;
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  contentName: string;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isOpen, open, close } = usePopup();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onRemoveConfirmed = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const newPortal: ExecutivePortal = {
      ...portal,
    };
    if (type === 'report') {
      newPortal.reportIds = newPortal.reportIds.filter((rId) => rId !== typeId);
    }
    if (type === 'dashboard') {
      newPortal.dashboardIds = newPortal.dashboardIds.filter(
        (rId) => rId !== typeId,
      );
    }
    if (type === 'targetApp') {
      newPortal.targetAppIds = newPortal.targetAppIds.filter(
        (rId) => rId !== typeId,
      );
    }
    if (type === 'scorecard') {
      newPortal.scorecardIds = newPortal.scorecardIds.filter(
        (rId) => rId !== typeId,
      );
    }

    await STORE.contentDistributions
      .getExecutivePortalsRef({ accountId: selectedAccountId })
      .doc(newPortal.id)
      .set(newPortal);
    setIsLoading(false);
  }, [isLoading, portal, selectedAccountId, type, typeId]);

  const RemoveConfirmation = useMemo(
    () => (
      <RemovePortalAccessConfirmation
        isOpen={isOpen}
        close={close}
        portal={portal}
        onRemoveConfirmed={onRemoveConfirmed}
        isLoading={isLoading}
        type={type}
        contentName={contentName}
      />
    ),
    [close, contentName, isLoading, isOpen, onRemoveConfirmed, portal, type],
  );

  return {
    RemoveConfirmation,
    onRemoveClicked: open,
  };
};

export default useRemovePortalAccessConfirmation;
