import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePopup from '../../../hooks/usePopup';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';
import DashboardMenu from './DashboardMenu';
import DashboardForm from '../../DashboardForm';
import captureException from '../../../services/captureException';
import IFrameForm from '../../IFrameForm';
import VideoCardForm from '../../VideoCardForm';
import TextCardForm from '../../TextCardForm';
import buildCardLayout from '../../../contextProviders/WidgetGalleryProvider/buildCardLayout';
import aguid from 'aguid';
import createMetricListGadget from '../../../api/metricListGadgets/createMetricListGadget';
import DashboardContext from '../../../contexts/DashboardContext';
import ConfigureVariables from '../ConfigureVariables';
import useTemplateInstances from '../../../hooks/dashboards/useTemplateInstances';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import findStartingY from '../../../contextProviders/WidgetGalleryProvider/findStartingY';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import WallboardContext from '../../../contexts/WallboardContext';
import ImageCardEditForm from '../../ImageGadget/ImageCardEditForm';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import AddScorecardForm from '../AddScorecardForm';
import cardTypeCheckers from '../../../types/cardTypeCheckers';
import { buildDashboardShow } from 'navigation/appRoutes';

const DashboardMenuContainer = ({
  startEditing,
  saveDashboardAs,
  createTemplateFrom,
  onDelete,
  setCurrentCanvas,
  canvasMode,
  isTemplate,
  openVariableFiltersSlideOut,
}: {
  startEditing: () => void;
  saveDashboardAs: (name: string) => Promise<void>;
  createTemplateFrom: () => Promise<void>;
  onDelete: () => void;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  canvasMode: CanvasMode;
  isTemplate: boolean;
  openVariableFiltersSlideOut: () => void;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { isWallboard } = useContext(WallboardContext);
  const { dashboard, templatedFrom } = useContext(DashboardContext);
  const [isCreatedByMe, setIsCreatedByMe] = useState<boolean>(
    !!dashboard && dashboard.createdBy === currentUserId,
  );
  const templateInstances = useTemplateInstances(dashboard);
  const navigate = useNavigate();
  const isDeletable =
    dashboard && dashboard.isTemplate ? templateInstances.length === 0 : true;
  const isTemplated = !!templatedFrom;
  const {
    isOpen: saveAsIsOpen,
    open: openSaveAs,
    close: closeSaveAs,
  } = usePopup(false);
  const {
    isOpen: isIFrameFormOpen,
    open: openIFrameForm,
    close: closeIFrameForm,
  } = usePopup(false);
  const {
    isOpen: isImageCardFormOpen,
    open: openImageCardForm,
    close: closeImageCardForm,
  } = usePopup(false);
  const {
    isOpen: isVideoCardFormOpen,
    open: openVideoCardForm,
    close: closeVideoCardForm,
  } = usePopup(false);
  const {
    isOpen: isTextCardFormOpen,
    open: openTextCardForm,
    close: closeTextCardForm,
  } = usePopup(false);
  const {
    isOpen: isSettingsOpen,
    open: openSettings,
    close: closeSettings,
  } = usePopup(false);
  const {
    isOpen: isConfigureVariablesOpen,
    open: openConfigureVariables,
    close: closeConfigureVariables,
  } = usePopup(false);
  const {
    isOpen: isAddScorecardFormOpen,
    open: openAddScorecardForm,
    close: closeAddScorecardForm,
  } = usePopup(false);

  const { open: openWGallery } = useContext(WidgetGalleryContext);
  const [isScorecardDashboard, setIsScorecardDashboard] =
    useState<boolean>(false);
  const [savingAs, setSavingAs] = useState(false);

  useEffect(() => {
    setIsCreatedByMe(!!dashboard && dashboard.createdBy === currentUserId);
  }, [currentUserId, dashboard]);

  useEffect(() => {
    setIsScorecardDashboard(
      !!dashboard && dashboard.canvas.cards.some(cardTypeCheckers.isScorecard),
    );
  }, [dashboard]);

  const onStartEditing = () => {
    startEditing();
  };

  const onAddScorecardClicked = useCallback(() => {
    openAddScorecardForm();
  }, [openAddScorecardForm]);

  const onSaveAsClicked = useCallback(() => {
    openSaveAs();
    trackEvent('Dashboard - Menu - Save As - Clicked');
  }, [openSaveAs, trackEvent]);

  if (!dashboard) {
    return null;
  }

  const onAddGadgetFromGalleryClicked = () => {
    trackEvent('Dashboard - Menu - Add gadget from gallery', {
      dashboardId: dashboard.id,
      dashboardName: dashboard.name,
      isWallboardSlide: isWallboard ? 'true' : 'false',
    });
    openWGallery();
  };

  const onCreateNewGadgetClicked = () => {
    trackEvent('Dashboard - Menu - Create new gadget', {
      dashboardId: dashboard.id,
      dashboardName: dashboard.name,
      isWallboardSlide: isWallboard ? 'true' : 'false',
    });
    openWGallery(true);
  };

  const onSaveAs = ({ name }: { name: string }) => {
    setSavingAs(true);
    saveDashboardAs(name).then(() => {
      setSavingAs(false);
      closeSaveAs();
    });
    trackEvent('Dashboard Saved As New');
  };

  const onFullscreenClicked = () => {
    try {
      const dash = document.getElementById('dashboard');
      if (dash) {
        dash.requestFullscreen();
      }
    } catch (ex) {
      alert('Something went wrong');
      captureException(ex);
    }
  };

  const onExitFullscreenClicked = () => {
    try {
      document.exitFullscreen();
    } catch (ex) {
      alert('Something went wrong');
      captureException(ex);
    }
  };

  const onMetricListAdded = async () => {
    const metricListId = aguid();
    await createMetricListGadget({
      id: metricListId,
      name: 'Metric List',
      currentUserId,
      accountId: selectedAccountId,
    });

    setCurrentCanvas((c) => {
      const newCard = (() => {
        return {
          layout: buildCardLayout(0, findStartingY(c.cards, 'desktop')),
          content: {
            type: 'Metric List' as 'Metric List',
            metricListId: metricListId,
          },
        };
      })();

      return {
        ...c,
        cards: [...c.cards, newCard],
      };
    });
    startEditing();
  };

  const onSettingsClicked = () => {
    openSettings();
  };

  const onConfigureVariables = () => {
    openConfigureVariables();
  };

  const onViewSourceTemplate = (templateId: string | undefined) => {
    if (!templateId) {
      const error = new Error();
      error.name = 'Dashboard Template: templateId is missing';
      throw error;
    }

    navigate(buildDashboardShow(templateId));
  };

  const isFullScreen = !!document.fullscreenElement;

  return (
    <React.Fragment>
      <DashboardMenu
        onStartEditing={onStartEditing}
        onCreateNewGadgetClicked={onCreateNewGadgetClicked}
        onAddGadgetFromGalleryClicked={onAddGadgetFromGalleryClicked}
        onSaveAs={onSaveAsClicked}
        createTemplateFrom={createTemplateFrom}
        onDelete={() => {
          const confirmed = window.confirm(
            `You are about to delete the ${dashboard.name} dashboard. Are you sure?`,
          );
          if (confirmed) {
            onDelete();
          }
        }}
        isFullScreen={isFullScreen}
        onFullscreenClicked={onFullscreenClicked}
        onExitFullscreenClicked={onExitFullscreenClicked}
        openIFrameForm={openIFrameForm}
        onImageCardAdded={openImageCardForm}
        openVideoCardForm={openVideoCardForm}
        openTextCardForm={openTextCardForm}
        onMetricListAdded={onMetricListAdded}
        openVariableFiltersSlideOut={openVariableFiltersSlideOut}
        isTemplate={isTemplate}
        onSettingsClicked={onSettingsClicked}
        isTemplated={isTemplated}
        onConfigureVariables={onConfigureVariables}
        onViewSourceTemplate={onViewSourceTemplate}
        isDeletable={isDeletable}
        isWallboard={isWallboard}
        onAddScorecardClicked={onAddScorecardClicked}
        isScorecardDashboard={isScorecardDashboard}
        isCreatedByMe={isCreatedByMe}
        dashboard={dashboard}
      />
      <DashboardForm
        close={closeSaveAs}
        isOpen={saveAsIsOpen}
        onSaveAs={onSaveAs}
        isSaveAs
        isSavingAs={savingAs}
      />
      <AddScorecardForm
        close={closeAddScorecardForm}
        isOpen={isAddScorecardFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      <IFrameForm
        close={closeIFrameForm}
        isOpen={isIFrameFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      {isImageCardFormOpen && (
        <ImageCardEditForm
          close={closeImageCardForm}
          isOpen={isImageCardFormOpen}
          startEditing={startEditing}
          setCurrentCanvas={setCurrentCanvas}
          canvasMode={canvasMode}
        />
      )}
      <VideoCardForm
        close={closeVideoCardForm}
        isOpen={isVideoCardFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      <TextCardForm
        close={closeTextCardForm}
        isOpen={isTextCardFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      <DashboardForm
        isOpen={isSettingsOpen}
        close={closeSettings}
        dashboard={dashboard}
      />
      <ConfigureVariables
        isOpen={isConfigureVariablesOpen}
        close={closeConfigureVariables}
      />
    </React.Fragment>
  );
};

export default DashboardMenuContainer;
