import React, { useCallback } from 'react';
import Button from 'kingpin/atoms/Button';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from 'permissionDefinitions';

import PortalPickerStyles from './styles';

const CreatePortalButton = ({
  openPortalForm,
  closePortalPicker,
}: {
  openPortalForm: () => void;
  closePortalPicker: () => void;
}) => {
  const onClick = useCallback(() => {
    openPortalForm();
    closePortalPicker();
  }, [closePortalPicker, openPortalForm]);

  return (
    <PortalPickerStyles.CreatePortalDiv>
      <Button
        size={'Medium'}
        type={'Ghost'}
        icon={'add'}
        label="Create Portal"
        onClick={onClick}
      />
    </PortalPickerStyles.CreatePortalDiv>
  );
};

const Gate = ({
  closePortalPicker,
  openPortalForm,
}: {
  closePortalPicker: () => void;
  openPortalForm: () => void;
}) => (
  <PermissionGates.Has
    requiredPermission={PERMISSIONS.CONTENT_ACCESS.CREATE_PORTALS}
  >
    <CreatePortalButton
      openPortalForm={openPortalForm}
      closePortalPicker={closePortalPicker}
    />
  </PermissionGates.Has>
);

export default Gate;
