import { useCallback, useContext, useEffect, useState } from 'react';
import cardTypeCheckers from '../../../types/cardTypeCheckers';
import isDefined from '../../../isDefined';
import DashboardGadgetsContext from '../../../contexts/DashboardGadgetsContext';
import MetricListsContext from '../../../contexts/MetricListsContext';
import SavedFiltersContext from '../../../contexts/SavedFiltersContext';
import isDrillDownEqual from './useDrillDownEqual';
import filterPlateTypeCheckers from '../../../types/filterPlateTypeCheckers';

// Fixed filters plates which match this variable filter
const useMatches = (
  dashboardTemplate: DashboardTemplate,
  variableFilter: VariableDrillDownType,
) => {
  const { allGadgets } = useContext(DashboardGadgetsContext);
  const { metricLists: metricListGadgets } = useContext(MetricListsContext);
  const { savedFilters } = useContext(SavedFiltersContext);

  const getMatches = useCallback(() => {
    const { canvas } = dashboardTemplate;
    // Find dashboard gadgets
    const dashboardGadgets = canvas.cards
      .filter(cardTypeCheckers.isDashboardGadget)
      .map((c) => allGadgets.find((g) => g.id === c.content.dashboardGadgetId))
      .filter(isDefined);
    const gadgetsWithThisVariableFilter = dashboardGadgets.filter(
      (dg) =>
        dg.drillDowns.some(
          (d) =>
            filterPlateTypeCheckers.isFixed(d) &&
            isDrillDownEqual(d.fixedValue, variableFilter.value),
        ) ||
        dg.scope.some(
          (d) =>
            filterPlateTypeCheckers.isFixed(d) &&
            isDrillDownEqual(d.fixedValue, variableFilter.value),
        ),
    );

    // Find metric list items
    const metricLists = canvas.cards
      .filter(cardTypeCheckers.isMetricList)
      .map((c) =>
        metricListGadgets.find((mlg) => mlg.id === c.content.metricListId),
      )
      .filter(isDefined);
    const metricListItems = metricLists.reduce(
      (allItems, ml) => [...allItems, ...ml.list],
      [] as MetricListItemType[],
    );
    const usedSavedFilters = metricListItems
      .map((mli) => savedFilters.find((sf) => sf.id === mli.savedFilterId))
      .filter((sf) => !!sf) as SavedFilter[];
    const usedSavedFiltersWithThisVariableFilter = usedSavedFilters.filter(
      (sf) =>
        sf.scope.some(
          (d) =>
            filterPlateTypeCheckers.isFixed(d) &&
            isDrillDownEqual(d.fixedValue, variableFilter.value),
        ) ||
        sf.drillDowns.some(
          (d) =>
            filterPlateTypeCheckers.isFixed(d) &&
            isDrillDownEqual(d.fixedValue, variableFilter.value),
        ),
    );

    return {
      gadgets: gadgetsWithThisVariableFilter,
      savedFilters: usedSavedFiltersWithThisVariableFilter,
    };
  }, [
    allGadgets,
    dashboardTemplate,
    metricListGadgets,
    savedFilters,
    variableFilter.value,
  ]);

  const [matches, setMatches] = useState(() => getMatches());
  useEffect(() => {
    setMatches(getMatches());
  }, [getMatches]);

  return matches;
};

export default useMatches;
