import { useCallback, useContext } from 'react';
import PERMISSIONS from '../permissionDefinitions';
import CurrentUserContext from 'contexts/CurrentUserContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import RolesContext from 'contexts/RolesContext';
import WallboardContext from 'contexts/WallboardContext';
import { PortalsContext } from '../contextProviders/PortalsProvider';
import portalTypeCheckers from '../types/portalTypeCheckers';
import { SharedContext } from '../contextProviders/SharedProvider';

const isDashboardOrReport = (
  resource: Resource | PersistedDashboardType | PersistedReportType,
): resource is PersistedDashboardType | PersistedReportType => {
  return 'id' in resource;
};

const useHasAccess = () => {
  const currentUser = useContext(CurrentUserContext);
  const { isWallboard } = useContext(WallboardContext);
  const { currentPermissions } = useContext(RolesContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const { selectedPortal, isPortalsEnabled, availablePortals } =
    useContext(PortalsContext);
  const { sharedContent } = useContext(SharedContext);

  const isShared = useCallback(
    ({
      type,
      typeId,
    }: {
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      typeId: string;
    }) => {
      if (type === 'report') {
        return sharedContent.reports.some((r) => r.id === typeId);
      } else if (type === 'dashboard') {
        return sharedContent.dashboards.some((d) => d.id === typeId);
      } else if (type === 'entity') {
        return true;
      }
      return false;
    },
    [sharedContent.dashboards, sharedContent.reports],
  );

  const portalHasAccess = useCallback(
    ({
      resource,
      type,
      portal,
    }: {
      resource: Resource | PersistedDashboardType | PersistedReportType;
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      portal: Portal;
    }) => {
      if (portalTypeCheckers.isAdminPortal(portal)) {
        return true;
      } else if (portalTypeCheckers.isExecutivePortal(portal)) {
        if (type === 'entity') {
          return true;
        }

        if (isDashboardOrReport(resource)) {
          return (
            portal.dashboardIds.includes(resource.id) ||
            portal.reportIds.includes(resource.id)
          );
        }
        return false;
      } else if (portalTypeCheckers.isEngagementPortal(portal)) {
      } else {
        return false;
      }
    },
    [],
  );

  const hasPortalAccess = useCallback(
    ({
      resource,
      type,
    }: {
      resource: Resource | PersistedDashboardType | PersistedReportType;
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
    }) => {
      return availablePortals.some((portal) =>
        portalHasAccess({ portal, resource, type }),
      );
    },
    [availablePortals, portalHasAccess],
  );

  const currentPortalHasAccess = useCallback(
    ({
      resource,
      type,
    }: {
      resource: Resource | PersistedDashboardType | PersistedReportType;
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
    }) => {
      if (!selectedPortal) {
        return false;
      }

      return portalHasAccess({
        portal: selectedPortal,
        type,
        resource,
      });
    },
    [portalHasAccess, selectedPortal],
  );

  const legacyHasAccess = useCallback(
    ({ access, resource }: { access: ResourceAccess; resource: Resource }) => {
      if (currentUser.isSuperAdmin) {
        return true;
      }

      if (currentUser.isWallboardUser || isWallboard) {
        return true;
      }
      const currentUserId = impersonatorId || currentUser.id;

      if (access.type === 'Public') {
        return true;
      } else if (access.type === 'Admin Only') {
        return currentPermissions.includes(
          PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_ADMIN_ONLY,
        );
      } else if (access.type === 'Private') {
        return (
          resource.createdBy === currentUserId ||
          currentPermissions.includes(
            PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_PRIVATE,
          )
        );
      } else if (access.type === 'Users' && access.userIds) {
        return (
          access.userIds.includes(currentUserId) ||
          resource.createdBy === currentUserId ||
          currentPermissions.includes(PERMISSIONS.CONTENT_ACCESS.VIEW_ALL_USERS)
        );
      } else {
        return true;
      }
    },
    [impersonatorId, currentUser, isWallboard, currentPermissions],
  );

  const hasAccess = useCallback(
    ({
      access,
      resource,
      type,
      typeId,
    }: {
      access: ResourceAccess;
      resource: Resource | PersistedDashboardType | PersistedReportType;
      type:
        | 'targetsApp'
        | 'entity'
        | 'dashboard'
        | 'report'
        | 'wallboard'
        | 'workspace'
        | 'goal'
        | 'scorecard';
      typeId: string;
    }) => {
      if (!isPortalsEnabled || type === 'workspace') {
        return legacyHasAccess({ access, resource });
      }

      if (selectedPortal) {
        return currentPortalHasAccess({ resource, type });
      }

      return hasPortalAccess({ resource, type }) || isShared({ type, typeId });
    },
    [
      selectedPortal,
      isPortalsEnabled,
      hasPortalAccess,
      isShared,
      legacyHasAccess,
      currentPortalHasAccess,
    ],
  );

  return hasAccess;
};

export default useHasAccess;
