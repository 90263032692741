import React, { useCallback, useContext } from 'react';
import VariableFiltersContext from 'contexts/VariableFiltersContext';
import usePopup from 'hooks/usePopup';
import useTemplateInstances from 'hooks/dashboards/useTemplateInstances';
import useDrillDownFieldName from 'hooks/useDrillDownFieldName';
import BulkUpdateFilterValues from '../BulkUpdateFilterValues';
import UpdateWarning from '../UpdateWarning';
import useUpdateVariableFilters from '../useUpdateVariableFilters';
import VariableFilterCard from './VariableFilterCard';
import useInstances from './useInstances';
import useMatches from './useMatches';
import useOnReplaceAllClicked from './useOnReplaceAllClicked';
import useOnDeleteConfirmed from './useOnDeleteConfirmed';

const getSourcesCount = (sources: {
  gadgets: DashboardGadget[];
  savedFilters: SavedFilter[];
}): number => {
  return sources.savedFilters.length + sources.gadgets.length;
};

const VariableFilterCardContainer = ({
  variableFilter,
  dashboardTemplate,
}: {
  variableFilter: VariableDrillDownType;
  dashboardTemplate: DashboardTemplate;
}) => {
  const fieldName = useDrillDownFieldName(variableFilter.value);
  const updateVariableFilters = useUpdateVariableFilters(dashboardTemplate);
  const { setVariableFilters, variableFilters } = useContext(
    VariableFiltersContext,
  );
  const templateInstances = useTemplateInstances(dashboardTemplate);
  const templateInstancesCount = templateInstances.filter((dashboard) =>
    dashboard.variableDrillDowns.some((d) => d.id === variableFilter.id),
  ).length;
  const {
    isOpen: isEditTemplatedValuesOpen,
    open: openEditTemplatedValues,
    close: closeEditTemplatedValues,
  } = usePopup();
  const {
    isOpen: isDeleteConfirmOpen,
    open: openDeleteConfirm,
    close: closeDeleteConfirm,
  } = usePopup();

  const onUpdated = useCallback(
    (newDrillDown: ReportDrillDownType) => {
      const newVariableFilter = {
        ...variableFilter,
        value: newDrillDown,
      };

      const newFilters = variableFilters.map((vs) => {
        if (vs.id === variableFilter.id) {
          return newVariableFilter;
        } else {
          return vs;
        }
      });

      setVariableFilters(newFilters);
      if (updateVariableFilters) {
        updateVariableFilters(newFilters);
      }
    },
    [
      setVariableFilters,
      updateVariableFilters,
      variableFilter,
      variableFilters,
    ],
  );

  const instances = useInstances(dashboardTemplate, variableFilter);
  const instancesCount = getSourcesCount(instances);

  const matches = useMatches(dashboardTemplate, variableFilter);
  const matchesFixedCount = getSourcesCount(matches);

  const onReplaceAllClicked = useOnReplaceAllClicked({
    matches,
    variableFilter,
  });

  const onDeleteConfirmed = useOnDeleteConfirmed({
    dashboardTemplate,
    variableFilter,
    instances,
  });

  const onDeleteClicked = () => {
    openDeleteConfirm();
  };

  return (
    <>
      <VariableFilterCard
        fieldName={fieldName}
        instancesCount={instancesCount}
        onDeleteClicked={onDeleteClicked}
        matchesFixedCount={matchesFixedCount}
        isDeleteConfirmOpen={isDeleteConfirmOpen}
        closeDeleteConfirm={closeDeleteConfirm}
        onDeleteConfirmed={onDeleteConfirmed}
        onReplaceAllClicked={onReplaceAllClicked}
        variableFilter={variableFilter}
        onUpdated={onUpdated}
        openEditTemplatedValues={openEditTemplatedValues}
        templateInstancesCount={templateInstancesCount}
      />
      <UpdateWarning
        openBulkUpdater={openEditTemplatedValues}
        variableFilter={variableFilter}
        template={dashboardTemplate}
      />
      <BulkUpdateFilterValues
        isOpen={isEditTemplatedValuesOpen}
        close={closeEditTemplatedValues}
        variableFilter={variableFilter}
        template={dashboardTemplate}
      />
    </>
  );
};

const Gate = ({
  variableFilter,
  dashboardTemplate,
}: {
  variableFilter: VariableDrillDownType;
  dashboardTemplate: DashboardTemplate;
}) => {
  return (
    <VariableFilterCardContainer
      variableFilter={variableFilter}
      dashboardTemplate={dashboardTemplate}
    />
  );
};

export default Gate;
