// What portals does this user have access to this content through?
import { useCallback, useContext, useEffect, useState } from 'react';
import { ExecutivePortalsContext } from '../../../contextProviders/PortalsProvider/ExecutivePortalsProvider';

const usePortalAccess = ({
  type,
  typeId,
  user,
}: {
  type: 'report' | 'dashboard';
  typeId: string;
  user: UserManagement.SignedUpUser | UserManagement.PendingUser;
}) => {
  const { allExecutivePortals } = useContext(ExecutivePortalsContext);

  const getAccessPortals = useCallback(() => {
    const portalsWithThisContent = allExecutivePortals.filter((portal) => {
      if (type === 'dashboard') {
        return portal.dashboardIds.includes(typeId);
      } else if (type === 'report') {
        return portal.reportIds.includes(typeId);
      }
      return false;
    });

    return portalsWithThisContent.filter((portal) =>
      portal.managerIds.includes(user.id),
    );
  }, [allExecutivePortals, type, typeId, user.id]);

  const [accessPortals, setAccessPortals] = useState<ExecutivePortal[]>(() =>
    getAccessPortals(),
  );

  useEffect(() => {
    setAccessPortals(getAccessPortals());
  }, [getAccessPortals]);

  return accessPortals;
};

export default usePortalAccess;
